import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import LabOrderService from "../../../Services/LabOrderService";

function ViewLabReports({ labReportData }) {
  console.log("labReportData", labReportData);

  const [uploadedLabDocuments, setUploadedLabDocuments] = useState([]);
  const [viewUploadedReports, setviewUploadedReports] = useState({});
  const [showUploadeddocuments, setShowUploadeddocuments] = useState(false);

  const [loading, setLoading] = useState(true);

  const viewFromTable = (record) => {
    setviewUploadedReports(record);
    setShowUploadeddocuments(true);
    console.log("record", record);
  };

  const getuploaded = async () => {
    setLoading(true);
    let payload = {
      labTestOrderId: labReportData.labTestOrderId,
      status: 1,
      patientId: labReportData.patientId,
      consultationId: labReportData.consultationId,
      userId: labReportData.userId,
    };

    let result2 = await LabOrderService.LabReportUploadGet(payload);
    setUploadedLabDocuments(result2.data);
    console.log("result 2", result2);
    setLoading(false);
  };

  useEffect(() => {
    getuploaded();
  }, []);

  return (
    <div>
      {loading ? (
        <div className="w-full flex h-[400px] justify-center items-center">
          <div className="spinner"></div>
        </div>
      ) : (
        <div className="w-full">
          <table className="border-collapse w-full mt-2">
            <thead>
              <tr className="bg-gray-200">
                <th className="border px-4 py-2 text-center">Test Name</th>
                <th className="border px-4 py-2 text-center">order ID</th>
                <th className="border px-4 py-2 text-center">View</th>
              </tr>
            </thead>
            <tbody>
              {uploadedLabDocuments && uploadedLabDocuments.length > 0 ? (
                uploadedLabDocuments.map((lab, index) => (
                  <tr key={index}>
                    <td className="border px-2 py-2">{lab.labTestName}</td>
                    <td className="border px-2 py-2">{lab.labTestOrderId}</td>
                    <td className="border px-2 flex items-center justify-center py-2">
                      <button
                        className="p-1 text-white text-sm rounded-sm bg-blue-500"
                        onClick={() => viewFromTable(lab)}
                      >
                        View Report
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="3" className="text-center py-4">
                    No documents uploaded yet.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {showUploadeddocuments && (
            <Modal
              open={showUploadeddocuments}
              onCancel={() => {
                setShowUploadeddocuments(false);
                setviewUploadedReports({});
              }}
              footer={[]}
              width={800}
              style={{
                top: 40,
              }}
            >
              <iframe
                src={`${process.env.REACT_APP_IMAGE_URL}${viewUploadedReports?.resultDocumentName}`}
                title="PDF Preview"
                style={{ width: "100%", height: "500px" }}
              />
            </Modal>
          )}
        </div>
      )}
    </div>
  );
}

export default ViewLabReports;
