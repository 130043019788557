import React, { useEffect } from 'react'
import AuthLayout from '.';
import { Flex, message, Spin } from 'antd';
import AuthService from '../../Services/AuthService';
import { decryptPayload, generateAesKey } from '../../utils/passwordEncrypt';
import { Base64 } from 'js-base64';
import { useHistory } from 'react-router-dom';
import { APP_ROUTES } from '../../PageRouting/approutes';

function AutoLogin() {
    const history = useHistory();

    useEffect(() => {
        console.log(window.location.search.slice(6));
        const userObj = decryptPayload(window.location.search.slice(6));
        handleLogin(userObj);
    }, []);

    const handleLogin = async (userObj) => {

        if (userObj.type === 'otp') {
            try {
                const tokenPayload = {
                  password: 'NA',
                  userId: `U${userObj.userId}`,
                };
                const res = await AuthService.TokenAuthentication(tokenPayload);
                localStorage.setItem("access_token", res.data.access_token);
                if (res.data.error === "1") {
                  message.error(res.data.error_description);
                  return;
                }
                const encryptPassword = generateAesKey(userObj.numVal);
                let verifyPayload = {
                  numVal: encryptPassword,
                  userId: userObj.userId,
                };
                const loginResult = await AuthService.verifyDoctorOtp(verifyPayload);
                if (!loginResult.data.message) {
                    if (
                        loginResult.data.user.rolecode !== "SUPERADMIN" &&
                        loginResult.data.user.userType === "P"
                    ) {
                        history.push(APP_ROUTES.DASHBOARD);
                        message.success(
                            `Welcome ${loginResult.data.user.firstName} ${loginResult.data.user.lastName}`
                        );
                        localStorage.setItem(
                            "login_result",
                            JSON.stringify(loginResult.data)
                        );
                    } else {
                        message.error("Invalid Credentials, verify and try again");
                    }
                } else {
                    message.error(loginResult.data.message);
                }
            
              } catch (e) {
                message.error(e.response.data.message);
                return;
              }

        }
        else {

            let payload = {
                password: Base64.encode(userObj.currVal),
                userId: userObj.userId,
            };

            try {
                const res = await AuthService.TokenAuthentication(payload);
                localStorage.setItem("access_token", res.data.access_token);
                if (res.data.error === "1") {
                    message.error(res.data.error_description);
                    return;
                }
                const encryptedPass = generateAesKey(userObj.currVal);
                let data1 = {
                    currentVal: encryptedPass,
                    userCode: userObj.userId,
                };
                const loginResult = await AuthService.Login(data1);

                if (!loginResult.data.message) {
                    if (
                        loginResult.data.user.rolecode !== "SUPERADMIN" &&
                        loginResult.data.user.userType === "P"
                    ) {
                        history.push(APP_ROUTES.DASHBOARD);
                        message.success(
                            `Welcome ${loginResult.data.user.firstName} ${loginResult.data.user.lastName}`
                        );
                        localStorage.setItem(
                            "login_result",
                            JSON.stringify(loginResult.data)
                        );
                    } else {
                        message.error("Invalid Credentials, verify and try again");
                    }
                } else {
                    message.error(loginResult.data.message);
                }
            } catch (error) {
                message.error("An Error occured. Please Try Again");
            }

        }


    };

    return (
        <div
            className="h-screen"
            style={{ backgroundSize: "cover", backgroundPosition: "full" }}
        >
            <div className="min-h-screen flex flex-col justify-center items-center w-screen h-screen">
                <div className="flex items-center justify-center mt-[10%]">
                    <Flex align="center" gap="middle">
                        <Spin size="large" tip="Loading" />
                    </Flex>
                </div>
            </div>
        </div>
    )
}

export default AutoLogin