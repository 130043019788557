import http from "./http-common";

class UserService {
  queryString(payload) {
    return Object.keys(payload)
      .map((key) => (key && payload[key] ? key + "=" + payload[key] : ""))
      .join("&");
  }
  UserDetails(payload) {
    const queryString = this.queryString(payload);
    return http.get(`/accountMaster/list/filter?${queryString}`);
  }
}

export default new UserService();
