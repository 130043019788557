import React, { useState } from "react";
import { Button, Input, message, Modal, Select, Spin, Tooltip } from "antd";
import ConsultationService from "../../../Services/ConsultationService";
import medicineImage from "../../../Assets/Images/Svg/addMedicine.svg";
import labReports from "../../../Assets/Images/Svg/addMedicine@.svg";
import MedicineDisabled from "../../../Assets/Images/Svg/MedicineDisabled.svg";
import LabDisabled from "../../../Assets/Images/Svg/LabDisabled.svg";
import PharmacyService from "../../../Services/PharmacyService";
import LabOrderService from "../../../Services/LabOrderService";

function ShareOrders({ record }) {
  const [loadingType, setLoadingType] = useState(null);

  const storedLoginResult = localStorage.getItem("login_result");
  const loginResultObject = storedLoginResult
    ? JSON.parse(storedLoginResult)
    : null;

  const [pharAndLabList, setPharAndLabList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sendPayload, setSendPayload] = useState({});

  const showModal = async () => {
    setLoadingType("p");

    let medlistpayload = {
      consultationId: record?.consultationId,
      status: 1,
    };

    let druglist = await PharmacyService.GetDispensedDrugsDtl(medlistpayload);

    if (Array.isArray(druglist?.data) && druglist.data.length === 0) {
      console.log("Drug list data is empty:", druglist);
      message.error("Unable to place order, Medicine List is empty");
      setLoadingType("");
      return;
    }

    let pharmaListPayload = {
      userId: loginResultObject.user.userId,
      status: 1,
    };

    let pharmaList1 = await PharmacyService.GetPharmacyList(pharmaListPayload);
    setPharAndLabList(pharmaList1.data);
    console.log("pharmaList1", pharmaList1.data);
    setIsModalOpen(true);
    setLoadingType(null);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleSelectedItem = (selectedItem) => {
    console.log("selectedItem", selectedItem);
    const filteredList = pharAndLabList.filter(
      (item) => item.id === selectedItem
    );
    console.log("filteredList", filteredList[0]);
    setSendPayload(filteredList[0]);
  };

  const [save, setSave] = useState(false);

  const onMedicineOrderSend = async () => {
    setSave(true);
    let LinkPayload = {
      consultationId: record?.consultationId,
      patientId: record.patientId,
      userId: loginResultObject.user.userId,
      appointmentId: record.appointmentId,
      prescriptionShareType: "p",
      pharmacyId: sendPayload.pharmacyId,
      pharmacyName: sendPayload.pharmacyName,
      pharmacyMailId: sendPayload.pharmacyMailId,
      pharmacyMobile: sendPayload.pharmacyMobile,
      drugPrescriptionStatus: "Sent",
      rejectedPharmacyRemarks: null,
    };

    try {
      const response = await ConsultationService.SharePharmaLabAPI(LinkPayload);

      if (response.data.message !== "Prescription sent successfully") {
        message.error("Medicine order is already placed");
        setLoadingType(null);
        setSave(false);
        return;
      }
      message.success(`Medicine Order Sent to ${LinkPayload.pharmacyName}`);
      setSave(false);
    } catch (error) {
      message.error("Medicine order is already placed");
      setSave(false);
    } finally {
      setLoadingType(null);
      setIsModalOpen(false);
      setSave(false);
    }
  };

  const [labList, setLabList] = useState([]);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [sendPayload2, setSendPayload2] = useState({});

  const showLabModal = async () => {
    setLoadingType("l");

    let labListPayload = {
      consultationId: record.consultationId,
      status: 1,
    };

    let labOrderList = await LabOrderService.GetLabTestOrderDtl(labListPayload);

    if (Array.isArray(labOrderList?.data) && labOrderList.data.length === 0) {
      message.error("Unable to place order, Lab List is empty");
      setLoadingType("");
      return;
    }

    let labList = {
      userId: loginResultObject.user.userId,
      status: 1,
    };

    let labListResult = await LabOrderService.GetLabList(labList);
    setLabList(labListResult.data);
    console.log("labListResult", labListResult.data);
    setIsModalOpen2(true);
    setLoadingType(null);
  };

  const handleLabCancel = () => {
    setIsModalOpen2(false);
  };

  const handleLabSelect = (selectedItem) => {
    console.log("selectedItem", selectedItem);
    const filteredList = labList.filter((item) => item.id === selectedItem);
    console.log("filteredList", filteredList[0]);
    setSendPayload2(filteredList[0]);
  };

  const onLabOrderSend = async () => {
    setSave(true);
    let LinkPayload = {
      consultationId: record?.consultationId,
      patientId: record.patientId,
      userId: loginResultObject.user.userId,
      appointmentId: record.appointmentId,
      prescriptionShareType: "l",
      labId: sendPayload2.labId,
      labName: sendPayload2.labName,
      labMailId: sendPayload2.labMailId,
      labMobile: sendPayload2.labMobile,
      labPrescriptionStatus: "Sent",
      rejectedLabRemarks: null,
    };

    try {
      const response = await ConsultationService.SharePharmaLabAPI(LinkPayload);

      if (response.data.message !== "Prescription sent successfully") {
        message.error("Lab order is already placed");
        setIsModalOpen2(false);
        setLoadingType(null);
        setSave(false);
        return;
      }
      message.success(`Lab Order Sent to ${LinkPayload.labName}`);
      setSave(false);
    } catch (error) {
      message.error("Lab order is already placed");
    } finally {
      setLoadingType(null);
      setIsModalOpen2(false);
      setSave(false);
    }
  };

  const handleLabOrders = async (type) => {
    setLoadingType(type);
    let pharmaListPayload = {
      userId: loginResultObject.user.userId,
      status: 1,
    };

    let LinkPayload = {
      consultationId: record?.consultationId,
      patientId: record.patientId,
      userId: loginResultObject.user.userId,
      appointmentId: record.appointmentId,
      prescriptionShareType: type,
    };

    try {
      let labList1 = await LabOrderService.GetLabList(pharmaListPayload);

      let labList = labList1.data[0];
      LinkPayload.labId = labList.labId;
      LinkPayload.labName = labList.labName;
      LinkPayload.labMailId = labList.labMailId;
      LinkPayload.labMobile = labList.labMobile;
      LinkPayload.labPrescriptionStatus = "Sent";
      LinkPayload.rejectedLabRemarks = null;

      console.log("link payload", LinkPayload);

      setLoadingType(type);
      try {
        const response = await ConsultationService.SharePharmaLabAPI(
          LinkPayload
        );

        console.log("response", response.data);

        if (response.data === 0) {
          message.error("Lab order is already placed");
          setLoadingType(null);
          return;
        }

        if (LinkPayload.prescriptionShareType === "p") {
          message.success(" Medicine Order Sent to Medplus");
        } else {
          message.success("Lab Diagnostics shared successfully");
        }
        setLoadingType(null);
      } catch (error) {
        console.error("Error sharing prescription:", error);
        message.error("Failed to share prescription. Please try again.");
        setLoadingType(null);
      }
    } catch (error) {
      console.log("error", error);
    }

    return;
  };

  return (
    <>
      {record.transactionId ? (
        <>
          <Tooltip placement="top" title={"Share Medicine Order"}>
            {loadingType === "p" ? (
              <Spin />
            ) : (
              <img
                src={medicineImage}
                alt="Preview"
                className="w-8 h-8"
                role="button"
                // onClick={() => handleOrderShare("p")}
                onClick={showModal}
              />
            )}
          </Tooltip>
          <Tooltip placement="top" title={"Share Lab Diagnostics"}>
            {loadingType === "l" ? (
              <Spin />
            ) : (
              <img
                src={labReports}
                alt="Share"
                className="w-8 h-8"
                role="button"
                // onClick={() => handleLabOrders("l")}
                onClick={showLabModal}
              />
            )}
          </Tooltip>
        </>
      ) : (
        <>
          <Tooltip
            placement="top"
            title={"Payment is not completed on Patient Side"}
          >
            <img
              src={MedicineDisabled}
              alt="Preview"
              className="w-8 h-8 cursor-not-allowed"
              role="button"
            />
          </Tooltip>

          <Tooltip
            placement="top"
            title={"Payment is not completed on Patient Side"}
          >
            <img
              src={LabDisabled}
              alt="Share"
              className="w-8 h-8 cursor-not-allowed"
              role="button"
            />
          </Tooltip>
        </>
      )}
      <Modal
        title="Medicine Order"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
      >
        {save && (
          <div class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="spinner"></div>
          </div>
        )}
        <div className="flex gap-2">
          <Select
            className="w-[80%]"
            onChange={(selectedItem) => {
              handleSelectedItem(selectedItem);
            }}
          >
            {pharAndLabList?.map((pAndlList) => (
              <Select.Option key={pAndlList.id} value={pAndlList.id}>
                {pAndlList.pharmacyName}
              </Select.Option>
            ))}
          </Select>
          {/* <Select className="w-[80%]">
            {pharAndLabList?.map((pAndlList) => (
              <Select.Option key={pAndlList.id} value={pAndlList.pharmacyName}>
                {pAndlList.pharmacyName}
              </Select.Option>
            ))}
          </Select> */}
          <button
            onClick={onMedicineOrderSend}
            className="p-1 rounded-md bg-blue-500 text-white font-semibold"
            disabled={save}
          >
            Send Order
          </button>
        </div>
      </Modal>

      <Modal
        title="Lab Order"
        open={isModalOpen2}
        onCancel={handleLabCancel}
        footer={[]}
      >
        {save && (
          <div class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="spinner"></div>
          </div>
        )}
        <div className="flex gap-2">
          <Select
            className="w-[80%]"
            onChange={(selectedItem) => {
              handleLabSelect(selectedItem);
            }}
          >
            {labList?.map((lab) => (
              <Select.Option key={lab.id} value={lab.id}>
                {lab.labName}
              </Select.Option>
            ))}
          </Select>

          <button
            onClick={onLabOrderSend}
            className="p-1 rounded-md bg-blue-500 text-white font-semibold"
            disabled={save}
          >
            Send Order
          </button>
        </div>
      </Modal>
    </>
  );
}

export default ShareOrders;
