import React, { useState } from "react";
import { Modal } from "antd";
import PharmacyHeader from "./PharmacyHeader";
import PharmacyFooter from "./PharmacyFooter";

function PrescriptionPharma({
  doctorData,
  hospitalAccountData,
  clinicDetails,
  consultationDetails,
  patientDetails,
  calculateAge,
}) {
  const [showPrescription, setShowPrescription] = useState(false);

  const handleCancel = () => {
    setShowPrescription(false);
  };

  return (
    <>
      <button
        onClick={() => setShowPrescription(true)}
        className="p-2 rounded-md md:h-12 text-white"
      >
        ViewPrescription
      </button>

      {showPrescription && (
        <Modal
          title=""
          open={showPrescription}
          onCancel={handleCancel}
          style={{ top: 40 }}
          width={"90%"}
          footer={[]}
        >
          <div className="m-4">
            <PharmacyHeader
              clinicDetails={clinicDetails}
              doctorData={doctorData}
              hospitalAccountData={hospitalAccountData}
            />
            <div className="mt-3">
              <div className="md:flex justify-end gap-1">
                <div className="text-sm font-bold">Date:</div>
                <h6 className="text-sm text-gray-700 font-semibold">
                  {consultationDetails?.consultationDate
                    ? `${consultationDetails?.consultationDate
                        .slice(0, 11)
                        .split("-")[2]
                        .trim()}-${
                        consultationDetails?.consultationDate
                          .slice(0, 11)
                          .split("-")[1]
                      }-${
                        consultationDetails?.consultationDate
                          .slice(0, 11)
                          .split("-")[0]
                      }`
                    : ""}
                </h6>
              </div>
            </div>
            <div className="md:flex gap-5  my-2">
              <div className="text-sm font-bold flex gap-1">
                Name:
                <span className="text-sm text-gray-500 font-semibold">
                  {patientDetails?.patientSalutation
                    ? patientDetails?.patientSalutation + " "
                    : ""}
                  {patientDetails?.patientFirstName
                    ? patientDetails?.patientFirstName + " "
                    : ""}
                  {patientDetails?.patientLastName
                    ? patientDetails?.patientLastName
                    : ""}
                </span>
              </div>
              <div className="text-sm font-bold md:flex gap-1">
                Age/Gender:
                <span className="text-sm text-gray-500 font-semibold">
                  {patientDetails?.dob ? calculateAge(patientDetails?.dob) : ""}
                  /
                  {patientDetails?.patientGender
                    ? patientDetails?.patientGender == "M"
                      ? "Male"
                      : "Female"
                    : "NA"}
                </span>
              </div>
              <div className="text-sm font-bold md:flex gap-1">
                Phone Number:
                <span className="text-sm text-gray-500 font-semibold">
                  {patientDetails?.patientMobile}
                </span>
              </div>

              <div className="text-sm font-bold flex gap-1">
                Visit Type:
                <span className=" text-sm text-gray-500 font-semibold">
                  {consultationDetails.consultationType}
                </span>
              </div>
            </div>
            <hr />
            <div className="md:flex gap-3 my-3 md:flex-wrap justify-stretch">
              <div
                className={`gap-1 md:w-[20%] ${
                  consultationDetails?.vitalsPrescriptionDetails?.find(
                    (vital) => vital.vitalCode === "BPS"
                  )?.value
                    ? "flex"
                    : "hidden"
                }`}
              >
                <label className="font-medium text-sm">BP:</label>
                <span className="text-gray-500 text-sm">
                  {`${
                    consultationDetails?.vitalsPrescriptionDetails.find(
                      (vital) => vital.vitalCode === "BPS"
                    )?.value
                  }/${
                    consultationDetails?.vitalsPrescriptionDetails.find(
                      (vital) => vital.vitalCode === "BPD"
                    )?.value
                  }`}{" "}
                  mmHg
                </span>
              </div>
              <div
                className={`gap-1 md:w-[20%] ${
                  consultationDetails?.vitalsPrescriptionDetails.find(
                    (vital) => vital.vitalCode === "BPD"
                  )?.value
                    ? "flex"
                    : "hidden"
                }`}
              >
                <label className="font-medium text-sm">Pulse Ox:</label>
                <span className="text-gray-500 text-sm">
                  {
                    consultationDetails?.vitalsPrescriptionDetails.find(
                      (vital) => vital.vitalCode === "PO"
                    )?.value
                  }{" "}
                  %
                </span>
              </div>
              <div
                className={`gap-1 md:w-[20%] ${
                  consultationDetails?.vitalsPrescriptionDetails.find(
                    (vital) => vital.vitalCode === "HR"
                  )?.value
                    ? "flex"
                    : "hidden"
                }`}
              >
                <label className="font-medium text-sm">Heart Rate:</label>
                <span className="text-gray-500 text-sm">
                  {
                    consultationDetails?.vitalsPrescriptionDetails.find(
                      (vital) => vital.vitalCode === "HR"
                    )?.value
                  }{" "}
                  Beats/min
                </span>
              </div>
              <div
                className={`gap-1 md:w-[30%] ${
                  consultationDetails?.vitalsPrescriptionDetails.find(
                    (vital) => vital.vitalCode === "RR"
                  )?.value
                    ? "flex"
                    : "hidden"
                }`}
              >
                <label className="font-medium text-sm">Respiration Rate:</label>
                <span className="text-gray-500 text-sm">
                  {
                    consultationDetails?.vitalsPrescriptionDetails.find(
                      (vital) => vital.vitalCode === "RR"
                    )?.value
                  }{" "}
                  Breaths/min
                </span>
              </div>
              <div
                className={`gap-1 md:w-[20%] ${
                  consultationDetails?.vitalsPrescriptionDetails.find(
                    (vital) => vital.vitalCode === "TP"
                  )?.value
                    ? "flex"
                    : "hidden"
                }`}
              >
                <label className="font-medium text-sm">Temperature:</label>
                <span className="text-gray-500 text-sm">
                  {
                    consultationDetails?.vitalsPrescriptionDetails.find(
                      (vital) => vital.vitalCode === "TP"
                    )?.value
                  }{" "}
                  °F
                </span>
              </div>
              <div
                className={`gap-1 md:w-[20%] ${
                  consultationDetails?.vitalsPrescriptionDetails.find(
                    (vital) => vital.vitalCode === "HI"
                  )?.value
                    ? "flex"
                    : "hidden"
                }`}
              >
                <label className="font-medium text-sm">Height:</label>
                <span className="text-gray-500 text-sm">
                  {
                    consultationDetails?.vitalsPrescriptionDetails.find(
                      (vital) => vital.vitalCode === "HI"
                    )?.value
                  }{" "}
                  cm
                </span>
              </div>
              <div
                className={`gap-1 md:w-[20%] ${
                  consultationDetails?.vitalsPrescriptionDetails.find(
                    (vital) => vital.vitalCode === "WE"
                  )?.value
                    ? "flex"
                    : "hidden"
                }`}
              >
                <label className="font-medium text-sm">Weight:</label>
                <span className="text-gray-500 text-sm">
                  {
                    consultationDetails?.vitalsPrescriptionDetails.find(
                      (vital) => vital.vitalCode === "WE"
                    )?.value
                  }{" "}
                  kg
                </span>
              </div>
              <div
                className={`gap-1 md:w-[20%] ${
                  consultationDetails?.vitalsPrescriptionDetails.find(
                    (vital) => vital.vitalCode === "BMI"
                  )?.value
                    ? "flex"
                    : "hidden"
                }`}
              >
                <label className="font-medium text-sm">BMI:</label>
                <span className="text-gray-500 text-sm">
                  {
                    consultationDetails?.vitalsPrescriptionDetails.find(
                      (vital) => vital.vitalCode === "BMI"
                    )?.value
                  }{" "}
                  Kg/m<sup>2</sup>
                </span>
              </div>
            </div>

            <hr className="hr-line mt-3" />

            <div className="md:grid grid-cols-1 my-2">
              <p>
                <b className=" font-bold">Chief Complaint : </b>
                <span className="text-gray-500 font-semibold">{`${consultationDetails?.consultationReason
                  ?.charAt(0)
                  .toUpperCase()}${consultationDetails?.consultationReason?.slice(
                  1
                )}`}</span>
              </p>
            </div>
            <div className="mb-5">
              <p className="">
                <span className="font-bold">Symptoms : </span>
                <span className="text-gray-500 font-semibold">
                  {consultationDetails?.symptoms}
                </span>
              </p>
            </div>
            {consultationDetails?.diagnosisPrescriptionDetails?.filter(
              (diagnosis) =>
                diagnosis.diagnosisType === "Primary" && diagnosis?.status === 1
            )?.length !== 0 && (
              <div className="md:grid grid-cols-1 my-2">
                <p className="">
                  <b className="font-bold">Primary Diagnosis :</b>
                </p>

                <>
                  <ol className="list-decimal ml-5">
                    {consultationDetails?.diagnosisPrescriptionDetails
                      ?.filter(
                        (diagnosis) =>
                          diagnosis?.diagnosisType === "Primary" &&
                          diagnosis?.status === 1
                      )
                      ?.map((primaryDiagnosis) => (
                        <li>
                          <span>{primaryDiagnosis?.diagnosisDescription}</span>
                          <span className="font-bold">
                            ({primaryDiagnosis?.diagnosisCode})
                          </span>
                        </li>
                      ))}
                  </ol>
                </>
              </div>
            )}

            {consultationDetails?.diagnosisPrescriptionDetails?.filter(
              (diagnosis) =>
                diagnosis?.diagnosisType === "Secondary" &&
                diagnosis?.status === 1
            )?.length !== 0 && (
              <div className="md:grid grid-cols-1 my-2">
                <p className="">
                  <b className="font-bold">Secondary Diagnosis :</b>
                </p>

                <>
                  <ol className="list-decimal ml-5">
                    {consultationDetails?.diagnosisPrescriptionDetails
                      ?.filter(
                        (diagnosis) =>
                          diagnosis?.diagnosisType === "Secondary" &&
                          diagnosis?.status === 1
                      )
                      ?.map((secondaryDiagnosis) => (
                        <li>
                          <span>
                            {secondaryDiagnosis?.diagnosisDescription}
                          </span>
                          <span className="font-bold">
                            ({secondaryDiagnosis?.diagnosisCode})
                          </span>
                        </li>
                      ))}
                  </ol>
                </>
              </div>
            )}

            <div className="mb-2 overflow-scroll scrollWidth">
              {consultationDetails?.drugPrescriptionDetails?.length !== 0 && (
                <div>
                  <p className="mb-2">
                    <b className="font-bold">Medicines :</b>
                  </p>
                  <table className="table-auto w-full mb-5 overflow-y-scroll">
                    <thead className="border-b-2 border-t-2 border-gray-400">
                      <tr>
                        <th className="px-3 py-1 text-left">Medicines</th>
                        <th className="px-3 py-1 text-left">Dosage</th>
                        <th className="px-3 py-1 text-left">Duration</th>
                      </tr>
                    </thead>
                    <tbody>
                      {consultationDetails?.drugPrescriptionDetails &&
                        consultationDetails?.drugPrescriptionDetails?.map(
                          (medicine, idx) => {
                            if (medicine?.status !== 0) {
                              return (
                                <tr className="border-b-2 text-gray-500">
                                  <td className="text-gray-700 font-semibold px-3 py-1">
                                    {medicine?.drugName}
                                  </td>
                                  <td className="text-gray-700 font-semibold px-3 py-1 flex flex-col">
                                    {medicine?.dosage}
                                    <br />(
                                    {medicine?.afterBeforeFood === "AF"
                                      ? "After Food"
                                      : "Before Food"}
                                    )
                                  </td>
                                  <td className="text-gray-700 font-semibold px-3 py-1">
                                    {medicine?.duration} days
                                    <br />
                                    {medicine?.instruction?.length > 20
                                      ? `(${medicine?.instruction?.slice(
                                          0,
                                          20
                                        )}...)`
                                      : medicine?.instruction}
                                  </td>
                                </tr>
                              );
                            }
                          }
                        )}
                    </tbody>
                  </table>
                </div>
              )}

              {consultationDetails?.labPrescriptionDetails?.length !== 0 && (
                <div className="mt-2  mb-5">
                  <p className="mb-2">
                    <b className="font-bold">Lab Test :</b>
                  </p>

                  <div className="md:w-[60%]">
                    <div className="text-gray-700 font-semibold">
                      {consultationDetails?.labPrescriptionDetails &&
                        consultationDetails?.labPrescriptionDetails
                          ?.filter((item) => item.status !== 0)
                          .map((lab, idx) => {
                            if (lab?.status !== 0) {
                              return (
                                <div
                                  key={idx}
                                  className="text-sm border text-gray-700 px-3 py-1"
                                >
                                  {lab?.labTestName?.length > 100
                                    ? lab?.labTestName?.slice(0, 100)
                                    : lab?.labTestName}
                                </div>
                              );
                            }
                          })}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="my-2">
              <div className="md:w-6/6  my-2">
                <div className="mb-5">
                  <p>
                    <b className="mr-3">Recommendation :</b>
                    <span className="text-gray-700 font-semibold">
                      {consultationDetails?.recommendation}
                    </span>
                  </p>
                </div>

                <div>
                  <p className=" my-2">
                    <b className="mr-3">Scheduled follow up visit date :</b>
                    <span className="text-gray-700 font-semibold">
                      {consultationDetails?.followupVisitDate
                        ? `${
                            consultationDetails?.followupVisitDate
                              ?.slice(0, 10)
                              .split("/")[1]
                          }/${
                            consultationDetails?.followupVisitDate
                              ?.slice(0, 10)
                              .split("/")[0]
                          }/${
                            consultationDetails?.followupVisitDate
                              ?.slice(0, 10)
                              .split("/")[2]
                          }`
                        : ""}
                    </span>
                  </p>
                </div>
              </div>
            </div>

            <div className="md:flex justify-between px-4">
              <div className="md:flex  items-center">
                <img
                  id="imag"
                  src={`${process.env.REACT_APP_IMAGE_URL}${doctorData?.doctorStampName}`}
                  alt="Stamp"
                  className="md:h-32 h-28 md:w-36 w-30 rounded-full"
                />
              </div>

              <div className="">
                <img
                  src={`${process.env.REACT_APP_IMAGE_URL}${doctorData?.doctorSignatureName}`}
                  alt="Signature"
                  className="md:w-28 w-20 md:h-28 h-20  rounded-full"
                />
                <p className="font-bold text-sm mb-1">
                  Dr.{doctorData?.firstName}
                </p>
                <p className="font-semibold text-gray-700 text-sm mb-1">
                  {doctorData?.qualification}
                  <br />
                  {doctorData?.speciality}
                </p>
                <p className="font-semibold text-gray-700 text-sm mb-1">
                  Regn No. {doctorData?.registrationNumber}
                </p>
              </div>
            </div>
          </div>
          <div className=" ">
            <PharmacyFooter clinicDetails={clinicDetails} />
          </div>
        </Modal>
      )}
    </>
  );
}

export default PrescriptionPharma;
