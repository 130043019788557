import React from "react";

function PrescriptionHeader({
  clinicDetails,
  doctorData,
  hospitalAccountData,
}) {
  return (
    <>
      {
        (clinicDetails?.prescription_HeaderYN === 'Y' || (clinicDetails?.prescription_HeaderYN === 'C' && !doctorData.headerDetails)) ?
          (
            <div className="border-b-2 border-gray-300 md:flex justify-between items-start">
              <div>
                <div className="flex">
                  <img
                    src={
                      process.env.REACT_APP_IMAGE_URL + hospitalAccountData?.logoName
                    }
                    alt="Clinic Logo"
                    className="md:w-24 w-12 md:h-24 h-12 mx-4"
                  />
                  <div className="text-center mt-3">
                    <label className="md:text-4xl text-sm font-serif font-medium text-[#154A6B]">
                      {clinicDetails?.clinic_Name}
                    </label>
                    {/* <h1 className="md:text-2xl text-sm  text-[#154A6B]">CLINIC</h1> */}
                  </div>
                </div>
                <div className="md:flex gap-3">
                  <p className="text-sm text-[#A7527E] font-medium mt-4">
                    Morning:
                    {
                      (clinicDetails?.morningFromTime && clinicDetails?.morningToTime) &&
                      (
                        <span className="text-[#184B67]">{` ${clinicDetails?.morningFromTime} - ${clinicDetails?.morningToTime} `}</span>
                      )
                    }
                  </p>
                  <p className="text-sm text-[#A7527E] font-medium mt-4">
                    Evening:
                    {
                      (clinicDetails?.eveningFromTime && clinicDetails?.eveningToTime) &&
                      (
                        <span className="text-[#184B67]">{` ${clinicDetails?.eveningFromTime} - ${clinicDetails?.eveningToTime} `} </span>
                      )
                    }
                  </p>
                </div>
              </div>
              <div className="text-right text-[#184B67]">
                <label className="md:text-2xl text-sm font-bold text-[#244D67]">
                  {`${doctorData?.salutation} ${doctorData?.firstName} ${doctorData?.lastName}`}
                </label>
                <p className=" text-[#244D67] font-medium">
                  <span className="text-xs"> {doctorData?.qualification}</span>
                  <br />
                  <span className="text-xs font-medium text-[#244D67]">
                    {doctorData?.speciality}
                  </span>
                  <br />
                  Reg.no.{doctorData?.registrationNumber}
                </p>
              </div>
            </div>
          )
          :
          clinicDetails?.prescription_HeaderYN === 'C' && doctorData.headerDetails ?
            (
              <div dangerouslySetInnerHTML={{ __html: atob(doctorData.headerDetails).replace(/className=/g, 'class=') }} />
            ) :
            clinicDetails?.prescription_HeaderYN === 'N' ?
              (
                <div style={{
                  height: `${clinicDetails.prescription_Header_Space}px`
                }}
                  className="h-auto">

                </div>
              ) : ''

      }
    </>

  );
}

export default PrescriptionHeader;
