import React, { useEffect, useState } from "react";
import Layouts from "../../Layouts";
import { Input, Modal, message } from "antd";
import PatientList from "./PatientList";
import { useForm } from "antd/es/form/Form";
import "dayjs/plugin/utc";
import FilterPatient from "./FilterPatient";
import CommonFunctions from "../../Shared/CommonFunctions";
import PatientService from "../../../Services/PatientService";
import NewPatientRegister from "./NewPatientRegister";
import enrollmentServices from "../../../Services/enrollmentServices";
import { FaFilter } from "react-icons/fa6";
import { FaSearch } from "react-icons/fa";
import PatientQueue from "../VCPage/PatientQueue";
import patientqueue from "../../../Assets/Images/person-icon-blue.png";
import { IoIosCloseCircle } from "react-icons/io";
import Draggable from "react-draggable";
import { MdKeyboardDoubleArrowUp } from "react-icons/md";
import { IoPersonAdd } from "react-icons/io5";

function Patients() {
  const [saveModal, setSaveModal] = useState(false);
  const [form, setForm] = useForm();
  const [patientData, setPatientData] = useState([]);
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const [queueData, setQueueData] = useState();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isVerticalOpen, setIsVerticalOpen] = useState(true);

  const storedLoginResult = localStorage.getItem("login_result");

  const loginResultObject = storedLoginResult
    ? JSON.parse(storedLoginResult)
    : null;

  const getPatientList = () => {
    let payload = {
      account_Id: loginResultObject.user.account_Id,
    };
    PatientService.PatientListFilter(payload).then((res) => {
      setPatientData(res.data);
    });
  };

  // useEffect(() => {
  //   getPatientList();
  // }, []);

  const newModal = () => {
    setSaveModal(true);
  };
  const handleOk = () => {
    setSaveModal(false);
  };

  const handleCancel = () => {
    form.resetFields();
    setSaveModal(false);
  };

  const handleKeyPress = (e) => {
    const allowedKeys = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "Tab",
    ];
    if (!allowedKeys.includes(e.key)) {
      e.preventDefault();
    }
  };

  const handleInputChange = async (e) => {
    let value = e.target.value;
    setSearchPerformed(true);

    if (value.length > 3) {
      try {
        let payload = {
          firstName: value,
          // createdBy: loginResultObject.user.userId,
          account_Id: loginResultObject.user.account_Id,
          // clinic_Id: loginResultObject.user.clinic_Id,
        };
        const res = await PatientService.PatientListFilter(payload);
        if (res && res.data.length > 0) {
          setPatientData(res.data);
        } else {
          setPatientData([]);
        }
      } catch (e) {
        console.error(e);
      }
    } else {
      setPatientData([]);
    }
  };

  const showModal3 = () => {
    setSaveModal(true);
  };
  const showModal2 = () => {
    setFilterModal(true);
  };

  const handleFilterOk = () => {
    setFilterModal(false);
  };

  const handleFilterCancel = () => {
    setFilterModal(false);
  };

  const onClose = () => {
    setIsDrawerOpen(false);
  };
  const showDrawer = () => {
    const userObj = localStorage.getItem("login_result");
    const userData = userObj ? JSON.parse(userObj) : null;
    let payload = {
      userId: userData?.user?.userId,
      queueStatus: "Y",
    };
    PatientService.patientQueueList(payload).then((res) => {
      if (res.data.length) {
        let validate = res?.data?.filter(
          (x) => x.appointmentId != userData?.user?.appointmentId
        );
        if (validate && validate.length) {
          setQueueData(validate);
          setIsDrawerOpen(true);
        } else {
          message.warning("No patient available in queue");
        }
      } else {
        message.warning("No patient available in queue");
      }
    });
  };

  const handleClick = () => {
    setIsVerticalOpen(true);
  };
  const closeCircle = () => {
    setIsVerticalOpen(false);
  };
  return (
    <Layouts>
      <div className="flex flex-col">
        <div className="font-bold">Patients</div>
        <div className="flex">
          <div className="flex justify-center md:w-[80%] items-center md:p-4 p-2">
            <Input
              className="md:w-[40%] w-full mr-2"
              placeholder="Search Patient"
              size="large"
              onChange={handleInputChange}
              suffix={
                <span className="bg-blue-500 p-1 text-white rounded-md">
                  <FaSearch />
                </span>
              }
            />
            <button
              className="text-white font-semibold p-2 rounded-md flex items-center "
              onClick={showModal2}
            >
              <FaFilter className="mr-1" />
              Filter
            </button>
          </div>
          <div className="flex justify-end items-center w-[20%]">
            <button
              className="text-white font-semibold p-2  rounded-md"
              onClick={showModal3}
            >
              <IoPersonAdd />
            </button>
          </div>
        </div>

        {filterModal && (
          <Modal
            title="Filter Patient"
            open={filterModal}
            onOk={handleFilterOk}
            onCancel={handleFilterCancel}
            footer={[]}
            className="responsive-modal"
          >
            <FilterPatient
              setPatientData={setPatientData}
              handleFilterCancel={handleFilterCancel}
              setSearchPerformed={setSearchPerformed}
            />
          </Modal>
        )}
        {saveModal && (
          <Modal
            title="New Patient Registration"
            open={saveModal}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[]}
            width="60%"
            style={{
              top: "0",
            }}
          >
            <NewPatientRegister
              handleKeyPress={handleKeyPress}
              handleCancel={handleCancel}
              getPatientList={getPatientList}
              setSearchPerformed={setSearchPerformed}
            />
          </Modal>
        )}

        <div>
          {searchPerformed ? (
            patientData.length > 0 ? (
              <PatientList
                handleKeyPress={handleKeyPress}
                patientData={patientData}
                getPatientList={getPatientList}
                setPatientData={setPatientData}
              />
            ) : (
              <div className="flex justify-center  mt-40">
                No results found
              </div>
            )
          ) : (
            <div className="flex justify-center  mt-40"></div>
          )}
        </div>
      </div>

      {/* patient queue design */}
      {isVerticalOpen ? (
        <Draggable>
          <div className="draggable-container fixed top-1/2 right-2 w-14 transform -translate-y-1/2 z-50">
            <div
              className="hidden md:block relative mb-5 cursor-pointer"
              onClick={closeCircle}
            >
              <IoIosCloseCircle className="hidden md:block absolute mb-1 right-0 top-0" />
            </div>
            <div className="p-2 flex justify-center items-center rounded-lg bg-[#5584f4]">
              <img
                src={patientqueue}
                alt="patientqueue"
                className="w-10 h-10 cursor-pointer"
                title="Patient Queue"
                onClick={showDrawer}
              />
            </div>
          </div>
        </Draggable>
      ) : (
        <div
          className="vertical"
          style={{ marginTop: "16rem" }}
          onClick={handleClick}
        >
          <p className="vertical-p">
            <div className="vertical-icon-dashboard">
              <MdKeyboardDoubleArrowUp />
            </div>
            Queue
          </p>
        </div>
      )}

      {isDrawerOpen && (
        <div className="grid justify-end mt-5">
          {isDrawerOpen && (
            <PatientQueue
              queueData={queueData ? queueData : ""}
              onClose={onClose}
            />
          )}
        </div>
      )}
      {/* patient queue design end */}
    </Layouts>
   
  );
}

export default Patients;
