import React, { useEffect, useState } from "react";
import { message, Modal, Spin } from "antd";
import { useParams } from "react-router-dom";
import PharmacyService from "../../../Services/PharmacyService";
import PharmacyHeader from "./PharmacyHeader";
import PharmacyFooter from "./PharmacyFooter";
import dayjs from "dayjs";
import PrescriptionPharma from "./PrescriptionPharma";

function MedPharma() {
  const [params, setParams] = useState();

  const [medicines, setMedicines] = useState([]);

  const [doctorData, setDoctorData] = useState();
  const [hospitalAccountData, setHospitalAccountData] = useState(null);
  const [clinicDetails, setClinicDetails] = useState(null);
  const [consultationDetails, setConsultationDetails] = useState(null);
  const [patientDetails, setPatientDetails] = useState(null);
  const [pharmacyAndLabDetails, setPharmacyAndLabDetails] = useState(null);
  const [dispensed, setDispensed] = useState(true);
  const [hasDispensedId, sethasDispensedId] = useState(false);

  const [updatePayload, setUpdatePayload] = useState({});

  const getCodeFromURL = () => {
    const query = window.location.search;
    const codeMatch = query.match(/ref=([^&]*)/);
    return codeMatch ? codeMatch[1] : null;
  };

  const onDispenseBoxChange = () => {
    setDispensed(!dispensed);
  };

  const [prices, setPrices] = useState({});
  const [total, setTotal] = useState(0);

  const [loading, setLoading] = useState(true);

  const calculateAge = (dob) => {
    const dobData = dob;
    const dobYear = dobData?.split("/")[2];
    const currYear = new Date().getFullYear();
    if (dobYear) {
      const patientAge = currYear - Number(dobYear);
      return patientAge;
    }
  };

  const convertUTCDate = (date, format) => {
    if (!format) {
      var d = new Date();
      date = new Date(
        dayjs(date, "MM-DD-YYYY").format("MM/DD/YYYY") +
          " " +
          d.getHours() +
          ":" +
          d.getMinutes()
      );
    }
    format = format || "MM/DD/YYYY";
    return dayjs(date).utc().format(format);
  };

  const shareDetails = async () => {
    setLoading(true);
    let prescriptionDetailsPayload = {
      refNumber: params,
    };

    let prescriptionDetails = await PharmacyService.GetPrescriptionDetails(
      prescriptionDetailsPayload
    );

    console.log("prescriptionDetails", prescriptionDetails.data);

    setDoctorData(prescriptionDetails.data.userDetails);
    setHospitalAccountData(prescriptionDetails.data.accountDetails);
    setClinicDetails(prescriptionDetails.data.clinicDetails);
    setConsultationDetails(prescriptionDetails.data.consultationDetails);
    setPatientDetails(prescriptionDetails.data.patientDetails);
    setPharmacyAndLabDetails(prescriptionDetails.data.pharmacyAndLabDetails);

    let payload = {
      refNumber: params,
      status: 1,
    };

    let result = await PharmacyService.GetShareDetails(payload);

    let medlistpayload = {
      consultationId: result?.data[0].consultationId,
      status: 1,
    };

    let druglist = await PharmacyService.GetDispensedDrugsDtl(medlistpayload);
    const hasDispensedId = druglist.data?.some((item) => item.dispensedId);

    console.log("hasDispensedId inside the function", hasDispensedId);

    if (hasDispensedId) {
      let payload = {
        consultationId: consultationDetails?.consultationId,
        dispensedId: druglist.data[0]?.dispensedId,
        patientId: consultationDetails?.patientId,
        userId: consultationDetails?.userId,
        pharmacyId: pharmacyAndLabDetails?.pharmacyId,
        status: 1,
      };

      console.log("payload to set update drug", payload);
      try {
        let result = await PharmacyService.PharmacyGet(payload);
        console.log("result.", result.data[0]);
        console.log("result.", result.data[0].dispensedDrugsDtl);
        setUpdatePayload(result.data[0]);
        setMedicines(result.data[0].dispensedDrugsDtl);
        sethasDispensedId(hasDispensedId);
      } catch (error) {
        console.log("error", error);
      }
    } else {
      console.log("dispense id is not present");
      setMedicines(druglist.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    let param = getCodeFromURL();
    setParams(param);
  }, []);
  useEffect(() => {
    if (params) {
      shareDetails();
    }
  }, [params]);

  const handleValueChange = (e, field, index, medicine) => {
    let value = e.target.value;
    if (field === "dispensedQty") {
      medicine.dispensedQty = value;
    } else if (field === "amount") {
      medicine.amount = value;
      const parsedValue = parseFloat(value) || 0;
      setPrices((prevPrices) => {
        const updatedPrices = { ...prevPrices, [index]: parsedValue };
        calculateTotal(updatedPrices);
        return updatedPrices;
      });
    } else if (field === "alternativeDrugName") {
      medicine.alternativeDrugName = value;
    }

    medicine.createdBy = pharmacyAndLabDetails.pharmacyId;
    medicine.modifiedBy = pharmacyAndLabDetails.pharmacyId;
    medicine.createdDate = convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss");
    medicine.modifiedDate = convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss");
    medicine.consultationId = consultationDetails.consultationId;
    medicine.patientId = consultationDetails.patientId;
    medicine.userId = consultationDetails.userId;
    medicine.status = 1;
    medicine.pharmacyId = pharmacyAndLabDetails.pharmacyId;
    medicine.appointmentId = consultationDetails.appointmentId;

    setMedicines((prevData) => {
      const updatedData = [...prevData];
      updatedData[index] = medicine;
      return updatedData;
    });
  };

  const handleUpdateValueChnage = (e, field, index, medicine) => {
    let value = e.target.value;
    if (field === "dispensedQty") {
      medicine.dispensedQty = value;
    } else if (field === "amount") {
      medicine.amount = value;
      const parsedValue = parseFloat(value) || 0;
      setPrices((prevPrices) => {
        const updatedPrices = { ...prevPrices, [index]: parsedValue };
        calculateTotal(updatedPrices);
        return updatedPrices;
      });
    } else if (field === "alternativeDrugName") {
      medicine.alternativeDrugName = value;
    }

    medicine.modifiedBy = pharmacyAndLabDetails.pharmacyId;
    medicine.modifiedDate = convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss");
    medicine.consultationId = consultationDetails.consultationId;
    medicine.patientId = consultationDetails.patientId;
    medicine.userId = consultationDetails.userId;
    medicine.status = 1;
    medicine.pharmacyId = pharmacyAndLabDetails.pharmacyId;
    medicine.appointmentId = consultationDetails.appointmentId;

    setMedicines((prevData) => {
      const updatedData = [...prevData];
      updatedData[index] = medicine;
      return updatedData;
    });
  };

  useEffect(() => {
    console.log("medicines from useEffect", medicines);
  }, [medicines]);

  const calculateTotal = (updatedPrices) => {
    const newTotal = Object.values(updatedPrices).reduce(
      (acc, price) => acc + price,
      0
    );
    setTotal(parseFloat(newTotal).toFixed(2));
  };

  const [btndisable, setBtnDisable] = useState(false);

  const medicinePayloadSubmit = async () => {
    setBtnDisable(true);
    let payload = {
      refNumber: params,

      address1: patientDetails.patientAddress1
        ? patientDetails.patientAddress1
        : "",
      address2: patientDetails.patientAddress2
        ? patientDetails.patientAddress2
        : "",
      amount: total,
      appointmentId: consultationDetails.appointmentId,
      bloodGroup: patientDetails.patientBloodGroup,
      city: patientDetails.patientCity,
      consultationId: consultationDetails.consultationId,
      country: patientDetails.patientCountry,
      createdBy: pharmacyAndLabDetails.pharmacyId,
      createdDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
      dispensedDrugsDtl: medicines,
      dob: patientDetails.dob,
      email: patientDetails.patientEmail,
      firstName: patientDetails.patientFirstName,
      gender: patientDetails.patientGender,
      lastName: patientDetails.patientLastName,
      mobile: patientDetails.patientMobile,
      modifiedBy: pharmacyAndLabDetails.pharmacyId,
      modifiedDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
      paidAmount: total,
      parentPatientId: "",
      patientId: consultationDetails.patientId,
      pharmacyId: pharmacyAndLabDetails.pharmacyId,
      pharmacyName: pharmacyAndLabDetails.pharmacyName,
      pinCode: patientDetails.patientPinCode,
      salutation: patientDetails.patientSalutation,
      state: patientDetails.patientState,
      status: 1,
      userId: consultationDetails.userId,
      dispensed_YN: "Y",
      userName:
        doctorData.userSalutation +
        " " +
        doctorData.userFirstName +
        " " +
        doctorData.userLastName,
    };

    const hasError = payload.dispensedDrugsDtl.some((medicine) => {
      if (medicine.dispensedQty < 0) {
        message.error(
          `Dispensed quantity for ${medicine.drugName} is less than Zero.`
        );
        return true;
      }
      return false;
    });

    if (hasError) {
      setBtnDisable(false);
      return;
    }

    let pharmacyStatusPayload = {
      drugPrescriptionStatus: "Dispensed",
      rejectedPharmacyRemarks: "",
      consultationId: consultationDetails.consultationId,
    };

    PharmacyService.UpdatePharmacyStatus(pharmacyStatusPayload).then((res) => {
      console.log("UpdatePharmacyStatus", res);
    });

    try {
      const result = await PharmacyService.PharmacySave(payload);
      console.log("result", result);
      calculateTotalFromMedicines();
      message.success("Medicine Order Placed Successfully");
      shareDetails();
      setBtnDisable(false);
    } catch (error) {
      message.error("Make sure you entered all the Medicines");
      setBtnDisable(false);
      console.log("error", error);
    }
  };

  const medicinePayloadUpdate = async () => {
    setBtnDisable(true);
    updatePayload.dispensedDrugsDtl = medicines;
    updatePayload.amount = total;
    updatePayload.paidAmount = total;

    const hasError = updatePayload.dispensedDrugsDtl.some((medicine) => {
      if (medicine.dispensedQty < 0) {
        message.error(
          `Dispensed quantity for ${medicine.drugName} is less than zero.`
        );
        return true;
      }
      return false;
    });

    if (hasError) {
      setBtnDisable(false);
      return;
    }

    try {
      let updateResult = await PharmacyService.PharmacyUpdate(updatePayload);
      console.log("updateResult", updateResult);
      calculateTotalFromMedicines();
      shareDetails();
      setBtnDisable(false);
      message.success("Medicine Order Update Successfully");
    } catch (error) {
      console.log("error", error);
      setBtnDisable(false);
    }
    setBtnDisable(false);

    console.log("Updated", updatePayload);
  };

  const calculateTotalFromMedicines = () => {
    const newTotal = medicines.reduce((acc, medicine) => {
      const amount = parseFloat(medicine.amount) || 0;
      return acc + amount;
    }, 0);
    setTotal(parseFloat(newTotal).toFixed(2));
  };

  useEffect(() => {
    if (!loading) {
      calculateTotalFromMedicines();
    }
  }, [medicines, loading]);

  return (
    <>
      {loading && (
        <div class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          {/* <Spin /> */}
          <div className="spinner"></div>
          {/* <div class="w-16 h-16 border-t-4 border-b-4 border-white rounded-full animate-spin"></div> */}
        </div>
      )}
      <div className="m-4">
        <PharmacyHeader
          clinicDetails={clinicDetails}
          doctorData={doctorData}
          hospitalAccountData={hospitalAccountData}
          consultationDetails={consultationDetails}
        />
        <div className="my-4">
          <div className="md:flex justify-between items-center">
            <div className="md:flex gap-5 my-4 px-4 md:px-0">
              <div className="text-sm font-bold flex gap-1">
                Name:
                <span className="text-sm text-gray-500 font-semibold">
                  {patientDetails?.patientSalutation
                    ? patientDetails?.patientSalutation + " "
                    : ""}
                  {patientDetails?.patientFirstName
                    ? patientDetails?.patientFirstName + " "
                    : ""}
                  {patientDetails?.patientLastName
                    ? patientDetails?.patientLastName
                    : ""}
                </span>
              </div>
              <div className="text-sm font-bold md:flex gap-1">
                Age/Gender:
                <span className="text-sm text-gray-500 font-semibold">
                  {patientDetails?.dob ? calculateAge(patientDetails?.dob) : ""}
                  /
                  {patientDetails?.patientGender
                    ? patientDetails?.patientGender === "M"
                      ? "Male"
                      : "Female"
                    : ""}
                </span>
              </div>
              <div className="text-sm font-bold md:flex gap-1">
                Phone Number:
                <span className="text-sm text-gray-500 font-semibold">
                  {patientDetails?.patientMobile
                    ? patientDetails?.patientMobile
                    : ""}
                </span>
              </div>

              <div className="text-sm font-bold flex gap-1">
                Visit Type:
                <span className=" text-sm text-gray-500 font-semibold">
                  {consultationDetails?.consultationType}
                </span>
              </div>
            </div>
            <PrescriptionPharma
              doctorData={doctorData}
              hospitalAccountData={hospitalAccountData}
              clinicDetails={clinicDetails}
              consultationDetails={consultationDetails}
              patientDetails={patientDetails}
              pharmacyAndLabDetails={pharmacyAndLabDetails}
              calculateAge={calculateAge}
            />
          </div>

          <div className="w-full mx-auto bg-white shadow-md rounded-lg p-6">
            <div className="overflow-x-auto">
              <table className="min-w-full border-collapse">
                <thead>
                  <tr className="bg-gray-200">
                    <th className="border px-4 py-2 text-left">Medicine1</th>
                    <th className="border px-4 py-2 text-left">Qty</th>
                    <th className="border px-4 py-2 text-left">Dosage</th>
                    <th className="border px-4 py-2 text-left">Duration</th>
                    <th className="border px-4 py-2 text-left">
                      Alternative Med
                    </th>
                    <th className="border px-4 py-2 text-left">
                      Dispensed Qty
                    </th>
                    <th className="border px-4 py-2 text-left">Price</th>
                  </tr>
                </thead>
                <tbody>
                  {medicines.map((medicine, index) => (
                    <tr key={index}>
                      <td className="border px-2 py-2">{medicine.drugName}</td>
                      <td className="border px-2 py-2">{medicine.quantity}</td>
                      <td className="border px-2 py-2">{medicine.dosage}</td>
                      <td className="border px-2 py-2">{medicine.duration}</td>
                      <td className="border px-2 py-2">
                        <input
                          value={
                            medicine.alternativeDrugName
                              ? medicine.alternativeDrugName
                              : null
                          }
                          placeholder={`${
                            medicine.alternativeDrugName
                              ? medicine.alternativeDrugName
                              : medicine.drugName
                          }`}
                          type="text"
                          onChange={(e) =>
                            hasDispensedId
                              ? handleUpdateValueChnage(
                                  e,
                                  "alternativeDrugName",
                                  index,
                                  medicine
                                )
                              : handleValueChange(
                                  e,
                                  "alternativeDrugName",
                                  index,
                                  medicine
                                )
                          }
                          className="w-full h-full p-1 outline-none px-3"
                        />
                      </td>
                      <td className="border">
                        <input
                          value={
                            medicine.dispensedQty ? medicine.dispensedQty : null
                          }
                          type="number"
                          onChange={(e) =>
                            hasDispensedId
                              ? handleUpdateValueChnage(
                                  e,
                                  "dispensedQty",
                                  index,
                                  medicine
                                )
                              : handleValueChange(
                                  e,
                                  "dispensedQty",
                                  index,
                                  medicine
                                )
                          }
                          className="w-full h-full p-1 outline-none px-3"
                        />
                      </td>
                      <td className="border">
                        <input
                          type="number"
                          value={medicine.amount ? medicine.amount : null}
                          // onChange={(e) =>
                          //   handleValueChange(e, "amount", index, medicine)
                          // }

                          onChange={(e) =>
                            hasDispensedId
                              ? handleUpdateValueChnage(
                                  e,
                                  "amount",
                                  index,
                                  medicine
                                )
                              : handleValueChange(e, "amount", index, medicine)
                          }
                          className="w-full h-full p-1 outline-none px-3"
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td
                      colSpan="6"
                      className="border px-4 py-2 text-right font-semibold"
                    >
                      Total
                    </td>
                    <td className="border px-4 py-2">{total}</td>
                  </tr>
                </tfoot>
              </table>
            </div>

            <div className="px-4 py-2">
              <label className="inline-flex items-center">
                <input
                  checked={dispensed}
                  onChange={onDispenseBoxChange}
                  type="checkbox"
                  className="form-checkbox h-5 w-5 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                />
                <span className="ml-2">Dispensed</span>
              </label>
            </div>

            <div className="px-4 py-2 flex justify-end w-full">
              {!hasDispensedId ? (
                <button
                  disabled={btndisable}
                  onClick={medicinePayloadSubmit}
                  className="bg-blue-500 text-white px-4 py-2 rounded-md mr-4 border"
                >
                  Submit
                </button>
              ) : (
                <button
                  disabled={btndisable}
                  onClick={medicinePayloadUpdate}
                  className="bg-blue-500 text-white px-4 py-2 rounded-md mr-4 border"
                >
                  Update
                </button>
              )}
            </div>
          </div>
        </div>
        <PharmacyFooter
          clinicDetails={clinicDetails}
          doctorData={doctorData}
          hospitalAccountData={hospitalAccountData}
          consultationDetails={consultationDetails}
        />
      </div>
    </>
  );
}

export default MedPharma;
