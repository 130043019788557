import React, { useEffect, useState } from "react";
import Layouts from "../../Layouts";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Pagination,
  Row,
  Select,
  Space,
  Table,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import BillingService from "../../../Services/BillingService";
import { useForm } from "antd/es/form/Form";
import moment from "moment";
import { FaSearch } from "react-icons/fa";
import { FaFilter } from "react-icons/fa6";
import PatientService from "../../../Services/PatientService";
import PatientQueue from "../VCPage/PatientQueue";
import patientqueue from "../../../Assets/Images/person-icon-blue.png";
import dayjs from "dayjs";
import { SlArrowUp } from "react-icons/sl";
import { IoIosCloseCircle } from "react-icons/io";
import Draggable from "react-draggable";
import { MdKeyboardDoubleArrowUp } from "react-icons/md";
import convertUTCDateLocal from "../../../utils/convertToUtc";
import Preview from "../../../Assets/Images/Svg/PreviewIcon.svg";
import BillingPrint from "./BillingPrint";

function Payments() {
  const [tableData, setTableData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form, setForm] = useForm();
  const [filterApplied, setFilterApplied] = useState(false);
  const [dataStatus, setDataStatus] = useState("Table");
  const [apiPayload, setApiPayload] = useState({});
  const [page, setPage] = useState(0);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const storedLoginResult = localStorage.getItem("login_result");
  const loginResultObject = storedLoginResult
    ? JSON.parse(storedLoginResult)
    : null;

  useEffect(() => {
    getRecordList();
  }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    form.resetFields();
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  const formatDate = (date) => {
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  const getRecordList = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();
    const firstDate = new Date(year, month, 1);
    const lastDate = new Date(year, month + 1, 0);

    setFromDate(formatDate(firstDate));
    setToDate(formatDate(lastDate));

    let payload = {
      userId: loginResultObject.user.userId,
      fromDate: formatDate(firstDate),
      toDate: formatDate(lastDate),
      pageNumber: 0,
    };

    setApiPayload(payload);

    BillingService.BillingRecordsList(payload)
      .then((res) => {
        setTableData(res.data);
      })
      .catch((error) => {
        console.error("Error fetching billing records:", error);
      });

    BillingService.BillingCount(payload)
      .then((res) => {
        setPage(res.data.TOTAL_COUNT_STATUS);
      })
      .catch((error) => {
        console.error("Error fetching billing records:", error);
      });
  };

  const handleFinish = () => {
    let payload = form.getFieldValue();
    payload.userId = loginResultObject.user.userId;
    payload.pageNumber = 0;
    payload.fromDate = fromDate;
    payload.toDate = toDate;

    setApiPayload(payload);

    BillingService.BillingRecordsList(payload)
      .then((res) => {
        setTableData(res.data);
      })
      .catch((error) => {
        console.error("Error fetching billing records:", error);
      });

    BillingService.BillingCount(payload)
      .then((res) => {
        setPage(res.data.TOTAL_COUNT_STATUS);
      })
      .catch((error) => {
        console.error("Error fetching billing records:", error);
      });

    handleOk();
  };

  const handleMonthFilter = (value) => {
    const formattedDate = "01";
    const formattedMonth = value.format("MM");
    const formattedYear = value.format("YYYY");
    const fromDate = `${formattedMonth}/${formattedDate}/${formattedYear}`;
    const fromDateMoment = moment(fromDate, "MM/DD/YYYY");
    const lastDayOfMonth = fromDateMoment.endOf("month").format("MM/DD/YYYY");
    const toDate = lastDayOfMonth;

    setFromDate(fromDate);
    setToDate(toDate);

    let payload = {
      userId: loginResultObject.user.userId,
      fromDate: fromDate,
      toDate: toDate,
      pageNumber: 0,
    };

    setApiPayload(payload);

    BillingService.BillingRecordsList(payload)
      .then((res) => {
        setTableData(res.data);
      })
      .catch((error) => {
        console.error("Error fetching billing records:", error);
      });

    BillingService.BillingCount(payload)
      .then((res) => {
        setPage(res.data.TOTAL_COUNT_STATUS);
      })
      .catch((error) => {
        console.error("Error fetching billing records:", error);
      });
  };

  const handlePageChange = (pageNumber) => {
    apiPayload.pageNumber = pageNumber - 1;
    apiPayload.fromDate = fromDate;
    apiPayload.toDate = toDate;

    let payload = apiPayload;

    BillingService.BillingRecordsList(payload)
      .then((res) => {
        setTableData(res.data);
      })
      .catch((error) => {
        console.error("Error fetching billing records:", error);
      });

    if (apiPayload.pageNumber == 0) {
      BillingService.BillingCount(payload)
        .then((res) => {
          setPage(res.data.TOTAL_COUNT_STATUS);
        })
        .catch((error) => {
          console.error("Error fetching billing records:", error);
        });
    }
  };

  const convertUTCDateLocal = (utcDate, format = "DD/MM/YYYY, hh:mm A") => {
    const localDate = dayjs.utc(utcDate).local();
    return localDate.format(format);
  };

  const columns = [
    {
      title: "Patient Name",
      dataIndex: "patientName",
      render: (_, record) => (
        <Space size="middle" className="">
          <div className="flex flex-col">
            <div className="font-bold">{record.patientName}</div>
            <div>{record.patientId}</div>
          </div>
        </Space>
      ),
    },
    {
      title: "Appointment ID",
      dataIndex: "serviceTransId",
    },
    {
      title: "Paid Date",
      dataIndex: "paymentDate",
      render: (_, record) => convertUTCDateLocal(record.paymentDate),
    },
    {
      title: "Type of Service",
      dataIndex: "serviceName",
      render: (_, record) => (
        <Space size="middle" className="">
          <div className="text-black px-2 rounded py-1">
            {record.serviceName}
          </div>
        </Space>
      ),
    },
    {
      title: "Mode Of Payment",
      dataIndex: "paymode",
    },
    {
      title: "Transaction ID",
      dataIndex: "transactionId",
    },
    {
      title: "Payment Status",
      dataIndex: "status",
      render: (_, record) => (
        <Space size="middle" className="">
          <div className="flex items-center gap-2">
            <div>
              <div className="text-brand-primaryColor">
                {record.status == 1 ? "Completed" : "Pending"}
              </div>
            </div>
          </div>
        </Space>
      ),
    },

    {
      title: "Amount",
      dataIndex: "amount",
      render: (_, record) => (
        <Space size="middle" className="">
          <div className="text-black px-2 rounded py-1">{record.amount}</div>
        </Space>
      ),
    },
    {
      title: "Discount Amount",
      dataIndex: "discountAmount",
      render: (_, record) => (
        <Space size="middle" className="">
          <div className="text-black px-2 rounded py-1">
            {record.discountAmount}
          </div>
        </Space>
      ),
    },
    {
      title: "Paid Amount",
      dataIndex: "paidAmount",
      render: (_, record) => (
        <Space size="middle" className="">
          <div className="text-black px-2 rounded py-1">
            {record.paidAmount}
          </div>
        </Space>
      ),
    },
    {
      title: "Invoice",
      width: 100,
      dataIndex: "actions",
      render: (_, record) => {
        return (
          <Space size="middle" className="">
            <div className="flex items-center gap-2">
              <div size="middle" className="">
                <div className="flex gap-1 items-center cursor-pointer">
                  <img
                    src={Preview}
                    alt="Preview"
                    className="w-6 h-6"
                    role="button"
                    onClick={() => handleBillingPreview(record)}
                  />
                </div>
              </div>
            </div>
          </Space>
        );
      },
    },
  ];

  const onClose = () => {
    setIsDrawerOpen(false);
  };

  const showDrawer = () => {
    const userObj = localStorage.getItem("login_result");
    const userData = userObj ? JSON.parse(userObj) : null;
    let payload = {
      userId: userData?.user?.userId,
      queueStatus: "Y",
    };
    PatientService.patientQueueList(payload).then((res) => {
      if (res.data.length) {
        let validate = res?.data?.filter(
          (x) => x.appointmentId != userData?.user?.appointmentId
        );
        if (validate && validate.length) {
          setQueueData(validate);
          setIsDrawerOpen(true);
        } else {
          message.warning("No patient available in queue");
        }
      } else {
        message.warning("No patient available in queue");
      }
    });
  };
  const [queueData, setQueueData] = useState();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const handleButtonSearch = () => {
    let payload = {};
    payload.patientId = searchValue;
    payload.userId = loginResultObject.user.userId;
    payload.pageNumber = 0;
    payload.fromDate = fromDate;
    payload.toDate = toDate;

    setApiPayload(payload);

    BillingService.BillingRecordsList(payload)
      .then((res) => {
        setTableData(res.data);
        setSearchValue("");
      })
      .catch((error) => {
        console.error("Error fetching billing records:", error);
      });
  };

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    let searchValue = e.target.value;
    let payload = {};
    payload.patientName = searchValue;
    payload.userId = loginResultObject.user.userId;
    payload.pageNumber = 0;
    payload.fromDate = fromDate;
    payload.toDate = toDate;

    if (searchValue.length > 3) {
      setApiPayload(payload);
      BillingService.BillingRecordsList(payload)
        .then((res) => {
          setTableData(res.data);
          // setSearchValue("");
        })
        .catch((error) => {
          console.error("Error fetching billing records:", error);
        });
    }
    if (apiPayload.pageNumber == 0) {
      BillingService.BillingCount(payload)
        .then((res) => {
          setPage(res.data.TOTAL_COUNT_STATUS);
        })
        .catch((error) => {
          console.error("Error fetching billing records:", error);
        });
    }
  };
  const [isVerticalOpen, setIsVerticalOpen] = useState(true);
  const handleClick = () => {
    setIsVerticalOpen(true);
  };
  const closeCircle = () => {
    setIsVerticalOpen(false);
  };

  const [billModal, setBillModal] = useState(false);
  const [billingRecord, setBillingRecord] = useState({});

  const handleBillingPreview = (record) => {
    setBillingRecord(record);
    setBillModal(true);
  };

  const closeBillingModal = () => {
    setBillModal(false);
  };
  return (
    <Layouts>
      <div className="flex flex-col h-full">
        <div className="font-bold">Payments</div>
        <div className="flex">
          <div className="md:flex justify-end items-center md:w-[60%]">
            <Input
              className="md:w-[60%]"
              placeholder="Search Patient"
              allowClear
              value={searchValue}
              onChange={handleSearch}
              suffix={
                <button className="text-white font-semibold p-2 rounded-md">
                  <FaSearch />
                </button>
              }
            />
          </div>
          <div className="flex justify-end gap-2 md:w-[40%]">
            <div>
              <DatePicker
                onChange={handleMonthFilter}
                picker="month"
                format="MMM YYYY"
                defaultValue={dayjs()}
                allowClear={false}
                className="h-[40px]"
              />
            </div>

            <div className="flex ml-4 items-center justify-end">
              <button
                className="text-white font-semibold p-2 rounded-md flex items-center "
                onClick={showModal}
              >
                <FaFilter className="mr-1" />
                Filter
              </button>
            </div>
          </div>
        </div>

        <div className="mt-10  rounded-lg h-full">
          <div className="rounded-xl p-1">
            <Table
              className="p-0"
              columns={columns}
              dataSource={tableData}
              pagination={false}
              scroll={{ x: "max-content" }}
            />

            <div className="flex justify-end items-end my-4">
              <Pagination
                onChange={handlePageChange}
                defaultCurrent={1}
                total={page}
                showSizeChanger={false}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        title="Payments Filter"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
      >
        <Form
          className="formDesign"
          name="trigger"
          layout="vertical"
          form={form}
          onFinish={handleFinish}
        >
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item label="Patient Name" name="patientName">
                <Input className="w-full" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Patient ID" name="patientId">
                <Input className="w-full" />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label="Transaction Id" name="transactionId">
                <Input className="w-full" />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label="Service Type" name="serviceCode">
                <Select className="w-full" />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <div className="flex justify-end  border-brand-colorBorder gap-3 pt-2">
              <Button className="h-10 Btnwhite px-8" onClick={handleCancel}>
                Cancel
              </Button>
              <Button className="h-10 px-10" type="primary" htmlType="submit">
                Filter
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
      <div>
        {/* patient queue design */}
        {isVerticalOpen ? (
          <Draggable>
            <div className="draggable-container fixed top-1/2 right-2 w-14 transform -translate-y-1/2 z-50">
              <div
                className="hidden md:block relative mb-5 cursor-pointer"
                onClick={closeCircle}
              >
                <IoIosCloseCircle className="hidden md:block absolute mb-1 right-0 top-0" />
              </div>
              <div className="p-2 flex justify-center items-center rounded-lg bg-[#5584f4]">
                <img
                  src={patientqueue}
                  alt="patientqueue"
                  className="w-10 h-10 cursor-pointer"
                  title="Patient Queue"
                  onClick={showDrawer}
                />
              </div>
            </div>
          </Draggable>
        ) : (
          <div
            className="vertical"
            style={{ marginTop: "16rem" }}
            onClick={handleClick}
          >
            <p className="vertical-p">
              <div className="vertical-icon-dashboard">
                <MdKeyboardDoubleArrowUp />
              </div>
              Queue
            </p>
          </div>
        )}

        {isDrawerOpen && (
          <div className="grid justify-end mt-5">
            {isDrawerOpen && (
              <PatientQueue
                queueData={queueData ? queueData : ""}
                onClose={onClose}
              />
            )}
          </div>
        )}
        {/* patient queue design end */}

        {billModal && (
          <Modal
            title="Invoice Bill"
            width={1000}
            footer={null}
            open={billModal}
            onCancel={closeBillingModal}
          >
            <BillingPrint
              billingRecord={billingRecord}
              loginResultObject={loginResultObject}
            />
          </Modal>
        )}
      </div>
    </Layouts>
  );
}

export default Payments;
