import React, { useEffect, useState } from "react";
import InvitationService from "../../../Services/InvitationService";
import AppointmentCalender from "./AppointmentCalender";
import { useHistory } from "react-router-dom";
import {
  AppstoreOutlined,
  CalendarOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import AppointmentTable from "./AppointmentTable";
import { APP_ROUTES } from "../../../PageRouting/approutes";
import UpcomeAppointment from "../Appointments/UpcomeAppointment";
import MonthlyAppointments from "./MonthlyAppointment";
import AppointmentService from "../../../Services/AppointmentService";
import { FiGrid } from "react-icons/fi";
import AppointmentCard from "./AppointmentCard";
import { IoMdArrowRoundBack } from "react-icons/io";

function UpcomingAppointments({
  patientsInviteList,
  appointmentsList,
  setPatientsInviteList,
}) {
  const [view, setView] = useState("0");
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [upcomingList, setUpcomingList] = useState([]);
  const history = useHistory();

  useEffect(() => {
    getAppointmentList();
  }, []);

  const storedLoginResult = localStorage.getItem("login_result");
  const loginResultObject = storedLoginResult
    ? JSON.parse(storedLoginResult)
    : null;

  const handleView = (button) => {
    setView(button);
  };

  const handleClick = (patient) => {
    setSelectedPatient(patient);
    history.push(APP_ROUTES.VCPAGE, { selectedPatient: patient });
  };

  const getAppointmentList = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const tomorrowDate = tomorrow.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });
    const months = new Date();
    months.setDate(months.getDate() + 30);
    const futureDate = months.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });

    let payload = {
      userId: loginResultObject.user.userId,
      fromDate: tomorrowDate,
      toDate: futureDate,
    };
    AppointmentService.AppointmentFilter(payload)
      .then((result) => {
        setUpcomingList(result.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className="border border-brand-colorBorder rounded-xl md:p-4">
      <div className="md:flex justify-between items-center mb-8 ">
        <div
          className="text-lg tracking-wide font-medium flex gap-2 cursor-pointer p-4 md:p-0"
          onClick={() => setView("0")}
        >
          {view === "0" && <div>Today's Appointments</div>}
          {view === "1" && <div>Upcoming Appointments</div>}
          {view === "2" && <div>Today's Appointments</div>}
          {view === "3" && <div>Calender View</div>}
        </div>
        <div className="flex gap-2 ">
          <div
            class={`flex items-center justify-center space-x-2 font-semibold ${
              view === "3" ? "block" : "hidden"
            }`}
          >
            <span class="w-4 h-4 bg-yellow-400 "></span>
            <span className="ml-2">Past</span>
            <span class="w-4 h-4 bg-green-500 "></span>
            <span>Today</span>
            <span class="w-4 h-4 bg-blue-500 "></span>
            <span>Upcoming</span>
          </div>
          <button
            className={`w-10 h-10 flex justify-center items-center ${
              view === "0" ? "hidden" : "block"
            } bg-white border border-blue-600 rounded-md`}
            onClick={() => handleView("0")}
          >
            <IoMdArrowRoundBack />
          </button>
          <button
            className="w-10 h-10 bg-white md:ml-0 ml-4 border border-blue-600 rounded-md"
            onClick={() => handleView("2")}
          >
            <AppstoreOutlined />
          </button>
          <button
            className="w-10 h-10 bg-white border border-blue-600 rounded-md"
            onClick={() => handleView("1")}
          >
            <UnorderedListOutlined />
          </button>

          <button
            className="w-10 h-10 bg-white border border-blue-600 rounded-md"
            onClick={() => handleView("3")}
          >
            <CalendarOutlined />
          </button>
          <button
            className="w-20 h-10 bg-white border border-blue-600 rounded-md"
            onClick={() => history.push(APP_ROUTES.APPOINTMENTS)}
          >
            View All
          </button>
        </div>
      </div>

      {view === "0" && (
        <AppointmentTable
          appointmentsList={appointmentsList}
          handleClick={(data) => handleClick(data)}
          patientsInviteList={patientsInviteList}
        />
      )}

      {view === "1" && <MonthlyAppointments upcomingList={upcomingList} />}

      {view === "2" && <AppointmentCard appointmentsList={appointmentsList} />}

      {view === "3" && <AppointmentCalender />}
    </div>
  );
}

export default UpcomingAppointments;
