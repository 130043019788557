import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useLocation, useParams } from "react-router-dom";
import PatientService from "../../../Services/PatientService";
import { Base64 } from "js-base64";
import moment from "moment";
import sha256 from "crypto-js/sha256";
import { Buffer } from "buffer";
import PaymentService from "../../../Services/PaymentService";
import AppointmentService from "../../../Services/AppointmentService";
import dayjs from "dayjs";
import "dayjs/plugin/utc";
import { Flex, Spin } from "antd";
const contentStyle = {
  padding: 100,
  background: "rgba(0, 0, 0, 0.2)",
  borderRadius: 4,
};
const content = <div style={contentStyle} />;
dayjs.extend(require("dayjs/plugin/utc"));
function SharePayment(props) {
  const [params, setParams] = useState();

  const getCodeFromURL = () => {
    const query = window.location.search;
    const codeMatch = query.match(/ref=([^&]*)/);
    return codeMatch ? codeMatch[1] : null;
  };

  useEffect(() => {
    let param = getCodeFromURL();
    setParams(param);
    console.log("param", param);
    console.log("params", params);
  }, []);

  useEffect(() => {
    if (params) {
      callFunction();
    }
  }, [params]);

  const convertUTCDate = (date, format) => {
    if (!format) {
      var d = new Date();
      date = new Date(
        dayjs(date, "MM-DD-YYYY").format("MM/DD/YYYY") +
          " " +
          d.getHours() +
          ":" +
          d.getMinutes()
      );
    }
    format = format || "MM/DD/YYYY";
    return dayjs(date).utc().format(format);
  };
  const callFunction = async () => {
    PatientService.patientPaymentDetails(params).then((results) => {
      let appointmentDetails =
        results.data[0].transactionId +
        "|" +
        results.data[0].patientId +
        "|" +
        results.data[0].userId +
        "|" +
        results.data[0].serviceTransId +
        "|" +
        results.data[0].account_Id +
        "|" +
        results.data[0].clinic_Id;
      let base64string = Base64.encode(appointmentDetails);
      let paymentPayload = {
        merchantId: "DOXZONUAT",
        merchantTransactionId: results.data[0].transactionId,
        merchantUserId: "MUID123",
        amount: parseFloat(results.data[0]?.amount) * 100,
        redirectUrl: `${process.env.REACT_APP_PATIENT_BASEURL}phonepepayment/${base64string}`,
        redirectMode: "POST",
        callbackUrl: `${process.env.REACT_APP_API_BASEURL}phonePeResponse1/paymentCallBackDetails`,
        mobileNumber: results.data[0].mobile,
        paymentInstrument: {
          type: "PAY_PAGE",
        },
      };

      AppointmentService.GetAppointmentId(results.data[0].serviceTransId).then(
        (apptResult) => {
          console.log("apptResult", apptResult);
          console.log("apptResult2", apptResult.data[0]);

          const SALT_KEY = "58d4bcb6-f046-4e58-8c62-66505c0e32d9";
          const PHONE_PE_HOST_URL =
            "https://api-preprod.phonepe.com/apis/pg-sandbox/pg/v1/pay";
          let bufferObj = Buffer.from(JSON.stringify(paymentPayload), "utf8");
          let base64EncodedPayload = bufferObj.toString("base64");
          let string = base64EncodedPayload + "/pg/v1/pay" + SALT_KEY;
          let sha256_val = sha256(string);
          let xVerifyChecksum = sha256_val + "###" + 1;
          let phonepeTransaction = {
            account_Id: results.data[0]?.account_Id,
            checksum: xVerifyChecksum,
            clinic_id: results.data[0]?.clinic_Id,
            createdBy: results.data[0]?.patientId,
            createdDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
            modifiedDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
            paidDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
            modifiedBy: results.data[0].userId,
            patientId: results.data[0]?.patientId,
            enoCodePayload: base64EncodedPayload,
            providerreferenceId: "",
            serviceCode: `${
              apptResult.data[0].patientRegFeeYN == "Y"
                ? "APPT, REG_FEE"
                : "APPT"
            }`,
            serviceName: `${
              apptResult.data[0].patientRegFeeYN == "Y"
                ? "Appointment Consultation, Patient Registration Fee"
                : "Appointment Consultation"
            }`,
            status: 1,
            transactionId: results.data[0].transactionId,
            userId: results.data[0].userId,
            serviceTransId: results.data[0]?.serviceTransId,
            amount: apptResult.data[0]?.totalAmount,
          };

          PaymentService.PhonepeTransaction(phonepeTransaction).then((res1) => {
            if (res1.data === 1) {
              let appointmentId = results.data[0]?.serviceTransId;
              AppointmentService.GetAppointmentId(appointmentId).then(
                (res2) => {
                  let appointmentUpdate = {
                    appointmentId: results.data[0]?.appointmentId,
                    email: results.data[0]?.email,
                    meetingLink: results.data[0]?.meetingLink,
                    mobile: results.data[0]?.mobile,
                    id: res2?.data[0]?.id,
                    patientId: results.data[0]?.patientId,
                    status: 1,
                    paymentReceived: "Y",
                    userId: results.data[0]?.userId,
                    createdBy: results.data[0]?.userId,
                    modifiedBy: results.data[0]?.userId,
                  };
                  AppointmentService.AppointmentUpdate(appointmentUpdate);
                  let billingTransaction = {
                    account_Id: results.data[0]?.account_Id,
                    amount: res2?.data[0]?.totalAmount,
                    clinic_id: results.data[0]?.account_Id,
                    createdBy: results.data[0]?.patientId,
                    createdDate: convertUTCDate(
                      new Date(),
                      "YYYY-MM-DD HH:mm:ss"
                    ),
                    discountAmount: 0,
                    discountPercentage: 0,
                    gst: 0,
                    modifiedBy: results.data[0]?.patientId,
                    modifiedDate: convertUTCDate(
                      new Date(),
                      "YYYY-MM-DD HH:mm:ss"
                    ),
                    paidAmount: res2?.data[0]?.totalAmount,
                    patientId: results.data[0]?.patientId,
                    paymentDate: convertUTCDate(
                      new Date(),
                      "YYYY-MM-DD HH:mm:ss"
                    ),
                    paymode: "PhonePe",
                    serviceCode: "",
                    serviceCost: res2?.data[0]?.totalAmount,
                    serviceName: "",
                    serviceTransId: res2?.data[0]?.appointmentId,
                    status: 0,
                    transactionId: results.data[0]?.transactionId,
                    userId: results.data[0]?.userId,
                    billingTransactionServices: [],
                  };

                  let array1 = {
                    account_Id: results.data[0]?.account_Id,
                    amount: res2?.data[0]?.consultationFee,
                    clinic_id: results.data[0]?.clinic_Id,
                    createdBy: results.data[0]?.patientId,
                    createdDate: convertUTCDate(
                      new Date(),
                      "YYYY-MM-DD HH:mm:ss"
                    ),
                    fromDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
                    modifiedBy: results.data[0]?.patientId,
                    modifiedDate: convertUTCDate(
                      new Date(),
                      "YYYY-MM-DD HH:mm:ss"
                    ),
                    paidAmount: res2?.data[0]?.consultationFee,
                    patientId: results.data[0]?.patientId,
                    paymentDate: convertUTCDate(
                      new Date(),
                      "YYYY-MM-DD HH:mm:ss"
                    ),
                    paymode: "PhonePe",
                    serviceCode: "APPT",
                    serviceCost: res2?.data[0]?.consultationFee,
                    serviceName: "Appointment Consultation",
                    serviceTransId: res2?.data[0]?.appointmentId,
                    status: 0,
                    toDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
                    transactionId: results.data[0]?.transactionId,
                    userId: results.data[0]?.userId,
                  };

                  billingTransaction.billingTransactionServices.push(array1);

                  if (res2?.data[0]?.patientRegFeeYN == "Y") {
                    let array2 = {
                      account_Id: results.data[0]?.account_Id,
                      amount: res2?.data[0]?.patientRegFee,
                      clinic_id: results.data[0]?.clinic_Id,
                      createdBy: results.data[0]?.patientId,
                      createdDate: convertUTCDate(
                        new Date(),
                        "YYYY-MM-DD HH:mm:ss"
                      ),
                      fromDate: convertUTCDate(
                        new Date(),
                        "YYYY-MM-DD HH:mm:ss"
                      ),
                      modifiedBy: results.data[0]?.patientId,
                      modifiedDate: convertUTCDate(
                        new Date(),
                        "YYYY-MM-DD HH:mm:ss"
                      ),
                      paidAmount: res2?.data[0]?.patientRegFee,
                      patientId: results.data[0]?.patientId,
                      paymentDate: convertUTCDate(
                        new Date(),
                        "YYYY-MM-DD HH:mm:ss"
                      ),
                      paymode: "PhonePe",
                      serviceCode: "REG_FEE",
                      serviceCost: res2?.data[0]?.patientRegFee,
                      serviceName: "Patient Registration Fee",
                      serviceTransId: res2?.data[0]?.appointmentId,
                      status: 0,
                      toDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
                      transactionId: results.data[0]?.transactionId,
                      userId: results.data[0]?.userId,
                    };
                    billingTransaction.billingTransactionServices.push(array2);
                  }

                  PaymentService.BillingTransaction(billingTransaction).then(
                    (res3) => {
                      if (res3.data === 1) {
                        const configurationObject = {
                          method: "POST",
                          url: PHONE_PE_HOST_URL,
                          data: { request: base64EncodedPayload },
                          headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            "X-VERIFY": xVerifyChecksum,
                          },
                        };
                        axios(configurationObject).then((res4) => {
                          window.location.replace(
                            res4.data.data.instrumentResponse.redirectInfo.url
                          );
                        });
                      }
                    }
                  );
                }
              );
            }
          });
        }
      );
    });
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh", // Makes the container take up the full height of the viewport
        }}
      >
        <Flex align="center" gap="middle">
          <Spin tip="Loading" size="large">
            {content}
          </Spin>
        </Flex>
      </div>
    </>
  );
}

export default SharePayment;
