// import React, { useEffect, useState } from "react";
// import Layouts from "../../../Layouts";
// import FilterIcon from "../../../../Assets/Images/Svg/FilterIcon.svg";
// import { Button, Col, DatePicker, Divider, Form, Input, Modal, Row, Select } from 'antd';
// import Dragger from "antd/es/upload/Dragger";
// import { CloudUploadOutlined } from "@ant-design/icons";
// import AuthService from "../../../../Services/AuthService";
// import AddReport from "./AddReport";
// import PatientService from "../../../../Services/PatientService";
// import NoDataFound from "../../../../Assets/Images/Svg/No data-found.svg";

// const Reports = () => {
//   const activePatient = JSON.parse(localStorage.getItem("patient"));
//   const patientCode = activePatient?.patientId;
//   const [myReport, setMyReport] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [isModalVisible, setIsModalVisible] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [isModalOpen1, setIsModalOpen1] = useState(false);
//   const [selectedDoc, setSelectedDoc] = useState(null);
//   const [viewDoc, setviewDoc] = useState(false);
//   const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);

//   console.log(myReport, " heyyyy buddy");
//   useEffect(() => {
//     const fetchPermittedData = async () => {
//       await fetchPatientPermission();
//     }
//     fetchPermittedData();
//   }, []);

//   const fetchPatientPermission = async () => {
//     const userObj = localStorage.getItem('login_result');
//     const userData = userObj ? JSON.parse(userObj) : null;
//     const patientObj = localStorage.getItem("patient");
//     const patientData = patientObj ? JSON.parse(patientObj) : null;

//     const currDate = new Date().toISOString();
//     const date = currDate.split('T')[0];
//     const time = currDate.split('T')[1].slice(0, 8);
//     const constructedTime = date + " " + time;

//     let permissionPayload = {
//       patientId: patientData.patientId,
//       userId: userData?.user?.userId,
//       accessDate: constructedTime,
//       status: 1
//     }
//     try {
//       const response = await PatientService.isPermissionGranted(permissionPayload);

//       if (response?.data?.length !== 0 && response?.data[0]?.reports === "Y") {
//         PatientReport(response?.data[0]?.reports);
//       }
//       else if (response?.data?.length !== 0 && response?.data[0]?.reports === "N") {
//         PatientReport(response?.data[0]?.reports);
//       }
//       else {
//         PatientReport(null);
//       }

//     }
//     catch (e) {
//       console.log(e);
//     }
//   }

//   const handleCloseModal = () => {
//     setIsModalVisible(false);
//     fetchPatientPermission();
//   };

//   const showModal = () => {
//     setIsModalOpen(true);
//     setIsModalVisible(true)
//   };
//   const handleOk = () => {
//     setIsModalOpen(false);
//   };
//   const handleCancel = () => {
//     setIsModalOpen(false);
//   };

//   const showModal1 = () => {
//     setIsModalOpen1(true);
//   };
//   const handleOk1 = () => {
//     setIsModalOpen1(false);
//   };
//   const handleCancel1 = () => {
//     setIsModalOpen1(false);
//   };

//   const handleViewDoc = (report) => {
//     setSelectedDoc(report);
//     setviewDoc(true);
//   }

//   const handleDocOk = () => {
//     setviewDoc(false);
//   };
//   const handleDocCancel = () => {
//     setviewDoc(false);
//   };

//   const PatientReport = (permission) => {
//     const patient = JSON.parse(localStorage.getItem('patient'));
//     const userObj = localStorage.getItem('login_result');
//     const userData = userObj ? JSON.parse(userObj) : null;

//     let payload = {
//       userId: (permission === null || permission === "N") ? userData?.user?.userId : '',
//       patientId: patient.patientId,
//       documentType: "MY REPORT",
//       status: 1,
//     };
//     setLoading(true);

//     AuthService.getMyReport(payload)
//       .then((result) => {
//         setMyReport(result.data);
//       })
//       .catch((error) => {
//         console.error("Failed to fetch reports:", error);
//         setMyReport([]);
//       })
//       .finally(() => {
//         setLoading(false);
//       });
//   };
//   // const PatientReport = (permission) => {
//   //   const patient = JSON.parse(localStorage.getItem('patient'));
//   //   const userObj = localStorage.getItem('login_result')
//   //   const userData = userObj ? JSON.parse(userObj) : null;

//   //     let payload = {
//   //       // account_Id: userData?.user?.account_Id,
//   //       // clinic_Id: userData?.user?.clinic_Id,
//   //       userId: (permission === null || permission === "N") ? userData?.user?.userId : '',
//   //       patientId: patient.patientId,
//   //       documentType: "MY REPORT",
//   //       status: 1,
//   //     };
//   //     AuthService.getMyReport(payload).then((result) => {
//   //       setMyReport(result.data);
//   //     });
//   // };

//   return (
//     <>
//       <div className="p-4 border rounded shadow-md bg-slate-100">
//         <div className="flex justify-between ">
//           <div className="font-bold text-xl">MyReports</div>
//           <div className="flex  gap-3">
//             <Button onClick={showModal}
//               className="h-9 px-2 flex justify-center text-white items-center gap-2 custom_filter" >
//               + New
//             </Button>
//             <Button
//               className="h-9 px-2 flex justify-center text-white items-center gap-2 custom_filter"
//               onClick={showModal1}
//             >
//               <span>
//                 <img src={FilterIcon} alt="Icon" className="custom_filter hover:text-brand-primaryColor" />
//               </span>
//               <span className=""> Filter</span>
//             </Button>
//           </div>
//         </div>
//         <div className="my-6 flex">
//           {loading ? (
//             <div className="w-full flex h-screen justify-center items-center">
//               <div className="spinner"></div>
//             </div>
//           ) : myReport && myReport.length > 0 ? (
//             <div className="w-full grid grid-cols-2 gap-2">
//               {myReport.map((report, i) => (
//                 <div key={i} className="w-full">
//                   <div className="border shadow-lg p-3 rounded-lg my-2 bg-white">
//                     <div className="flex justify-between">
//                       <div className="flex flex-col gap-1">
//                         <label className="font-medium">Date</label>
//                         <p className="font-normal">{report.documentDate}</p>
//                       </div>
//                       <div className="flex flex-col gap-1">
//                         <label className="font-medium">Title</label>
//                         <p className="font-normal">{report.title}</p>
//                       </div>
//                       <div className="flex flex-col gap-1">
//                         <label className="font-medium">Document Type</label>
//                         <p className="font-normal">{report.documentType}</p>
//                       </div>
//                     </div>
//                     <div
//                       className="underline cursor-pointer text-brand-primaryColor my-1 font-medium"
//                       onClick={() => handleViewDoc(report)}
//                     >
//                       View Document
//                     </div>
//                   </div>
//                 </div>
//               ))}
//             </div>
//           ) : (
//             <div className="w-full flex h-screen justify-center items-center">
//               <div>
//                 <img src={NoDataFound} alt="No Data" className="w-24 h-24" />
//                 <p className="text-center">No Reports Found</p>
//               </div>
//             </div>
//           )}
//         </div>

//       </div>
//       {/* <div className={`container w-[600px]  rounded-md shadow-gray-500 shadow-md mx-auto item-center justify-center p-4 ${isMessageModalOpen ? 'flex' : 'hidden'}`}>
//         <div className="h-full">
//           <span className="bg-yellow-300 text-black font-bold p-1 rounded-lg w-6 h-6">
//             !
//           </span>{" "}
//           Patient needs to provide permission to view Reports
//         </div>
//       </div> */}

//       <Modal
//         title={<span className="font-bold">Add Report</span>}
//         visible={isModalVisible}
//         onOk={() => handleCloseModal()}
//         centered={true}
//         footer={null}
//         onCancel={() => handleCloseModal()}
//         okText="Submit"
//         cancelText="Cancel"
//         width={600}
//         maskClosable={false}
//       >
//         <AddReport onClose={handleCloseModal} />
//       </Modal>

//       <Modal
//         title={<span className="font-bold">Filter Reports</span>}
//         className="commonModal"
//         footer={false}
//         open={isModalOpen1}
//         onOk={showModal1}
//         onCancel={handleCancel1}
//       >
//         <Form
//           layout="vertical"
//           autoComplete="off"
//           className=" grid grid-cols-2 gap-5"
//         >
//           <Form.Item className="mb-0" label={<span className="font-medium">From Date</span>} name="fromDate">
//             <DatePicker
//               placeholder="fromDate "
//               className="w-full"
//               format="MM/DD/YYYY"
//             />
//           </Form.Item>
//           <Form.Item className="mb-0" label={<span className="font-medium">To Date</span>} name="toDate">
//             <DatePicker
//               placeholder="toDate"
//               className="w-full"
//               format="MM/DD/YYYY"
//             />
//           </Form.Item>
//         </Form>
//         <div className=" flex justify-end gap-3 mt-3 ">
//           <button
//             onClick={handleCancel1}
//             className="bg-White border-primaryColor border rounded-md text-base text-white p-2"
//           >
//             Cancel
//           </button>
//           <button
//             onClick={handleOk1}
//             className="bg-brand-primaryColor p-2 text-base rounded-md text-white"
//           >
//             Submit
//           </button>
//         </div>
//       </Modal>

//       <Modal
//         title={selectedDoc?.title}
//         open={viewDoc}
//         footer={[]}
//         onOk={handleDocOk}
//         onCancel={handleDocCancel}
//       >
//         {console.log(`${process.env.REACT_APP_IMAGE_URL}/${selectedDoc?.documentName}`)}
//         {
//           (selectedDoc?.documentName.endsWith(".jpg") || selectedDoc?.documentName.endsWith(".jpeg") || selectedDoc?.documentName.endsWith(".png")) ?
//             (<div className="flex justify-center items-center h-[inherit] w-[inherit]">
//               <img src={`${process.env.REACT_APP_IMAGE_URL}${selectedDoc?.documentName}`} alt="report" />
//             </div>)
//             : (
//               <iframe
//                 src={`${process.env.REACT_APP_IMAGE_URL}${selectedDoc?.documentName}`}
//                 title="report"
//                 style={{ width: "100%", height: "500px" }} // Adjust height as needed
//               />
//             )
//         }

//       </Modal>

//     </>

//   );
// };

// export default Reports;

import React, { useEffect, useRef, useState } from "react";
import Layouts from "../../../Layouts";
import FilterIcon from "../../../../Assets/Images/Svg/FilterIcon.svg";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Tabs,
} from "antd";
import Dragger from "antd/es/upload/Dragger";
import { CloudUploadOutlined } from "@ant-design/icons";
import AuthService from "../../../../Services/AuthService";
import AddReport from "./AddReport";
import PatientService from "../../../../Services/PatientService";
import NoDataFound from "../../../../Assets/Images/Svg/No data-found.svg";
import TabPane from "antd/es/tabs/TabPane";
import LabOrderService from "../../../../Services/LabOrderService";
import dayjs from "dayjs";

const Reports = ({ selectedPatient, permissionData }) => {
  const activePatient = JSON.parse(localStorage.getItem("patient"));
  const patientCode = activePatient?.patientId;
  const [myReport, setMyReport] = useState([]);
  const [labReports, setLabReports] = useState([]);
  const [labReportLoad, setLabReportLoad] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [labReportModal, setLabReportModal] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [viewDoc, setviewDoc] = useState(false);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const formRef = useRef();
  const formRef2 = useRef();

  useEffect(() => {
    fetchPatientPermission();
    setIsFiltered(false);
  }, []);

  const fetchPatientPermission = async () => {
    // const userObj = localStorage.getItem('login_result');
    // const userData = userObj ? JSON.parse(userObj) : null;
    // const patientObj = localStorage.getItem("patient");
    // const patientData = patientObj ? JSON.parse(patientObj) : null;

    // const currDate = new Date().toISOString();
    // const date = currDate.split('T')[0];
    // const time = currDate.split('T')[1].slice(0, 8);
    // const constructedTime = date + " " + time;

    // let permissionPayload = {
    //   patientId: patientData.patientId,
    //   userId: userData?.user?.userId,
    //   accessDate: constructedTime,
    //   status: 1
    // }
    // try {
    //   const response = await PatientService.isPermissionGranted(permissionPayload);

    //   if (response?.data?.length !== 0 && response?.data[0]?.reports === "Y") {
    //     PatientReport(response?.data[0]?.reports);
    //   }
    //   else if (response?.data?.length !== 0 && response?.data[0]?.reports === "N") {
    //     PatientReport(response?.data[0]?.reports);
    //   }
    //   else {
    //     PatientReport(null);
    //   }

    // }
    // catch (e) {
    //   console.log(e);
    // }
    if (permissionData && permissionData.reports === "Y") {
      PatientReport(permissionData.reports === "Y");
      patientLabReports(permissionData.reports === "Y");
    }
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    fetchPatientPermission();
  };

  const showModal = () => {
    setIsModalOpen(true);
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showModal1 = () => {
    setIsModalOpen1(true);
  };

  const handleLabReportModal = () => {
    setLabReportModal(!labReportModal);
  };

  const [btn, setBtn] = useState("1");

  const handleLabReportFilter = async () => {
    const { fromdate, todate } = formRef.current.getFieldsValue();
    const fromDate = dayjs(fromdate).format("MM/DD/YYYY");
    const toDate = dayjs(todate).format("MM/DD/YYYY");
    const payload = {
      patientId: selectedPatient.patientId,
      fromDate,
      toDate,
      status: 1,
    };
    try {
      const response = await LabOrderService.getLabReportsData(payload);
      setLabReports(response.data);
      setLabReportModal(false);
      setIsFiltered(true);
    } catch (e) {
      console.log(e);
    }
  };

  const handleClearFilter = () => {
    patientLabReports(permissionData.reports === "Y");
    setIsFiltered(false);
  };

  const handleOk1 = () => {
    const { fromDate, toDate } = formRef2.current.getFieldsValue();

    if (!fromDate && !toDate) {
      message.error("Select Dates to Filter");
      return;
    }

    const fromDate1 = fromDate ? dayjs(fromDate).format("MM/DD/YYYY") : null;
    const toDate1 = fromDate ? dayjs(toDate).format("MM/DD/YYYY") : null;

    const patient = JSON.parse(localStorage.getItem("patient"));
    const userObj = localStorage.getItem("login_result");
    const userData = userObj ? JSON.parse(userObj) : null;

    let payload = {
      userId: userData?.user?.userId,
      patientId: patient.patientId,
      documentType: "MY REPORT",
      status: 1,
    };
    payload.fromDate = fromDate1 ? fromDate1 : null;
    payload.toDate = toDate1 ? toDate1 : null;
    console.log("payload", payload);
    // return;

    setLoading(true);

    AuthService.getMyReport(payload)
      .then((result) => {
        setMyReport(result.data);
        setBtn("2");
      })
      .catch((error) => {
        console.error("Failed to fetch reports:", error);
        setMyReport([]);
      })
      .finally(() => {
        setLoading(false);
      });

    setIsModalOpen1(false);
  };
  const handleCancel1 = () => {
    setIsModalOpen1(false);
  };

  const handleViewDoc = (report) => {
    setSelectedDoc(report);
    setviewDoc(true);
  };

  const handleViewDoc2 = (report) => {
    setSelectedDoc(null);
    const reportObj = {
      ...report,
      documentName: report.resultDocumentName,
    };
    setSelectedDoc(reportObj);
    setviewDoc(true);
  };

  const handleDocOk = () => {
    setviewDoc(false);
  };
  const handleDocCancel = () => {
    setviewDoc(false);
  };

  const PatientReport = (permission) => {
    const patient = JSON.parse(localStorage.getItem("patient"));
    const userObj = localStorage.getItem("login_result");
    const userData = userObj ? JSON.parse(userObj) : null;

    let payload = {
      userId:
        permission === null || permission === "N" ? userData?.user?.userId : "",
      patientId: patient.patientId,
      documentType: "MY REPORT",
      status: 1,
    };
    setLoading(true);

    AuthService.getMyReport(payload)
      .then((result) => {
        setMyReport(result.data);
      })
      .catch((error) => {
        console.error("Failed to fetch reports:", error);
        setMyReport([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const patientLabReports = async (permission) => {
    const patient = JSON.parse(localStorage.getItem("patient"));
    const userObj = localStorage.getItem("login_result");
    const userData = userObj ? JSON.parse(userObj) : null;

    setLabReportLoad(true);
    let payload = {
      userId:
        permission === null || permission === "N" ? userData?.user?.userId : "",
      patientId: patientCode,
      status: 1,
    };

    try {
      const response = await LabOrderService.getLabReportsData(payload);
      console.log(response.data, "   hello");
      setLabReports(response.data);
      setLabReportLoad(false);
    } catch (e) {
      console.log(e);
      setLabReportLoad(false);
    }
  };
  // const PatientReport = (permission) => {
  //   const patient = JSON.parse(localStorage.getItem('patient'));
  //   const userObj = localStorage.getItem('login_result')
  //   const userData = userObj ? JSON.parse(userObj) : null;

  //     let payload = {
  //       // account_Id: userData?.user?.account_Id,
  //       // clinic_Id: userData?.user?.clinic_Id,
  //       userId: (permission === null || permission === "N") ? userData?.user?.userId : '',
  //       patientId: patient.patientId,
  //       documentType: "MY REPORT",
  //       status: 1,
  //     };
  //     AuthService.getMyReport(payload).then((result) => {
  //       setMyReport(result.data);
  //     });
  // };

  return (
    <>
      <Tabs>
        <TabPane tab="Medical Reports(self)" key={"1"}>
          <div className="p-4 border rounded shadow-md bg-slate-100">
            <div className="flex justify-between ">
              {/* <div className="font-bold text-xl">My Reports</div> */}
              <div></div>
              <div className="flex  gap-3">
                <Button
                  onClick={showModal}
                  className="h-9 px-2 flex justify-center text-white items-center gap-2 custom_filter"
                >
                  + New
                </Button>
                {btn === "1" ? (
                  <Button
                    className="h-9 px-2 flex justify-center text-white items-center gap-2 custom_filter"
                    onClick={showModal1}
                  >
                    <span>
                      <img
                        src={FilterIcon}
                        alt="Icon"
                        className="custom_filter hover:text-brand-primaryColor"
                      />
                    </span>
                    <span className=""> Filter</span>
                  </Button>
                ) : (
                  <Button
                    className="h-9 px-2 flex justify-center text-white items-center gap-2 custom_filter"
                    onClick={() => {
                      setBtn("1");
                      PatientReport("Z");
                    }}
                  >
                    <span>
                      <img
                        src={FilterIcon}
                        alt="Icon"
                        className="custom_filter hover:text-brand-primaryColor"
                      />
                    </span>
                    <span className=""> Clear</span>
                  </Button>
                )}
              </div>
            </div>
            <div className="my-6 flex">
              {loading ? (
                <div className="w-full flex h-screen justify-center items-center">
                  <div className="spinner"></div>
                </div>
              ) : myReport && myReport.length > 0 ? (
                <div className="w-full grid grid-cols-2 gap-2">
                  {myReport.map((report, i) => (
                    <div key={i} className="w-full">
                      <div className="border shadow-lg p-3 rounded-lg my-2 bg-white">
                        <div className="flex justify-between">
                          <div className="flex flex-col gap-1">
                            <label className="font-medium">Date</label>
                            <p className="font-normal">{report.documentDate}</p>
                          </div>
                          <div className="flex flex-col gap-1">
                            <label className="font-medium">Title</label>
                            <p className="font-normal">{report.title}</p>
                          </div>
                          <div className="flex flex-col gap-1">
                            <label className="font-medium">Document Type</label>
                            <p className="font-normal">{report.documentType}</p>
                          </div>
                        </div>
                        <div
                          className="underline cursor-pointer text-brand-primaryColor my-1 font-medium"
                          onClick={() => handleViewDoc(report)}
                        >
                          View Document
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="w-full flex h-screen justify-center items-center">
                  <div>
                    <img
                      src={NoDataFound}
                      alt="No Data"
                      className="w-24 h-24"
                    />
                    <p className="text-center">No Reports Found</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </TabPane>
        <TabPane tab="Lab Reports" key={"2"}>
          <div className="p-4 border rounded shadow-md bg-slate-100">
            <div className="flex justify-between ">
              {/* <div className="font-bold text-xl">Lab reports</div> */}
              <div></div>
              <div className="flex  gap-3">
                <Button
                  className="h-9 px-2 flex justify-center text-white items-center gap-2 custom_filter"
                  onClick={handleLabReportModal}
                >
                  <span>
                    <img
                      src={FilterIcon}
                      alt="Icon"
                      className="custom_filter hover:text-brand-primaryColor"
                    />
                  </span>
                  <span className=""> Filter</span>
                </Button>
              </div>
            </div>
            <div className="flex justify-end">
              {isFiltered && (
                <p
                  className="py-2 px-2 flex items-center gap-2 text-brand-primaryColor cursor-pointer"
                  onClick={handleClearFilter}
                >
                  Clear All
                </p>
              )}
            </div>
            <div className="my-6 flex">
              {loading ? (
                <div className="w-full flex h-screen justify-center items-center">
                  <div className="spinner"></div>
                </div>
              ) : labReports && labReports.length > 0 ? (
                <div className="w-full grid grid-cols-2 gap-2">
                  {labReports.map((report, i) => (
                    <div key={i} className="w-full">
                      <div className="border shadow-lg p-3 rounded-lg my-2 bg-white">
                        <div className="flex justify-between">
                          <div className="flex flex-col gap-1">
                            <label className="font-medium">Date</label>
                            <p className="font-normal">
                              {report.uploadedDateTime?.split(" ")[0]}
                            </p>
                          </div>
                          <div className="flex flex-col gap-1">
                            <label className="font-medium">Title</label>
                            <p className="font-normal">{report.labTestName}</p>
                          </div>
                          <div className="flex flex-col gap-1">
                            <label className="font-medium">Document Type</label>
                            <p className="font-normal">
                              {report.resultDocumentType}
                            </p>
                          </div>
                        </div>
                        <div
                          className="underline cursor-pointer text-brand-primaryColor my-1 font-medium"
                          onClick={() => handleViewDoc2(report)}
                        >
                          View Document
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="w-full flex h-screen justify-center items-center">
                  <div>
                    <img
                      src={NoDataFound}
                      alt="No Data"
                      className="w-24 h-24"
                    />
                    <p className="text-center">No Reports Found</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </TabPane>
      </Tabs>
      {/* <div className={`container w-[600px]  rounded-md shadow-gray-500 shadow-md mx-auto item-center justify-center p-4 ${isMessageModalOpen ? 'flex' : 'hidden'}`}>
        <div className="h-full">
          <span className="bg-yellow-300 text-black font-bold p-1 rounded-lg w-6 h-6">
            !
          </span>{" "}
          Patient needs to provide permission to view Reports
        </div>
      </div> */}

      <Modal
        title={<span className="font-bold">Add Report</span>}
        visible={isModalVisible}
        onOk={() => handleCloseModal()}
        centered={true}
        footer={null}
        onCancel={() => handleCloseModal()}
        okText="Submit"
        cancelText="Cancel"
        width={600}
        maskClosable={false}
      >
        <AddReport onClose={handleCloseModal} />
      </Modal>

      {/* Self documents filter */}
      <Modal
        title={<span className="font-bold">Filter Reports</span>}
        className="commonModal"
        footer={false}
        open={isModalOpen1}
        onOk={showModal1}
        onCancel={handleCancel1}
      >
        <Form
          layout="vertical"
          autoComplete="off"
          className=" grid grid-cols-2 gap-5"
          ref={formRef2}
        >
          <Form.Item
            className="mb-0"
            label={<span className="font-medium">From Date</span>}
            name="fromDate"
          >
            <DatePicker
              placeholder="fromDate "
              className="w-full"
              format="MM/DD/YYYY"
            />
          </Form.Item>
          <Form.Item
            className="mb-0"
            label={<span className="font-medium">To Date</span>}
            name="toDate"
          >
            <DatePicker
              placeholder="toDate"
              className="w-full"
              format="MM/DD/YYYY"
            />
          </Form.Item>
        </Form>
        <div className=" flex justify-end gap-3 mt-3 ">
          <button
            onClick={handleCancel1}
            className="bg-White border-primaryColor border rounded-md text-base text-white p-2"
          >
            Cancel
          </button>
          <button
            onClick={handleOk1}
            className="bg-brand-primaryColor p-2 text-base rounded-md text-white"
          >
            Submit
          </button>
        </div>
      </Modal>

      {/* Lab reports filter */}
      <Modal
        title={<span className="font-bold">Filter Reports</span>}
        className="commonModal"
        footer={false}
        open={labReportModal}
        onOk={handleLabReportModal}
        onCancel={handleLabReportModal}
      >
        <Form
          layout="vertical"
          autoComplete="off"
          className=" grid grid-cols-2 gap-5"
          ref={formRef}
        >
          <Form.Item
            className="mb-0"
            label={<span className="font-medium">From Date</span>}
            name="fromdate"
          >
            <DatePicker
              placeholder="fromDate "
              className="w-full"
              format="MM/DD/YYYY"
            />
          </Form.Item>
          <Form.Item
            className="mb-0"
            label={<span className="font-medium">To Date</span>}
            name="todate"
          >
            <DatePicker
              placeholder="toDate"
              className="w-full"
              format="MM/DD/YYYY"
            />
          </Form.Item>
        </Form>
        <div className=" flex justify-end gap-3 mt-3 ">
          <button
            onClick={handleLabReportModal}
            className="bg-White border-primaryColor border rounded-md text-base text-white p-2"
          >
            Cancel
          </button>
          <button
            onClick={handleLabReportFilter}
            className="bg-brand-primaryColor p-2 text-base rounded-md text-white"
          >
            Submit
          </button>
        </div>
      </Modal>

      <Modal
        title={selectedDoc?.title}
        open={viewDoc}
        footer={[]}
        onOk={handleDocOk}
        onCancel={handleDocCancel}
      >
        {selectedDoc?.documentName.endsWith(".jpg") ||
        selectedDoc?.documentName.endsWith(".jpeg") ||
        selectedDoc?.documentName.endsWith(".png") ? (
          <div className="flex justify-center items-center h-[inherit] w-[inherit]">
            <img
              src={`${process.env.REACT_APP_IMAGE_URL}${selectedDoc?.documentName}`}
              alt="report"
            />
          </div>
        ) : (
          <iframe
            src={`${process.env.REACT_APP_IMAGE_URL}${selectedDoc?.documentName}`}
            title="report"
            style={{ width: "100%", height: "500px" }} // Adjust height as needed
          />
        )}
      </Modal>
    </>
  );
};

export default Reports;
