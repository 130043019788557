import {
  Col,
  Input,
  Row,
  Form,
  DatePicker,
  Select,
  TimePicker,
  Button,
  message,
  Radio,
  Checkbox,
} from "antd";
import React, { useEffect, useState } from "react";
import enrollmentServices from "../../../Services/enrollmentServices";
import CommonFunctions from "../../Shared/CommonFunctions";
import dayjs from "dayjs";

function NewPatientInvite({
  paymentType,
  setPaymentType,
  appointmentsList,
  handleOk,
  form,
  handleKeyPress,
  paymentReceived,
  setPaymentReceived,
  callType,
  setCallType,
  accountDetails,
}) {
  const [save, setSave] = useState(false);
  const [endTime, setEndTime] = useState(null);
  const [submitType, setSubmitType] = useState(null);
  const [applyRegFee, setApplyRegFee] = useState(true);
  const [showFields, setShowFields] = useState(false);
  const [showFields2, setShowFields2] = useState(false);

  const onFeeCheckBoxChage = () => {
    setApplyRegFee(!applyRegFee);
  };

  const disabledDate = (current) => {
    return current && current < new Date().setHours(0, 0, 0, 0);
  };

  const storedLoginResult = localStorage.getItem("login_result");
  const loginResultObject = storedLoginResult
    ? JSON.parse(storedLoginResult)
    : null;

  const handleCallTypeChange = (value) => {
    setCallType(value);
  };

  const handleCancelClick = () => {
    setPaymentType("Cash");
    setCallType("APPT_VIDEO");
    handleOk();
  };

  const handleClick = async (payload) => {
    payload.createdDate = CommonFunctions.convertUTCDate(
      new Date(),
      "YYYY-MM-DD HH:mm:ss"
    );
    payload.modifiedDate = CommonFunctions.convertUTCDate(
      new Date(),
      "YYYY-MM-DD HH:mm:ss"
    );
    payload.appointmentDate = CommonFunctions.convertDate(
      payload.appointmentDate
    );

    payload.firstName =
      payload.firstName.charAt(0).toUpperCase() + payload.firstName.slice(1);

    payload.lastName = payload?.lastName
      ? payload?.lastName.charAt(0).toUpperCase() + payload?.lastName.slice(1)
      : "";
    payload.startTime = CommonFunctions.ConvertTime(payload.startTime);
    payload.endTime = CommonFunctions.ConvertTime(payload.endTime);
    payload.createdBy = loginResultObject.user.userId;

    payload.modifiedBy = loginResultObject.user.userId;
    payload.dob = payload.dob
      ? dayjs(payload?.dob, "MM/DD/YYYY").format("MM/DD/YYYY")
      : "";
    payload.userId = loginResultObject.user.userId;
    payload.account_Id = loginResultObject.user.account_Id;
    payload.clinic_Id = loginResultObject.user.clinic_Id;
    payload.patientType = "N";
    payload.specialityCode = loginResultObject.user?.specialityCode;
    payload.serviceName =
      payload.serviceCode === "APPT_VIDEO"
        ? "Video Consultation"
        : payload.serviceCode === "APPT_INPERSON"
        ? "Inperson Consultation"
        : "";
    if (payload.startTime > payload.endTime) {
      message.error("Select Valid Start time and End time");
      setSave(false);
      return;
    }

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (payload.email && !emailRegex.test(payload.email)) {
      console.log("payload", payload);
      message.error("Enter a valid email in the send invite");
      return;
    }

    const currentTime = CommonFunctions.ConvertTime(dayjs());

    if (
      payload.startTime < currentTime &&
      dayjs().format("MM/DD/YYYY") == payload.appointmentDate
    ) {
      message.error("Scheduled time has already ended");
      setSave(false);
      return;
    }

    payload.patientRegFeeYN = applyRegFee ? "Y" : "N";
    payload.patientRegFee = applyRegFee ? payload.patientRegFee : 0;

    payload.totalAmount =
      parseFloat(payload.patientRegFee) + parseFloat(payload.consultationFee);


    if (submitType === "1") {
      setSave(true);
      payload.status = 0;
      payload.paymentReceived = "N";
      let data = JSON.stringify(payload);

      CommonFunctions.NewPatientPayment(data)
        .catch((error) => {
          console.error("An error occurred:", error);
          message.error("An Error occurred, try again");
        })
        .finally(() => {
          setSave(false);
        });
    } else if (submitType === "2") {
      if (payload.paymentType == "Cash" && !paymentReceived) {
        message.error("Confirm Payment received by clicking the check box");
        setSave(false);
        return;
      }

      setSave(true);
      try {
        payload.paymentReceived = paymentReceived ? "Y" : "N";
        payload.status = 1;
        let data = JSON.stringify(payload);
        const result = await enrollmentServices.Invite(data);
        if (result.data.code === "FAILED") {
          message.error(result.data.message);
          setSave(false);
          return;
        }
        if (result.data.code === "SUCCESS") {
          message.success("New Patient Registration Successfull");
          message.success("Invitation sent Successfully");
          appointmentsList();
          handleOk();
        }
      } catch (error) {
        console.error("An error occurred:", error);
        message.error("An Error occured please try again");
      } finally {
        setSave(false);
      }
    } else if (submitType == "3") {
      setSave(true);
      try {
        if (
          payload.paymentType == "Digital" &&
          payload.paymentConsultation == "AC"
        ) {
          message.error(
            "Share payment link is not available for After Consultation"
          );
          return;
        }
        payload.status = 0;
        payload.paymentType = "Share_Link";
        let data = JSON.stringify(payload);
        const result = await enrollmentServices.Invite(data);
        if (result.data.code === "FAILED") {
          message.error(result.data.message);
          setSave(false);
          return;
        }
        if (result.data.code === "SUCCESS") {
          message.success("New Patient Registration Successfull");
          message.success("Payment Link and invitation sent Successfully");
          appointmentsList();
          handleOk();
        }
      } catch (error) {
        console.error("An error occurred:", error);
        message.error("An Error occured please try again");
      } finally {
        setSave(false);
      }
    }
  };

  const handleStartTimeChange = (time) => {
    if (time) {
      const endTime = dayjs(time).add(15, "minutes");
      setEndTime(endTime);
      form.setFieldsValue({ endTime });
    } else {
      setEndTime(null);
    }
  };

  const genders = [
    {
      value: "M",
      label: "Male",
    },
    {
      value: "F",
      label: "Female",
    },
  ];
  const salutationOptions = [
    {
      value: "Mr.",
      label: "Mr.",
    },
    {
      value: "Mrs.",
      label: "Mrs.",
    },
    {
      value: "Miss.",
      label: "Miss.",
    },
    {
      value: "Ms.",
      label: "Ms.",
    },
  ];

  const disabledFutureDate = (current) => {
    return current && current > new Date().setHours(23, 59, 59, 999);
  };

  const onPaymentStatusChange = () => {
    setPaymentReceived(!paymentReceived);
  };

  const onPaymentTypeChange = (value) => {
    setPaymentType(value);
    if (value === "Cash") {
      setPaymentReceived(true);
      form.setFieldsValue({ paymentConsultation: "BC" });
    } else if (value === "Digital") {
      form.setFieldsValue({ paymentConsultation: "BC" });
      setPaymentReceived(false);
    } else {
      form.setFieldsValue({ paymentConsultation: null });
      setPaymentReceived(false);
    }
  };

  const handleSubmitType = (type) => {
    setSubmitType(type);
  };

  return (
    <div className="my-2">
      {save && (
        <div class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="spinner"></div>
        </div>
      )}
      <Form
        className="formDesign mt-3"
        layout="vertical"
        form={form}
        onFinish={handleClick}
        initialValues={{
          serviceCode: "APPT_VIDEO",
          paymentType: "Cash",
          consultationFee: "500",
          paymentConsultation: "BC",
          appointmentDate: dayjs(),
          patientRegFee: accountDetails.patientRegFee,
          startTime: dayjs().add(3, "minutes"),
          endTime: dayjs().add(18, "minutes"),
        }}
      >
        <div className="rounded-lg bg-white p-3 shadow-lg">
          <Row gutter={24}>
            <Col span={12}>
              <div className="relative">
                <span className="absolute -top-1 z-10 left-3 bg-white px-1  text-xs">
                  Name <span className="text-red-500">*</span>
                </span>
                <Form.Item
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter First Name!",
                    },
                  ]}
                >
                  <Input
                    type="text"
                    placeholder=""
                    className="w-full mt-1 rounded-lg px-4 py-2"
                  />
                </Form.Item>
              </div>
            </Col>
            <Col span={12}>
              <div className="relative">
                <span className="absolute -top-1 z-10 left-3 bg-white px-1  text-xs">
                  Mobile <span className="text-red-500">*</span>
                </span>
                <Form.Item
                  name="mobile"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Mobile Number!",
                    },
                  ]}
                >
                  <Input
                    className="w-full mt-1 rounded-lg px-4 py-2"
                    type="text"
                    maxLength={15}
                    onKeyDown={handleKeyPress}
                    placeholder="Mobile"
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Row gutter={24}>
            {showFields && (
              <>
                <Col span={12}>
                  <div className="relative mt-2">
                    <span className="absolute -top-2 z-10 left-3 bg-white px-1 ">
                      Email ID
                    </span>
                    <Form.Item name="email">
                      <Input
                        type="text"
                        placeholder=""
                        className="w-full mt-1 rounded-lg px-4 py-2"
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col span={12}>
                  <div className="relative mt-2">
                    <span className="absolute -top-2 z-10 left-3 bg-white px-1 ">
                      Last Name
                    </span>
                    <Form.Item name="lastName">
                      <Input
                        type="text"
                        placeholder=""
                        className="w-full mt-1 rounded-lg px-4 py-2"
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col span={12}>
                  <div className="relative mt-2">
                    <span className="absolute -top-2 z-10 left-3 bg-white px-1 ">
                      D.O.B
                    </span>
                    <Form.Item name="dob">
                      <DatePicker
                        className="w-full mt-1 rounded-lg px-4 py-2"
                        format={"DD/MM/YYYY"}
                        disabledDate={disabledFutureDate}
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col span={12}>
                  <div className="relative mt-3">
                    <span className="absolute -top-3 z-10 left-3 bg-white px-1 ">
                      Gender
                    </span>
                    <Form.Item name="gender">
                      <Select className="w-full" options={genders} />
                    </Form.Item>
                  </div>
                </Col>
              </>
            )}
            <div className="flex w-full justify-end mt-2">
              <span
                className="px-2 text-blue-500 cursor-pointer"
                onClick={() => {
                  setShowFields(!showFields);
                }}
              >
                {!showFields ? "More..." : "Hide..."}
              </span>
            </div>
          </Row>
        </div>
        <div className="rounded-lg bg-white p-3 mt-5 shadow-lg">
          <Row gutter={24}>
            <Col span={12}>
              <div className="relative mt-2">
                <span className="absolute -top-1 z-10 left-3 bg-white px-1  text-xs">
                  Appointment Date <span className="text-red-500">*</span>
                </span>
                <Form.Item
                  name="appointmentDate"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter appointmentDate!",
                    },
                  ]}
                >
                  <DatePicker
                    format={"DD/MM/YYYY"}
                    defaultValue={dayjs()}
                    className="w-full mt-1 rounded-lg px-4 py-2"
                    disabledDate={disabledDate}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col span={12}>
              <div className="relative mt-3">
                <span className="absolute -top-2 z-10 left-3 bg-white px-1  text-xs">
                  Service Type <span className="text-red-500">*</span>
                </span>

                <Form.Item
                  name="serviceCode"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Consultation Type!",
                    },
                  ]}
                >
                  <Select
                    defaultValue={"APPT_VIDEO"}
                    showSearch={false}
                    className="w-full"
                    placeholder="Search to Consltations"
                    onChange={handleCallTypeChange}
                  >
                    <Select.Option value="APPT_INPERSON">
                      In-person
                    </Select.Option>
                    <Select.Option value="APPT_VIDEO">Video</Select.Option>
                  </Select>
                </Form.Item>
              </div>
            </Col>
            <Col span={12}>
              <div className="relative mt-2">
                <span className="absolute -top-1 z-10 left-3 bg-white px-1  text-xs">
                  Start Time <span className="text-red-500">*</span>
                </span>
                <Form.Item
                  name="startTime"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Start Time!",
                    },
                  ]}
                >
                  <TimePicker
                    use12Hours
                    format="h:mm a"
                    className="w-full mt-1 rounded-lg px-4 py-2"
                    onChange={handleStartTimeChange}
                    minuteStep={10}
                    needConfirm={false}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col span={12}>
              <div className="relative mt-2">
                <span className="absolute -top-1 z-10 left-3 bg-white px-1  text-xs">
                  End Time <span className="text-red-500">*</span>
                </span>
                <Form.Item
                  name="endTime"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter End Time!",
                    },
                  ]}
                >
                  <TimePicker
                    id="endTime"
                    use12Hours
                    format="h:mm a"
                    className="w-full mt-1 rounded-lg px-4 py-2"
                    value={endTime}
                    minuteStep={10}
                    needConfirm={false}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col className={`${showFields2 ? "block" : "hidden"}`} span={12}>
              <div className="relative mt-2">
                <span className="absolute -top-2 z-10 left-2 bg-white px-1 ">
                  Reason for Consultation
                </span>
                <Form.Item name="consultationReason">
                  <Input placeholder="Enter Consultation Reason" />
                </Form.Item>
              </div>
            </Col>
            <Col className={`${showFields2 ? "block" : "hidden"}`} span={12}>
              <div className="relative mt-2">
                <span className="absolute -top-2 z-10 left-2 bg-white px-1 ">
                  Enter Fees
                </span>
                <Form.Item
                  name="consultationFee"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Total Amount!",
                    },
                  ]}
                >
                  <Input placeholder="Enter Amount" />
                </Form.Item>
              </div>
            </Col>
            <div className="flex w-full justify-end mt-2">
              <span
                className="px-2 text-blue-500 cursor-pointer"
                onClick={() => {
                  setShowFields2(!showFields2);
                }}
              >
                {!showFields2 ? "More..." : "Hide..."}
              </span>
            </div>
          </Row>
        </div>
        <div className="bg-white rounded-lg  p-3 mt-5 shadow-lg">
          <Row gutter={24}>
            <Col span={12}>
              <div className="relative mt-2">
                <span className="absolute -top-2 z-10 left-2 px-1 ">
                  Payment
                </span>
                <Form.Item
                  name="paymentConsultation"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Payment Consultation!",
                    },
                  ]}
                >
                  <Radio.Group
                    className="mt-5"
                    disabled={
                      paymentType === "Cash" || paymentType === "Digital"
                        ? true
                        : false
                    }
                  >
                    <Radio value="BC" className="text-black">
                      Before
                    </Radio>
                    <Radio value="AC" className="text-black">
                      After
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
            </Col>
            <Col span={12}>
              <div className="relative mt-3">
                <span className="absolute -top-2 z-10 left-3 bg-white px-1  text-xs">
                  Payment Type <span className="text-red-500">*</span>
                </span>

                <Form.Item
                  name="paymentType"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Payment Type!",
                    },
                  ]}
                >
                  <Select
                    defaultValue={"Cash"}
                    showSearch
                    className="w-full text-xs"
                    placeholder="Search to Payment mode"
                    onChange={onPaymentTypeChange}
                  >
                    <Select.Option value="Cash">Cash</Select.Option>
                    <Select.Option value="Digital">
                      Digital Payment
                    </Select.Option>
                  </Select>
                </Form.Item>
              </div>
            </Col>
            {accountDetails.patientRegFeeYN === "Y" && (
              <>
                <Col span={12}>
                  <Form.Item name="patientRegFeeYN">
                    <div>
                      <Checkbox
                        checked={applyRegFee}
                        className="mr-2"
                        onChange={onFeeCheckBoxChage}
                      />
                      Registration Fee
                    </div>
                  </Form.Item>
                </Col>
              </>
            )}
            {applyRegFee && (
              <Col span={12}>
                <div className="relative mt-2">
                  <span className="absolute -top-2 z-10 left-3 bg-white px-1  text-xs">
                    Registration Fees
                  </span>
                  <Form.Item name="patientRegFee">
                    <Input className="w-full" placeholder="" />
                  </Form.Item>
                </div>
              </Col>
            )}
            {paymentType == "Cash" && (
              <>
                <Col>
                  <Form.Item name="">
                    <div>
                      <Checkbox
                        checked={paymentReceived}
                        className="mr-2"
                        onChange={onPaymentStatusChange}
                      />
                      Payment Received
                    </div>
                  </Form.Item>
                </Col>
                <Col span={12}></Col>
              </>
            )}
          </Row>
        </div>
        <Form.Item>
          <div className="flex gap-2 justify-end mt-5">
            <button
              className="border rounded-lg bg-white text-sm w-20"
              onClick={handleCancelClick}
            >
              Cancel
            </button>
            {paymentType == "Digital" ? (
              <>
                <button
                  className="bg-blue-600 border rounded-lg  w-40 p-2 text-sm text-white"
                  type="primary"
                  disabled={save}
                  htmlType="submit"
                  onClick={() => handleSubmitType("3")}
                >
                  Share Payment Link
                </button>
                <button
                  className="border rounded-lg bg-blue-600 w-28 text-white"
                  type="primary"
                  disabled={save}
                  htmlType="submit"
                  onClick={() => handleSubmitType("1")}
                >
                  {callType === "APPT_VIDEO"
                    ? "Pay & Invite"
                    : "Pay & Schedule"}
                </button>
                {/* <button
                  className="border rounded-lg text-white p-2 text-sm"
                  type="primary"
                  htmlType="submit"
                  disabled={save}
                  onClick={() => handleSubmitType("2")}
                >
                  {callType === "APPT_VIDEO" ? "Send Invite" : "Schedule"}
                </button> */}
              </>
            ) : (
              <>
                <button
                  className="border rounded-lg text-white p-2 text-sm"
                  type="primary"
                  htmlType="submit"
                  disabled={save}
                  onClick={() => handleSubmitType("2")}
                >
                  {callType === "APPT_VIDEO" ? "Send Invite" : "Schedule"}
                </button>
              </>
            )}
          </div>
        </Form.Item>
      </Form>
    </div>
  );
}

export default NewPatientInvite;
