import React, { useEffect, useState } from "react";
import Layouts from "../../Layouts";
import { Button, Modal, Segmented, Select, Spin, message } from "antd";
import DropdownArrowIcon from "../../../Assets/Images/Svg/dropdownArrow.svg";
import TodaysAppointment from "./TodaysAppointment";
import UpcomeAppointment from "./UpcomeAppointment";
import AddAppointments from "./AddAppointments";
import UpcomingAppointmentFilter from "./UpcomingAppointmentFilter";
import filterIcon from "../../../../src/Assets/Images/Svg/filter.svg";
import AppointmentService from "../../../Services/AppointmentService";
import TodayAppointmentFilter from "./TodayAppointmentFilter";
import patientqueue from "../../../Assets/Images/person-icon-blue.png";
import PatientService from "../../../Services/PatientService";
import PatientQueue from "../VCPage/PatientQueue";
import { SlArrowUp } from "react-icons/sl";
import { IoIosCloseCircle } from "react-icons/io";
import Draggable from "react-draggable";
import { FaFilter } from "react-icons/fa6";
import { MdKeyboardDoubleArrowUp } from "react-icons/md";

function Appointments() {
  const storedLoginResult = localStorage.getItem("login_result");
  const loginResultObject = storedLoginResult
    ? JSON.parse(storedLoginResult)
    : null;

  const [selectedOption, setSelectedOption] = useState("Today Appointments");
  const [upcomingList, setUpcomingList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [appointmentList, setAppointmentList] = useState([]);
  const [todayModal, setTodayModal] = useState(false);
  const [queueData, setQueueData] = useState();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [filterDate, setFilterDate] = useState("");
  const [maintainbg, setMaintainbg] = useState(true);
  const [loading, setLoading] = useState(false);
  const [apiPayload, setApiPayload] = useState({});

  const [todayStatusValue, setTodayStatusValue] = useState("");
  const [todayTypeValue, setTodayTypeValue] = useState("1");

  const [upcomingStatusValue, setUpcomingStatusValue] = useState("");
  const [upcomingTypeValue, setUpcomingTypeValue] = useState("1");

  const showModal = () => setIsModalOpen(true);
  const handleOk = () => setIsModalOpen(false);
  const handleCancel = () => setIsModalOpen(false);

  const showTodayModal = () => setTodayModal(true);
  const todayFilter = () => setTodayModal(false);
  const todayCancel = () => setTodayModal(false);

  const fromDate = new Date();
  const formattedDate = fromDate.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  const tomorrowDate = tomorrow.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });
  const months = new Date();
  months.setDate(months.getDate() + 30);
  const futureDate = months.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    if (option == "Today Appointments") {
      getAppointmentList();
      setFilterDate("");
      setMaintainbg(true);
    } else {
      upcomingAppointmentList();
      setFilterDate("");
      setMaintainbg(true);
    }
  };

  const getAppointmentList = () => {
    setLoading(true);
    let payload = {
      fromDate: formattedDate,
      userId: loginResultObject.user.userId,
    };
    setApiPayload(payload);
    AppointmentService.AppointmentFilter(payload)
      .then((result) => {
        setAppointmentList(result.data);
        setLoading(false);
        setTodayStatusValue("");
        setTodayTypeValue("1");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getAppointmentList();
  }, []);

  const todayStatus = (value) => {
    setTodayStatusValue(value);
    apiPayload.status = value;
    // let payload = {
    //   status: value,
    //   fromDate: formattedDate,
    //   userId: loginResultObject.user.userId,
    // };
    AppointmentService.AppointmentFilter(apiPayload)
      .then((result) => {
        setAppointmentList(result.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const todayTypeChange = (value) => {
    setTodayTypeValue(value);
    if (value === "1") {
      let payload = {
        userId: loginResultObject.user.userId,
        fromDate: apiPayload.fromDate ? apiPayload.fromDate : formattedDate,
      };
      AppointmentService.AppointmentFilter(payload)
        .then((result) => {
          setAppointmentList(result.data);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      apiPayload.serviceCode = value;
      // let payload = {
      //   serviceCode: value,
      //   userId: loginResultObject.user.userId,
      //   fromDate: formattedDate,
      // };
      AppointmentService.AppointmentFilter(apiPayload)
        .then((result) => {
          setAppointmentList(result.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const upcomingAppointmentList = () => {
    setLoading(true);
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const tomorrowDate = tomorrow.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });
    const months = new Date();
    months.setDate(months.getDate() + 30);
    const futureDate = months.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });

    let payload = {
      userId: loginResultObject.user.userId,
      fromDate: tomorrowDate,
      toDate: futureDate,
    };
    setApiPayload(payload);
    AppointmentService.AppointmentFilter(payload)
      .then((result) => {
        setUpcomingList(result.data);
        setLoading(false);
        setUpcomingStatusValue("");
        setUpcomingTypeValue("1");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleUpcomingStatus = (value) => {
    setUpcomingStatusValue(value);
    // let payload = {
    //   status: value,
    //   userId: loginResultObject.user.userId,
    //   fromDate: tomorrowDate,
    //   toDate: futureDate,
    // };
    apiPayload.status = value;
    AppointmentService.AppointmentFilter(apiPayload)
      .then((result) => {
        setUpcomingList(result.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const upcomingTypeChange = (value) => {
    setUpcomingTypeValue(value);
    if (value === "1") {
      // let payload = {
      //   status: value,
      //   userId: loginResultObject.user.userId,
      //   fromDate: tomorrowDate,
      //   toDate: futureDate,
      // };
      apiPayload.status = value;
      AppointmentService.AppointmentFilter(apiPayload)
        .then((result) => {
          setUpcomingList(result.data);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      // let payload = {
      //   serviceCode: value,
      //   userId: loginResultObject.user.userId,
      //   toDate: tomorrowDate,
      // };
      apiPayload.serviceCode = value;
      AppointmentService.AppointmentFilter(apiPayload)
        .then((result) => {
          setUpcomingList(result.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const onClose = () => {
    setIsDrawerOpen(false);
  };
  const showDrawer = () => {
    const userObj = localStorage.getItem("login_result");
    const userData = userObj ? JSON.parse(userObj) : null;
    let payload = {
      userId: userData?.user?.userId,
      queueStatus: "Y",
    };
    PatientService.patientQueueList(payload).then((res) => {
      if (res.data.length) {
        let validate = res?.data?.filter(
          (x) => x.appointmentId != userData?.user?.appointmentId
        );
        if (validate && validate.length) {
          setQueueData(validate);
          setIsDrawerOpen(true);
        } else {
          message.warning("No patient available in queue");
        }
      } else {
        message.warning("No patient available in queue");
      }
    });
  };

  const handleClick = () => {
    setIsVerticalOpen(true);
  };
  const closeCircle = () => {
    setIsVerticalOpen(false);
  };
  const [isVerticalOpen, setIsVerticalOpen] = useState(true);

  return (
    <Layouts>
      <div className="">
        <div className="flex justify-between items-center mb-8 ">
          <div className="font-bold">Appointments</div>
          <AddAppointments getAppointmentList={getAppointmentList} />
        </div>
        <div className="md:flex justify-between items-center mb-8">
          <div className="w-[350px] md:w-[500px] flex md:gap-2 items-center -ml-4 md:ml-0 mb-4">
            <div className="flex justify-center items-center cursor-pointer rounded-full bg-white">
              <div
                className={`rounded-full md:px-8 px-4 py-3  font-normal text-xs md:text-base ${
                  selectedOption == "Today Appointments" && maintainbg
                    ? "bg-[#1D64C8] text-white"
                    : "text-gray-600"
                }`}
                onClick={() => handleOptionChange("Today Appointments")}
              >
                Today Appointments
              </div>
              <div
                className={`rounded-full md:px-8 px-4 py-3  font-normal text-xs md:text-base ${
                  selectedOption == "Upcoming Appointments" && maintainbg
                    ? "bg-[#1D64C8] text-white"
                    : "text-gray-600"
                }`}
                onClick={() => handleOptionChange("Upcoming Appointments")}
              >
                Upcoming Appointments
              </div>
            </div>
          </div>
          {/* today appointment filter */}
          {selectedOption === "Today Appointments" && (
            <div className=" flex md:gap-5 gap-2 ">
              <div className="flex items-center gap-5">
                <Select
                  className="md:w-36 w-24 h-6 md:h-10"
                  placeholder="Status"
                  suffixIcon={<img src={DropdownArrowIcon} alt="img" />}
                  onChange={todayStatus}
                  // value={todayStatusValue}
                >
                  <Select.Option value="2">Completed</Select.Option>
                  <Select.Option value="0">Cancelled</Select.Option>
                  <Select.Option value="1">Pending</Select.Option>
                </Select>
              </div>
              <div className="flex items-center gap-5">
                <Select
                  className="md:w-36 w-24 md:h-10 h-6"
                  defaultValue="All Consultations"
                  suffixIcon={<img src={DropdownArrowIcon} alt="img" />}
                  onChange={todayTypeChange}
                  value={todayTypeValue}
                >
                  <Select.Option value="APPT_INPERSON">In-person</Select.Option>
                  <Select.Option value="APPT_VIDEO">Video</Select.Option>
                  <Select.Option value="1">All Consultation</Select.Option>
                </Select>
              </div>
              <div className="flex ml-4 items-center justify-end">
                <button
                  className="text-white md:p-2 p-1 rounded-md flex items-center "
                  onClick={showTodayModal}
                >
                  <FaFilter className="md:mr-1" />
                  Filter
                </button>
              </div>
            </div>
          )}
          {/* upcoming appointment logic comes here */}
          {selectedOption === "Upcoming Appointments" && (
            <div className=" flex gap-5 ">
              <div className="flex items-center md:gap-5 gap-2">
                <Select
                  className="md:w-36 w-24 md:h-10 h-6"
                  placeholder="Status"
                  suffixIcon={<img src={DropdownArrowIcon} alt="img" />}
                  onChange={handleUpcomingStatus}
                  // value={upcomingStatusValue}
                >
                  <Select.Option value="0">Cancelled</Select.Option>
                  <Select.Option value="1">Pending</Select.Option>
                </Select>
              </div>
              <div className="flex items-center md:gap-5 gap-2">
                <Select
                  className="md:w-36 w-24 md:h-10 h-6"
                  defaultValue="All Consultations"
                  suffixIcon={<img src={DropdownArrowIcon} alt="img" />}
                  onChange={upcomingTypeChange}
                  value={upcomingTypeValue}
                >
                  <Select.Option value="APPT_INPERSON">In-person</Select.Option>
                  <Select.Option value="APPT_VIDEO">Video</Select.Option>
                  <Select.Option value="1">All Consultation</Select.Option>
                </Select>
              </div>
              <div className="flex md:ml-4 items-center justify-end">
                <button
                  className="text-white md:p-2 p-1 rounded-md flex items-center "
                  onClick={showModal}
                >
                  <FaFilter className="md:mr-1" />
                  Filter
                </button>
              </div>
            </div>
          )}
        </div>
        {filterDate !== "" && (
          <div className="my-4">
            <span className="font-bold">Appointment Date :</span> {filterDate}
          </div>
        )}
        {loading ? (
          <div className="flex justify-center items-center mt-40">
            <Spin />
          </div>
        ) : (
          <>
            <div className="rounded-xl p-1 h-full">
              {selectedOption === "Today Appointments" && (
                <TodaysAppointment
                  appointmentList={appointmentList}
                  getAppointmentList={getAppointmentList}
                />
              )}

              {selectedOption === "Upcoming Appointments" && (
                <UpcomeAppointment
                  upcomingList={upcomingList}
                  setUpcomingList={setUpcomingList}
                  upcomingAppointmentList={upcomingAppointmentList}
                />
              )}
            </div>

            {/* patient queue design */}
            {isVerticalOpen ? (
              <Draggable>
                <div className="draggable-container fixed top-1/2 right-2 w-14 transform -translate-y-1/2 z-50">
                  <div
                    className="hidden md:block relative mb-5 cursor-pointer"
                    onClick={closeCircle}
                  >
                    <IoIosCloseCircle className="hidden md:block absolute mb-1 right-0 top-0" />
                  </div>
                  <div className="p-2 flex justify-center items-center rounded-lg bg-[#5584f4]">
                    <img
                      src={patientqueue}
                      alt="patientqueue"
                      className="w-10 h-10 cursor-pointer"
                      title="Patient Queue"
                      onClick={showDrawer}
                    />
                  </div>
                </div>
              </Draggable>
            ) : (
              <div
                className="vertical"
                style={{ marginTop: "16rem" }}
                onClick={handleClick}
              >
                <p className="vertical-p">
                  <div className="vertical-icon-dashboard">
                    <MdKeyboardDoubleArrowUp />
                  </div>
                  Queue
                </p>
              </div>
            )}

            {isDrawerOpen && (
              <div className="grid justify-end mt-5">
                {isDrawerOpen && (
                  <PatientQueue
                    queueData={queueData ? queueData : ""}
                    onClose={onClose}
                  />
                )}
              </div>
            )}
          </>
        )}

        {todayModal && (
          <Modal
            title="Today Appointments Filter"
            open={todayModal}
            onOk={todayFilter}
            onCancel={todayCancel}
            footer={[]}
          >
            <TodayAppointmentFilter
              setFilterDate={setFilterDate}
              upcomingList={upcomingList}
              setAppointmentList={setAppointmentList}
              todayFilter={todayFilter}
              todayCancel={todayCancel}
              setMaintainbg={setMaintainbg}
              setApiPayload={setApiPayload}
              setTodayStatusValue={setTodayStatusValue}
              setTodayTypeValue={setTodayTypeValue}
            />
          </Modal>
        )}

        {isModalOpen && (
          <Modal
            title="Upcoming Appointments Filter"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[]}
          >
            <UpcomingAppointmentFilter
              upcomingList={upcomingList}
              setFilterDate={setFilterDate}
              setUpcomingList={setUpcomingList}
              handleOk={handleOk}
              handleCancel={handleCancel}
              setMaintainbg={setMaintainbg}
              setApiPayload={setApiPayload}
              setUpcomingStatusValue={setUpcomingStatusValue}
              setUpcomingTypeValue={setUpcomingTypeValue}
            />
          </Modal>
        )}
      </div>
    </Layouts>
  );
}

export default Appointments;
