import React, { useState } from "react";
import {
  Col,
  Input,
  Row,
  Form,
  DatePicker,
  Select,
  Checkbox,
  TimePicker,
  Button,
  message,
  Radio,
} from "antd";
import CommonFunctions from "../../Shared/CommonFunctions";
import { useForm } from "antd/es/form/Form";
import dayjs from "dayjs";
import enrollmentServices from "../../../Services/enrollmentServices";

function PatientInvite({
  inviteDetails,
  handleInviteOk,
  callType,
  setCallType,
}) {
  const [form, setForm] = useForm();
  const [endTime, setEndTime] = useState(null);
  const [save, setSave] = useState(false);

  const [submitType, setSubmitType] = useState(null);

  const [paymentReceived, setPaymentReceived] = useState(true);
  const [paymentType, setPaymentType] = useState("Cash");

  const handleCallTypeChange = (value) => {
    setCallType(value);
  };

  const handleSubmitType = (type) => {
    setSubmitType(type);
  };

  const handleStartTimeChange = (time) => {
    if (time) {
      const endTime = dayjs(time).add(15, "minutes");
      setEndTime(endTime);
      form.setFieldsValue({ endTime });
    } else {
      setEndTime(null);
    }
  };

  const handleCancel = () => {
    handleInviteOk();
    form.resetFields();
    setPaymentReceived(false);
    setPaymentType("Cash");
  };

  const storedLoginResult = localStorage.getItem("login_result");
  const loginResultObject = storedLoginResult
    ? JSON.parse(storedLoginResult)
    : null;

  const disabledDate = (current) => {
    return current && current < new Date().setHours(0, 0, 0, 0);
  };

  const onPaymentStatusChange = () => {
    setPaymentReceived(!paymentReceived);
  };

  const handleSubmit = async (payload) => {
    setSave(true);
    let data = {
      consultationReason: payload?.consultationReason,
      createdBy: loginResultObject.user.userId,
      createdDate: CommonFunctions.convertUTCDate(
        new Date(),
        "YYYY-MM-DD HH:mm:ss"
      ),
      email: inviteDetails?.email,
      account_Id: loginResultObject.user.account_Id,
      clinic_Id: loginResultObject.user.clinic_Id,
      firstName: inviteDetails?.firstName,
      lastName: inviteDetails?.lastName,
      specialityCode: loginResultObject.user?.specialityCode,
      mobile: inviteDetails.mobile,
      modifiedBy: loginResultObject.user.userId,
      modifiedDate: CommonFunctions.convertUTCDate(
        new Date(),
        "YYYY-MM-DD HH:mm:ss"
      ),
      patientId: inviteDetails?.patientId,
      paymentConsultation: payload.paymentConsultation,
      paymentType: payload.paymentType,
      appointmentDate: CommonFunctions.convertDate(payload.appointmentDate),
      startTime: CommonFunctions.ConvertTime(payload.startTime),
      endTime: CommonFunctions.ConvertTime(payload.endTime),
      serviceName:
        payload.serviceCode === "APPT_VIDEO"
          ? "Video Consultation"
          : payload.serviceCode === "APPT_INPERSON"
          ? "Inperson Consultation"
          : "",
      serviceCode: payload.serviceCode,
      consultationFee: payload.consultationFee,
      totalAmount: payload.consultationFee,
      userId: loginResultObject.user.userId,
      patientType: "E",
      patientRegFeeYN: "N",
    };

    const currentTime = CommonFunctions.ConvertTime(dayjs());
    const startTime = CommonFunctions.ConvertTime(payload.startTime);

    if (
      startTime < currentTime &&
      dayjs().format("MM/DD/YYYY") ==
        CommonFunctions.convertDate(payload.appointmentDate)
    ) {
      message.error("Scheduled time has already ended");
      setSave(false);
      return;
    }

    if (submitType === "1") {
      setSave(true);
      data.status = 0;
      data.paymentReceived = "N";
      CommonFunctions.ExisTingPatientPayment(data)
        .catch((error) => {
          console.error("An error occurred:", error);
          message.error("An Error occurred, try again");
        })
        .finally(() => {
          setSave(false);
        });
    } else if (submitType === "2") {
      if (payload.paymentType == "Cash" && !paymentReceived) {
        message.error("Confirm Payment received by clicking the check box");
        setSave(false);
        return;
      }

      setSave(true);
      try {
        data.paymentReceived = paymentReceived ? "Y" : "N";
        data.status = 1;
        const result = await enrollmentServices.Invite(data);
        if (result.data.code === "FAILED") {
          message.error("Sending Invitation Failed, Retry");
          setSave(false);
          return;
        }
        if (result.data.code === "SUCCESS") {
          message.success("Invite sent Successfully");
          form.resetFields();
          handleInviteOk();
        }
      } catch (error) {
        console.error("An error occurred:", error);
        message.error("An Error occured please try again");
      } finally {
        setSave(false);
      }
    } else if (submitType == "3") {
      setSave(true);
      try {
        if (data.paymentType == "Digital" && data.paymentConsultation == "AC") {
          message.error(
            "Share payment link is not available for After Consultation"
          );
          return;
        }
        data.status = 0;
        data.paymentType = "Share_Link";
        const result = await enrollmentServices.Invite(data);
        if (result.data.code === "FAILED") {
          message.error("Sending Invitation Failed, Retry");
          setSave(false);
          return;
        }
        if (result.data.code === "SUCCESS") {
          message.success("Payment Link and invitation sent Successfully");
          form.resetFields();
          handleInviteOk();
        }
      } catch (error) {
        console.error("An error occurred:", error);
        message.error("An Error occured please try again");
      } finally {
        setSave(false);
      }
    }
  };

  const onPaymentTypeChange = (value) => {
    setPaymentType(value);

    if (value === "Cash") {
      //changed
      setPaymentReceived(true);
      form.setFieldsValue({ paymentConsultation: "BC" });
    } else if (value === "Digital") {
      form.setFieldsValue({ paymentConsultation: "BC" });
      setPaymentReceived(false);
    } else {
      setPaymentReceived(false);
      form.setFieldsValue({ paymentConsultation: null });
    }
  };

  return (
    <>
      {save && (
        <div class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="spinner"></div>
        </div>
      )}
      <div className="flex justify-between">
        <div className="flex gap-1">
          <div className="font-bold text-blue-600">{"Patient Name : "}</div>
          <div>{inviteDetails?.firstName + " " + inviteDetails?.lastName}</div>
        </div>
        <div className="flex gap-1">
          <div className="font-bold text-blue-600">{"Patient ID : "}</div>
          <div>{inviteDetails?.patientId}</div>
        </div>
      </div>
      <div className="flex mt-4 justify-between">
        <div className="flex gap-1">
          <div className="font-bold text-blue-600">{"Email : "}</div>
          <div>{inviteDetails?.email}</div>
        </div>
        <div className="flex gap-1">
          <div className="font-bold text-blue-600">{"Mobile : "}</div>
          <div>{inviteDetails?.mobile}</div>
        </div>
      </div>
      <Form
        className="formDesign mt-4 mb-10"
        name="trigger"
        layout="vertical"
        form={form}
        onFinish={handleSubmit}
        initialValues={{
          serviceCode: "APPT_VIDEO",
          paymentType: "Cash",
          paymentConsultation: "BC",
          consultationFee: "500",
          appointmentDate: dayjs(),
          startTime: dayjs().add(3, "minutes"),
          endTime: dayjs().add(18, "minutes"),
        }}
      >
        <div className="rounded-lg bg-white p-3 mt-5 shadow-lg">
          <Row gutter={24}>
            <Col span={12}>
              <div className="relative mt-2">
                <span className="absolute -top-1 z-10 left-3 bg-white px-1  text-xs">
                  Appointment Date <span className="text-red-500">*</span>
                </span>
                <Form.Item
                  name="appointmentDate"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter appointmentDate!",
                    },
                  ]}
                >
                  <DatePicker
                    format={"DD/MM/YYYY"}
                    defaultValue={dayjs()}
                    className="w-full mt-1 rounded-lg px-4 py-2"
                    disabledDate={disabledDate}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col span={12}>
              <div className="relative mt-3">
                <span className="absolute -top-2 z-10 left-3 bg-white px-1  text-xs">
                  Service Type <span className="text-red-500">*</span>
                </span>

                <Form.Item
                  name="serviceCode"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Consultation Type!",
                    },
                  ]}
                >
                  <Select
                    defaultValue={"APPT_VIDEO"}
                    showSearch={false}
                    className="w-full"
                    placeholder="Search to Consltations"
                    onChange={handleCallTypeChange}
                  >
                    <Select.Option value="APPT_INPERSON">
                      In-person
                    </Select.Option>
                    <Select.Option value="APPT_VIDEO">Video</Select.Option>
                  </Select>
                </Form.Item>
              </div>
            </Col>
            <Col span={12}>
              <div className="relative mt-2">
                <span className="absolute -top-1 z-10 left-3 bg-white px-1  text-xs">
                  Start Time <span className="text-red-500">*</span>
                </span>
                <Form.Item
                  name="startTime"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Start Time!",
                    },
                  ]}
                >
                  <TimePicker
                    use12Hours
                    format="h:mm a"
                    className="w-full mt-1 rounded-lg px-4 py-2"
                    onChange={handleStartTimeChange}
                    minuteStep={10}
                    needConfirm={false}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col span={12}>
              <div className="relative mt-2">
                <span className="absolute -top-1 z-10 left-3 bg-white px-1  text-xs">
                  End Time <span className="text-red-500">*</span>
                </span>
                <Form.Item
                  name="endTime"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter End Time!",
                    },
                  ]}
                >
                  <TimePicker
                    id="endTime"
                    use12Hours
                    format="h:mm a"
                    className="w-full mt-1 rounded-lg px-4 py-2"
                    value={endTime}
                    minuteStep={10}
                    needConfirm={false}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col className={`${true ? "block" : "hidden"}`} span={12}>
              <div className="relative mt-2">
                <span className="absolute -top-2 z-10 left-2 bg-white px-1 ">
                  Reason for Consultation
                </span>
                <Form.Item name="consultationReason">
                  <Input placeholder="Enter Consultation Reason" />
                </Form.Item>
              </div>
            </Col>
            <Col className={`${true ? "block" : "hidden"}`} span={12}>
              <div className="relative mt-2">
                <span className="absolute -top-2 z-10 left-2 bg-white px-1 ">
                  Enter Fees
                </span>
                <Form.Item
                  name="consultationFee"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Total Amount!",
                    },
                  ]}
                >
                  <Input placeholder="Enter Amount" />
                </Form.Item>
              </div>
            </Col>
          </Row>
        </div>
        <div className="bg-white rounded-lg  p-3 mt-5 shadow-lg">
          <Row gutter={24}>
            <Col span={12}>
              <div className="relative mt-2">
                <span className="absolute -top-2 z-10 left-2 px-1 ">
                  Payment
                </span>
                <Form.Item
                  name="paymentConsultation"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Payment Consultation!",
                    },
                  ]}
                >
                  <Radio.Group
                    className="mt-5"
                    disabled={
                      paymentType === "Cash" || paymentType === "Digital"
                        ? true
                        : false
                    }
                  >
                    <Radio value="BC" className="text-black">
                      Before
                    </Radio>
                    <Radio value="AC" className="text-black">
                      After
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
            </Col>
            <Col span={12}>
              <div className="relative mt-3">
                <span className="absolute -top-2 z-10 left-3 bg-white px-1  text-xs">
                  Payment Type <span className="text-red-500">*</span>
                </span>

                <Form.Item
                  name="paymentType"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Payment Type!",
                    },
                  ]}
                >
                  <Select
                    defaultValue={"Cash"}
                    showSearch
                    className="w-full text-xs"
                    placeholder="Search to Payment mode"
                    onChange={onPaymentTypeChange}
                  >
                    <Select.Option value="Cash">Cash</Select.Option>
                    <Select.Option value="Digital">
                      Digital Payment
                    </Select.Option>
                  </Select>
                </Form.Item>
              </div>
            </Col>

            {paymentType == "Cash" && (
              <>
                <Col>
                  <Form.Item name="">
                    <div>
                      <Checkbox
                        checked={paymentReceived}
                        className="mr-2"
                        onChange={onPaymentStatusChange}
                      />
                      Payment Received
                    </div>
                  </Form.Item>
                </Col>
                <Col span={12}></Col>
              </>
            )}
          </Row>
        </div>
        <Form.Item>
          <div className="flex gap-2 justify-end mt-5">
            <button
              className="border rounded-lg bg-white text-sm w-20"
              onClick={handleCancel}
            >
              Cancel
            </button>
            {paymentType == "Digital" ? (
              <>
                <button
                  className="bg-blue-600 border rounded-lg  w-40 p-2 text-sm text-white"
                  type="primary"
                  disabled={save}
                  htmlType="submit"
                  onClick={() => handleSubmitType("3")}
                >
                  Share Payment Link
                </button>
                <button
                  className="border rounded-lg bg-blue-600 w-28 text-white"
                  type="primary"
                  disabled={save}
                  htmlType="submit"
                  onClick={() => handleSubmitType("1")}
                >
                  {callType === "APPT_VIDEO"
                    ? "Pay & Invite"
                    : "Pay & Schedule"}
                </button>
                <button
                  className="border rounded-lg text-white p-2 text-sm"
                  type="primary"
                  htmlType="submit"
                  disabled={save}
                  onClick={() => handleSubmitType("2")}
                >
                  {callType === "APPT_VIDEO" ? "Send Invite" : "Schedule"}
                </button>
              </>
            ) : (
              <>
                <button
                  className="border rounded-lg text-white p-2 text-sm"
                  type="primary"
                  htmlType="submit"
                  disabled={save}
                  onClick={() => handleSubmitType("2")}
                >
                  {callType === "APPT_VIDEO" ? "Send Invite" : "Schedule"}
                </button>
              </>
            )}
          </div>
        </Form.Item>
      </Form>
    </>
  );
}

export default PatientInvite;
