import React, { useRef, useState } from "react";
import { Form, Input, Button, message } from "antd";
import AuthLayout from ".";
import Arrowicon from "../../Assets/Images/Svg/Arrow.svg";
import { useHistory } from "react-router-dom";
import { Base64 } from "js-base64";
import AuthService from "../../Services/AuthService";
import { APP_ROUTES } from "../../PageRouting/approutes";
import { UserSwitchOutlined } from "@ant-design/icons";
import { generateAesKey } from "../../utils/passwordEncrypt";

function MobileLogin() {
  const [form] = Form.useForm();
  const history = useHistory();
  const [userName, setUserName] = useState("");
  const [disable, setDisable] = useState(false);
  const [page, setPage] = useState("1");
  const inputRefs = useRef([]);
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [timer, setTimer] = useState(0);
  const verifyformRef = useRef();

  const handleGetOTP = async () => {
    setDisable(true);

    let payload1 = {
      userId: userName,
    };

    console.log("payload", payload1);

    if (!payload1.userId) {
      message.error("please enter valid credentials to login");
      setDisable(false);
      return;
    }

    let tokenPayload = {
      userId: `U${userName}`,
      password: "NA",
    };

    try {
      let tokenResult = await AuthService.GetTokenForOTP(tokenPayload);
      localStorage.setItem("access_token", tokenResult.data.access_token);

      let result = await AuthService.GenerateOTP(payload1);
      console.log("result", result);
      setPage("2");
      startResendTimer();
    } catch (error) {
      message.error(error.response.data.message);
      setDisable(false);
    }
  };

  const startResendTimer = () => {
    setIsResendDisabled(true);
    setTimer(120);

    const interval = setInterval(() => {
      setTimer((prev) => {
        if (prev <= 1) {
          clearInterval(interval);
          setIsResendDisabled(false);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  const handleInputChange = (e, index) => {
    const value = e.target.value;
    if (/^[0-9]$/.test(value) && index < 5) {
      inputRefs.current[index + 1].focus();
    } else if (!e.target.value && index >= 1) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleVerifyOTP = async (e) => {
    e.preventDefault();
    const { one, two, three, four, five, six } =
      verifyformRef.current.getFieldsValue();
    console.log(" otp ", verifyformRef.current.getFieldsValue());
    if ((one || two || three || four || five || six) === undefined) {
      console.log("hello enter all number");
      message.error("Enter valid OTP to Login");
      setDisable(false);
      return;
    }
    let finalNum = one + two + three + four + five + six;

    const encryptPassword = generateAesKey(finalNum);
    console.log(" enc ", encryptPassword);
    let verifyPayload = {
      numVal: encryptPassword,
      userId: userName,
    };

    console.log("verifyPayload", verifyPayload);

    try {
      const response = await AuthService.VerifyOTP(verifyPayload);
      if (!response.data.message) {
        if (
          response.data.user.rolecode !== "SUPERADMIN" &&
          response.data.user.userType == "P"
        ) {
          history.push(APP_ROUTES.DASHBOARD);
          message.success(
            `Welcome ${response.data.user.firstName} ${response.data.user.lastName}`
          );
          localStorage.setItem("login_result", JSON.stringify(response.data));
        } else {
          message.error("Invalid Credentials, verify and try again");
          setDisable(false);
        }
      } else {
        message.error(response.data.message);
        setDisable(false);
      }
    } catch (e) {
      message.error(e.response.data.message);
      return;
    }
  };

  const handleResendOTP = async () => {
    setDisable(true);

    let payload1 = {
      userId: userName,
    };

    try {
      let result = await AuthService.GenerateOTP(payload1);
      console.log("result", result);
      startResendTimer();
    } catch (error) {
      message.error(error.response.data.message);
      setDisable(false);
    }
  };

  return (
    <AuthLayout>
      <div className="w-[80%]">
        <div className="w-full justify-start flex mt-6">
          {page == "1" ? (
            <div className="w-full">
              <Form
                name="basic"
                layout="vertical"
                autoComplete="off"
                form={form}
                ref={verifyformRef}
                onFinish={handleGetOTP}
              >
                <Form.Item name="userId">
                  <Input
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                    placeholder="Enter Registered Mobile Number"
                    className="h-10 w-full px-2  border-[1px] border-gray-400 appearance-none bg-none dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    style={{ background: "none" }}
                    suffix={
                      <UserSwitchOutlined className="h-5 w-5 text-gray-500" />
                    }
                  />
                </Form.Item>
                <div className="flex justify-end items-center mt-2 mb-2">
                  <p
                    onClick={() => history.push(APP_ROUTES.LOGINFORM)}
                    className=" text-brand-primaryColor mt-5 text-sm cursor-pointer mb-0"
                  >
                    Login with Email
                  </p>
                </div>
                <Form.Item>
                  <button
                    htmlType="submit"
                    type="primary"
                    className="w-full font-semibold text-white rounded-md inline-block mt-2 mb-5 h-10 bg-blue-500"
                    disabled={disable}
                  >
                    Get OTP
                  </button>
                </Form.Item>
              </Form>
            </div>
          ) : (
            <div className="my-8">
              <div className="my-2">
                <label className="md:text-lg font-medium">Enter OTP </label>
                <span className="text-red-500">*</span>
              </div>

              <Form ref={verifyformRef}>
                <div className="w-full flex gap-2 items-center justify-evenly">
                  {[...Array(6)].map((_, index) => (
                    <Form.Item
                      key={index}
                      name={
                        ["one", "two", "three", "four", "five", "six"][index]
                      }
                      className="w-1/5"
                      rules={[
                        {
                          pattern: /^[0-9]{1}$/,
                        },
                      ]}
                    >
                      <Input
                        maxLength={1}
                        ref={(el) => (inputRefs.current[index] = el)}
                        onChange={(e) => handleInputChange(e, index)}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        className="outline-none border rounded-lg border-borderColor p-3 text-center font-medium text-xl"
                      />
                    </Form.Item>
                  ))}
                </div>
                <div className="flex gap-2">
                  <button
                    htmlType="submit"
                    type="primary"
                    className={`w-full font-semibold text-white rounded-md inline-block mt-2 mb-5 h-10 ${
                      isResendDisabled ? "bg-gray-400" : "bg-blue-500"
                    }`}
                    onClick={handleResendOTP}
                    disabled={isResendDisabled}
                  >
                    Resend OTP {isResendDisabled && `(${timer})`}
                  </button>
                  <button
                    htmlType="submit"
                    type="primary"
                    className="w-full font-semibold text-white rounded-md inline-block mt-2 mb-5 h-10 bg-blue-500"
                    onClick={handleVerifyOTP}
                  >
                    Submit
                  </button>
                </div>
              </Form>
            </div>
          )}
        </div>
      </div>
    </AuthLayout>
  );
}

export default MobileLogin;
