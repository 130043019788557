import React, { useState } from "react";
import { Button, Modal, Space, Table, Tooltip } from "antd";
import PaidIcon from "../../../Assets/Images/Svg/paid.svg";
import { APP_ROUTES } from "../../../PageRouting/approutes";
import PatientService from "../../../Services/PatientService";
import { useHistory, useLocation } from "react-router-dom";
import Edit from "../../../Assets/Images/Svg/EditIcon.svg";
import Preview from "../../../Assets/Images/Svg/PreviewIcon.svg";
import moment from "moment";
import PrescriptionDetails from "../ConsultationNotes/VitalTabs/ViewPrescription/PrescriptionDetails";
import ConsultationService from "../../../Services/ConsultationService";
import shareIcon from "../../../Assets/Images/Svg/shareIcon.svg";
import videoicon from "../../../Assets/Images/videoicon.png";
import { IoPersonSharp } from "react-icons/io5";
import share from "../../../Assets/Images/share.png";
import { Base64 } from "js-base64";
import close from "../../../Assets/Images/close.png";
import { RWebShare } from "react-web-share";
import AppointmentService from "../../../Services/AppointmentService";
import CommonFunctions from "../../Shared/CommonFunctions";
import SharePrescription from "../UpcomingAppointments/SharePrescription";
import ShareOrders from "../UpcomingAppointments/ShareOrders";
import MedicineDisabled from "../../../Assets/Images/Svg/MedicineDisabled.svg";
import LabDisabled from "../../../Assets/Images/Svg/LabDisabled.svg";
import MedOrderPreview from "./MedOrderPreview";
import LabOrderPreview from "./LabOrderPreview";
import ViewLabReports from "./ViewLabReports";

function TodaysAppointment(props) {
  const [selectedPrescription, setSelectedPrescription] = useState(null);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [preview, setPreview] = useState(false);
  const [cancelAppointmentID, setCancelAppointmentID] = useState({});
  const [shareModal, setShareModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [previewType, setPreviewType] = useState("M");

  const encodeBase64 = (str) => {
    let id = Base64.encode(str);
    return id;
  };

  const showModal = (record) => {
    setIsModalOpen(true);
    setCancelAppointmentID(record);
  };

  const handleOk = () => {
    AppointmentService.AppointmentCancel(
      cancelAppointmentID.appointmentId
    ).then((res) => {
      setIsModalOpen(false);
      props.getAppointmentList();
    });
    setCancelAppointmentID({});
  };

  const cancelAppointment = () => {
    setIsModalOpen(false);
  };

  const download = () => {
    setPreview(false);
  };
  const cancel = () => {
    setPreview(false);
  };

  const handlePrescriptionPreview = async (record) => {
    let patientPayload = {
      patientId: record?.patientId,
    };
    let consultationPayload = {
      consultationId: record?.consultationId,
      detailsYN: "Y",
    };
    try {
      const patientResponse = await PatientService.PatientListFilter(
        patientPayload
      );
      if (patientResponse) {
        setSelectedPatient(patientResponse.data[0]);
      }
      const consultationResponse = await ConsultationService.getPrescriptions(
        consultationPayload
      );
      if (consultationResponse.data) {
        setSelectedPrescription(consultationResponse.data[0]);
        setPreview(true);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const [showLabReport, setShowLabReport] = useState(false);
  const [labReportData, setLabReportData] = useState({});

  const currentDate = new Date();

  const columns = [
    {
      title: "Patient Name",
      dataIndex: "firstName",
      render: (_, record) => (
        <Space size="middle" className="">
          <div className="flex items-center gap-4">
            <div>
              <div className="text-base font-medium">{record.patientName}</div>
              <p className="text-brand-textDarkgray text-sm mb-0">
                <span>{record.patientId}</span>
              </p>
            </div>
          </div>
        </Space>
      ),
      sorter: {
        compare: (a, b) => a.patientName.localeCompare(b.patientName),
      },
      width: 200,
    },
    {
      title: "Appt.ID",
      dataIndex: "appointmentId",
      width: 150,
    },
    {
      title: "Chief Complaints",
      dataIndex: "appointmentReason",
      width: 150,
    },

    {
      title: "Date & Time",
      width: 200,
      dataIndex: "startTime",
      render: (_, record) => {
        const [hours, minutes] = record.startTime.split(":");
        const date = new Date();
        date.setHours(parseInt(hours, 10));
        date.setMinutes(parseInt(minutes, 10));
        const formattedTime = date.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        });

        const formattedDate = moment
          .utc(record.appointmentDate)
          .local()
          .format("DD/MM/YYYY");
        return (
          <span>
            {formattedDate}-{formattedTime}
          </span>
        );
      },
      sorter: {
        compare: (a, b) => a.startTime.localeCompare(b.startTime),
      },
    },

    {
      title: "Payment Status",
      dataIndex: "transactionId",
      width: 150,
      render: (_, record) => {
        return record.transactionId ? (
          <span className="text-green-500 font-semibold">Completed</span>
        ) : (
          <span className="text-blue-500 font-semibold">Pending</span>
        );
      },
    },

    {
      title: "Call & Type",
      width: 150,
      dataIndex: "actions",
      render: (_, record) => (
        <>
          {record.status === 2 || record.status === 0 ? (
            <button
              className="flex items-center justify-center gap-2 w-full px-1 py-2 border bg-gray-400 border-black rounded-lg  text-black cursor-not-allowed"
              disabled
            >
              {record.serviceCode === "APPT_VIDEO" ? (
                <>
                  <img src={videoicon} alt="videoicon" className="w-5 h-5" />
                  Join call
                </>
              ) : (
                <>
                  <IoPersonSharp className="text-green-500" /> Consult
                </>
              )}
            </button>
          ) : !record.transactionId && record.paymentConsultation === "BC" ? (
            <Tooltip
              placement="top"
              title={"payment is not completed on the patient side"}
            >
              <button
                className="flex items-center justify-center gap-2 w-full px-1 py-2 border border-black rounded-lg bg-gray-400 text-black hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                disabled
              >
                {record.serviceCode === "APPT_VIDEO" ? (
                  <>
                    <img src={videoicon} alt="videoicon" className="w-5 h-5" />
                    Join call
                  </>
                ) : (
                  <>
                    <IoPersonSharp className="text-green-500" /> Consult
                  </>
                )}
              </button>
            </Tooltip>
          ) : (
            <button
              onClick={() => handleClick(record, "1")}
              className="flex items-center justify-center gap-2 w-full px-1 py-2 border border-black rounded-lg bg-white text-black hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              {record.serviceCode === "APPT_VIDEO" ? (
                <>
                  <img src={videoicon} alt="videoicon" className="w-5 h-5" />
                  Join call
                </>
              ) : (
                <>
                  <IoPersonSharp className="text-green-500" /> Consult
                </>
              )}
            </button>
          )}
        </>
      ),
    },
    {
      title: "Rx",
      width: 100,
      dataIndex: "actions",
      render: (_, record) => {
        return (
          <Space size="middle" className="">
            <div className="flex items-center gap-2">
              <div size="middle" className="">
                {record.status === 2 ? (
                  <div className="flex gap-1 items-center cursor-pointer">
                    <img
                      onClick={() => handleClick(record, "2")}
                      src={Edit}
                      alt="Edit"
                      className="w-6 h-6"
                    />
                    <img
                      src={Preview}
                      alt="Preview"
                      className="w-6 h-6"
                      role="button"
                      onClick={() => handlePrescriptionPreview(record)}
                    />

                    <SharePrescription
                      record={record}
                      className={"w-6 h-6"}
                      paypendingClass={
                        "w-6 h-6 bg-gray-400 rounded-md cursor-not-allowed"
                      }
                    />
                  </div>
                ) : (
                  <div className="flex gap-1 items-center cursor-pointer">
                    <img
                      src={Edit}
                      alt="Edit"
                      className="w-6 h-6 bg-gray-400 rounded-md cursor-not-allowed"
                    />
                    <img
                      src={Preview}
                      alt="Preview"
                      className="w-6 h-6 bg-gray-400 rounded-md cursor-not-allowed"
                      role="button"
                    />
                    <img
                      src={shareIcon}
                      alt="Share"
                      className="w-6 h-6 bg-gray-400 rounded-md cursor-not-allowed"
                      role="button"
                    />
                  </div>
                )}
              </div>
            </div>
          </Space>
        );
      },
    },
    {
      title: "Orders",
      width: 100,
      dataIndex: "actions",
      render: (_, record) => {
        return (
          <Space size="middle" className="">
            <div className="flex items-center gap-2">
              <div size="middle" className="">
                {record.status === 2 ? (
                  <div className="flex gap-1 items-center cursor-pointer">
                    <ShareOrders record={record} />
                  </div>
                ) : (
                  <div className="flex gap-1 items-center cursor-pointer">
                    <img
                      src={MedicineDisabled}
                      alt="Preview"
                      className="w-8 h-8 cursor-not-allowed"
                      role="button"
                    />

                    <img
                      src={LabDisabled}
                      alt="Share"
                      className="w-8 h-8 cursor-not-allowed"
                      role="button"
                    />
                  </div>
                )}
              </div>
            </div>
          </Space>
        );
      },
    },
    {
      title: "Med. Order ID",
      dataIndex: "drugAmount",
      width: 150,
      render: (_, record) => {
        return record.drugAmount != 0 ? (
          <div className="flex justify-center items-center">
            <span
              className=" border p-1 rounded-md border-blue-500 cursor-pointer"
              onClick={() => handlePharmaMedOpen(record, "M")}
            >
              {record.dispensedId}
            </span>
          </div>
        ) : (
          <div className="flex justify-center items-center">
            <span className="text-blue-500 font-semibold">
              {record.drugPrescriptionStatus
                ? record.drugPrescriptionStatus
                : "N/A"}
            </span>
          </div>
        );
      },
    },
    {
      title: "Lab Order ID",
      dataIndex: "labAmount",
      width: 150,
      render: (_, record) => {
        return record.labAmount != 0 ? (
          <div className="flex justify-center gap-1 items-center">
            <span
              className=" border p-1 rounded-md border-blue-500 cursor-pointer"
              onClick={() => handlePharmaMedOpen(record, "L")}
            >
              {record.labTestOrderId}
            </span>
            <img
              src={Preview}
              alt="Preview"
              className="w-6 h-6 rounded-md cursor-pointer"
              role="button"
              onClick={() => {
                setShowLabReport(true);
                setLabReportData(record);
              }}
            />
          </div>
        ) : (
          <div className="flex justify-center items-center">
            <span className="text-blue-500 font-semibold">
              {record.labPrescriptionStatus
                ? record.labPrescriptionStatus
                : "N/A"}
            </span>
          </div>
        );
      },
    },
    {
      title: "Status",
      width: 120,
      dataIndex: "status",
      render: (_, record) => (
        <div size="middle" className="">
          {record.status === 0 && (
            <span className=" text-red-500 " disabled>
              Cancelled
            </span>
          )}
          {record.status === 1 && (
            <span className=" text-blue-500 ">Pending</span>
          )}
          {record.status === 2 && (
            <span className=" text-green-500 ">Completed</span>
          )}
        </div>
      ),
    },
    {
      title: "Action",
      width: 100,
      dataIndex: "action",
      render: (_, record) => (
        <Space size="middle" className="">
          {record.status === 1 && (
            <div className="flex justify-center items-center gap-2">
              <Tooltip placement="left" title={"Share"}>
                <div className="border  border-gray-500 p-1 cursor-pointer rounded-lg">
                  <div className="flex items-center gap-2">
                    <RWebShare
                      data={{
                        text: "Appointment",
                        url:
                          `${process.env.REACT_APP_PATIENT_BASEURL}vc?ref=` +
                          record?.appointmentId +
                          "/N",
                        title: "Share Link",
                      }}
                      onClick={() => console.log("shared successfully!")}
                    >
                      <img className="w-4" src={share} alt="shareImage" />
                    </RWebShare>
                  </div>
                </div>
              </Tooltip>
              <Tooltip placement="right" title={"Cancel"}>
                <div
                  onClick={() => showModal(record)}
                  className="border  border-gray-500 p-1 cursor-pointer rounded-lg"
                >
                  <img className="w-4" src={close} alt="closeImage" />
                </div>
              </Tooltip>
            </div>
          )}
          {record.status === 2 && (
            <div className="flex justify-center items-center gap-2">
              <div className="border  border-gray-500 bg-gray-500 cursor-not-allowed p-1  rounded-lg">
                <div className="flex items-center gap-2">
                  <img className="w-4" src={share} alt="shareImage" />
                </div>
              </div>
              <div className="border  border-gray-500 bg-gray-500 p-1 cursor-not-allowed rounded-lg">
                <img className="w-4" src={close} alt="closeImage" />
              </div>
            </div>
          )}
          {record.status === 0 && (
            <div className="flex justify-center items-center gap-2">
              <div className="border  border-gray-500 bg-gray-500 cursor-not-allowed p-1  rounded-lg">
                <div className="flex items-center gap-2">
                  <img className="w-4" src={share} alt="shareImage" />
                </div>
              </div>
              <div className="border  border-gray-500 bg-gray-500 p-1 cursor-not-allowed rounded-lg">
                <img className="w-4" src={close} alt="closeImage" />
              </div>
            </div>
          )}
        </Space>
      ),
    },
  ];

  const history = useHistory();

  const handleClick = (record, tab) => {
    if (tab === "1") {
      let payload = {
        appointmentId: record.appointmentId,
        modifiedBy: record.userId,
        modifiedDate: CommonFunctions.convertUTCDate(
          new Date(),
          "YYYY-MM-DD HH:mm:ss"
        ),
        patientId: record.patientId,
        startDateTime: CommonFunctions.convertUTCDate(
          new Date(),
          "YYYY-MM-DD HH:mm:ss"
        ),
        userId: record.userId,
      };

      ConsultationService.updateStartTime(payload).then((res) => {
        console.log("");
      });
    }
    record.callMode = tab == "1" ? "JoinCall" : "EditCall";
    let payload = {
      patientId: record.patientId,
    };

    PatientService.PatientListFilter(payload).then((res) => {
      localStorage.setItem("patient", JSON.stringify(res.data[0]));
    });
    history.push(APP_ROUTES.VIDEOCALL, { selectedPatient: record });
  };

  const [pharmaMed, setPharmaMed] = useState(false);
  const [pharmaLab, setPharmaLab] = useState(false);

  const handlePharmaMedOpen = (record, type) => {
    setPreviewType(type);
    console.log(" from med open", record, type);
    setMedRecord(record);
    setPharmaMed(true);
  };

  const handlePharmaMedClose = () => {
    setPharmaMed(false);
  };

  const handlePharmaLabView = () => {
    setPharmaLab();
  };

  const [medRecord, setMedRecord] = useState({});

  return (
    <div className="">
      <Table
        className="customTable"
        columns={columns}
        dataSource={props.appointmentList}
        pagination={{
          pageSize: 5,
        }}
        scroll={{ x: "max-content" }}
      />

      {showLabReport && (
        <Modal
          title="Lab Reports"
          open={showLabReport}
          onCancel={() => {
            setShowLabReport(false);
          }}
          style={{
            top: 40,
          }}
          width={800}
          footer={null}
        >
          <ViewLabReports labReportData={labReportData} />
        </Modal>
      )}

      {pharmaMed && (
        <Modal
          title=""
          open={pharmaMed}
          onCancel={handlePharmaMedClose}
          style={{
            top: 40,
          }}
          width={1000}
          footer={null}
        >
          {previewType === "M" ? (
            <MedOrderPreview medRecord={medRecord} />
          ) : (
            <LabOrderPreview medRecord={medRecord} />
          )}
        </Modal>
      )}
      <Modal
        title="Prescription"
        open={preview}
        onOk={download}
        onCancel={cancel}
        style={{
          top: 40,
        }}
        width={1200}
        footer={null}
      >
        <PrescriptionDetails
          prescription={selectedPrescription}
          selectedPatient={selectedPatient}
        />
      </Modal>
      <Modal
        title="Cancel Appointment"
        open={isModalOpen}
        // onOk={handleOk}
        onCancel={cancelAppointment}
        footer={[]}
      >
        Do you want to cancel this appointment
        <div className="flex justify-end border-brand-colorBorder gap-3 mt-10">
          <Button className="h-10 Btnwhite px-8" onClick={cancelAppointment}>
            Cancel
          </Button>
          <Button onClick={handleOk} className="h-10 px-10" type="primary">
            Ok
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default TodaysAppointment;
