import http from "./http-common";
import _ from "lodash";

class DrugServices {

  constructor() {
    this.debouncedDrugList = _.debounce((medicineName) => {
     return this.fetchDrugList(medicineName);
    }, 150);
  }

  DrugList(medicineName) {
    return this.debouncedDrugList(medicineName);
  }

  fetchDrugList(medicineName) {
    return http.get(`/drugInfo/list/filter?medicineName=${medicineName}&pageNo=${1}`);
  }
  
}

export default new DrugServices();
