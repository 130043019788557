import React, { useEffect, useState } from "react";
import logo from "../../../Assets/Images/sreethaila.jpeg";
import BillingService from "../../../Services/BillingService";
import { Spin } from "antd";

const BillingPrint = ({ billingRecord, loginResultObject }) => {
  const [loading, setLoading] = useState(true);
  const [billData, setBillData] = useState(null);

  useEffect(() => {
    getBilling(billingRecord);
  }, [billingRecord]);

  const getBilling = (record) => {
    let payload = {
      //   userId: record.userId,
      //   account_Id: record.account_Id,
      //   clinic_Id: record.clinic_id,
      //   PatientId: record.patientId,
      transactionId: record.transactionId,
      srviceCode: record.serviceCode,
      status: 1,
    };

    BillingService.BillingRecordsListInvoice(payload)
      .then((res) => {
        // console.log("rec", res.data[0]);
        setBillData(res.data[0]);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching billing records:", error);
      });
  };

  const calculateage = (date) => {
    const dobYear = date.split("/")[2];
    const currYear = new Date().getFullYear();
    const patientAge = currYear - Number(dobYear);
    return patientAge;
  };

  function numberToWords(num) {
    const belowTwenty = [
      "Zero",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
      "Ten",
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ];

    const tens = [
      "",
      "",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];

    if (num < 20) return belowTwenty[num];
    if (num < 100)
      return (
        tens[Math.floor(num / 10)] +
        (num % 10 ? " " + belowTwenty[num % 10] : "")
      );
    if (num < 1000)
      return (
        belowTwenty[Math.floor(num / 100)] +
        " Hundred" +
        (num % 100 ? " " + numberToWords(num % 100) : "")
      );

    if (num < 1000000) {
      return (
        numberToWords(Math.floor(num / 1000)) +
        " Thousand" +
        (num % 1000 ? " " + numberToWords(num % 1000) : "")
      );
    }

    if (num < 1000000000) {
      return (
        numberToWords(Math.floor(num / 1000000)) +
        " Million" +
        (num % 1000000 ? " " + numberToWords(num % 1000000) : "")
      );
    }

    return "Number too large";
  }

  return (
    <>
      {loading ? (
        <div className="w-full h-full flex justify-center items-center">
          <Spin />
        </div>
      ) : (
        <div className="p-3">
          <div className="mx-auto bg-white p-8 border border-gray-300 rounded-lg bg-opacity-90">
            <div className="flex justify-between">
              <div className="flex">
                <img
                  src={process.env.REACT_APP_IMAGE_URL + billData?.logoName}
                  alt="Clinic Logo"
                  className="md:w-24 w-12 md:h-24 h-12 mx-4"
                />
                <div className="text-center mt-3">
                  <label className="md:text-4xl text-sm font-serif font-medium text-[#154A6B]">
                    {billData?.clinic_Name}
                  </label>
                </div>
              </div>

              <div className="">
                <h1 className="text-xl font-bold">{billData?.clinic_Name}</h1>
                <div>
                  {billData?.clinicAddress}, {billData?.area_Name}
                </div>
                <div>Email: {billData?.email}</div>
                <div>Phone: {billData?.contactNo}</div>
              </div>
            </div>
            <div className="p-1 grid grid-cols-3 gap-4 mt-6 text-sm border-t-2 border-b-2 items-center">
              <div>
                <p>
                  OP No: <strong></strong>
                </p>
                <p>
                  Consultant: <strong>{billData?.userName}</strong>
                </p>
                <p>
                  Date: <strong>{billData?.paymentDate}</strong>
                </p>
              </div>
              <div className="text-center col-span-1 mb-24 p-2">
                <strong>Bill Cum Receipt</strong>
              </div>
              <div className="">
                <p>
                  Token No: <strong></strong>
                </p>
                <p>
                  Bill No: <strong>{billData?.transactionId}</strong>
                </p>
              </div>
            </div>
            <div className="mt-4">
              <table className="w-full border-collapse border border-gray-300 text-sm">
                <thead>
                  <tr className="bg-gray-200 text-center">
                    <th className="p-2 ">Name</th>
                    <th className="p-2 ">ID</th>
                    <th className="p-2 ">Age</th>
                    <th className="p-2 ">Gender</th>
                    <th className="p-2 ">Mobile</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="text-center">
                    <td className="p-2">{billData?.patientName}</td>
                    <td className="p-2">{billData?.patientId}</td>
                    <td className="p-2">
                      {billData?.dob ? calculateage(billData?.dob) : "N/A"}
                    </td>
                    <td className="p-2 ">
                      {billData?.gender === "M" ? "Male" : "Female"}
                    </td>
                    <td className="p-2">{billData?.mobile}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="mt-6 text-sm">
              <p>
                Payment Type: <strong>{billData?.paymentType}</strong>
              </p>
            </div>

            <div className="mt-4">
              <table className="w-full border-collapse border border-gray-300 text-sm">
                <thead>
                  <tr className="bg-gray-200 text-center">
                    <th className="p-2 ">S.No</th>
                    <th className="p-2 ">Particulars</th>
                    <th className="p-2 ">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="text-center">
                    <td className="p-2">1</td>
                    <td className="p-2">{billData?.serviceName}</td>
                    <td className="p-2 ">{billData?.paidAmount}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="mt-8 flex flex-col items-end">
              <span className="text-sm mb-2">
                <strong>Amount Receivable:</strong> {billData?.paidAmount}
              </span>
              <span className="text-sm">
                <strong>Amount Received:</strong> {billData?.paidAmount}
              </span>
            </div>
            <div className="mt-6 text-sm">
              <span>
                <strong>Amount (in words):</strong>{" "}
                {numberToWords(Number(billData?.paidAmount)).toUpperCase()}{" "}
                RUPEES ONLY
              </span>
            </div>

            <div className="mt-8 ">
              {/* <p className="text-sm">Authorized Signature</p> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default BillingPrint;
