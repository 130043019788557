import http from "./http-common";

class OrderService {
  
  queryString(payload) {
    return Object.keys(payload)
      .map((key) => (key && payload[key] ? key + "=" + payload[key] : ""))
      .join("&");
  }

  GetOrderList(payload) {
    var queryString = this.queryString(payload);
    return http.get(`/billingTransaction/getDrugAndLabAmount?${queryString}`);
  }

  GetOrderListCount(payload) {
    var queryString = this.queryString(payload);
    return http.get(`/billingTransaction/getDrugAndLabCount?${queryString}`);
  }
}

export default new OrderService();
