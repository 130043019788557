import React, { useState } from "react";
import { Menu, Modal, Tooltip } from "antd";
import { useHistory, useLocation } from "react-router-dom";

import { APP_ROUTES } from "../../PageRouting/approutes";
import { MdDashboard } from "react-icons/md";
import { FaCalendarAlt } from "react-icons/fa";
import { FiUsers } from "react-icons/fi";
import { VscCreditCard } from "react-icons/vsc";
import { IoSettings } from "react-icons/io5";
import { IoLogOutSharp } from "react-icons/io5";
import { BiSolidReport } from "react-icons/bi";
// import { useContext } from "react";
// import { ConsultationDataContext } from "../../Context/consultationContext";

function Sidemenu({ isMenuOpen }) {
  const location = useLocation();
  const history = useHistory();
  const [pageRoute, setPageRoute] = useState("");
  const [showWarning, setShowWarning] = useState(false);
  const [LogoutWarning, setLogOutWarning] = useState(false);
  // const consultationState = useContext(ConsultationDataContext);

  const handleLogOutOk = () => {
    localStorage.clear();
    history.push(pageRoute);
  };
  const handleLogOutCancel = () => {
    setLogOutWarning(false);
  };

  const handleOk = () => {
    setShowWarning(false);
  };
  const handleCancel = () => {
    setShowWarning(false);
  };

  const isMenuItemActive = (path) => {
    return location.pathname === path;
  };

  const handleNavigation = (path) => {
    if (location.pathname === APP_ROUTES.VIDEOCALL) {
      setPageRoute(path);
      setShowWarning(true);
    } else if (path === "/") {
      localStorage.clear();
      setPageRoute("");
      history.push(path);
    } else {
      setPageRoute("");
      history.push(path);
    }
  };

  const handleLogout = (path) => {
    if (location.pathname === APP_ROUTES.VIDEOCALL) {
      setPageRoute(path);
      setShowWarning(true);
    } else {
      setLogOutWarning(true);
      setPageRoute(path);
    }
  };

  // const handleDraft = async (e) => {
  //   e.preventDefault();
  //   const currDate = new Date().toISOString();
  //   const date = currDate.split("T")[0];
  //   const time = currDate.split("T")[1].slice(0, 8);
  //   const constructedTime = date + " " + time;
  //   const buttonClick = e.target.innerHTML;
  //   const patientData = state?.selectedPatient;
  //   const userData = JSON.parse(localStorage.getItem("login_result"));

  //   let diagnosisArr = [];
  //   let vitalsArr = [];
  //   let medicinesArr = [];
  //   let labtestArr = [];
  //   const selectedOriginalDiagArr = consultationState?.consultationPayload?.diagnosis;
  //   const medicinesObj = consultationState?.consultationPayload?.medicines;
  //   const labTestsObj = consultationState?.consultationPayload?.lab;
  //   const vitalsArrObj = consultationState?.consultationPayload?.vitals;
  //   const VitalKeys = Object.keys(vitalsArrObj);

  //   // diagnosis payload objects
  //   for (let i = 0; i < selectedOriginalDiagArr?.length; i++) {
  //     let Diagpayload = {
  //       ...selectedOriginalDiagArr[i],
  //       account_Id: userData?.user?.account_Id,
  //       clinic_Id: userData?.user?.clinic_Id,
  //       createdBy: userData?.user.userId,
  //       createdDate: constructedTime,
  //       diagnosisCode: selectedOriginalDiagArr[i]?.diagnosisCode,
  //       diagnosisDescription: selectedOriginalDiagArr[i]?.diagnosisDescription,
  //       diagnosisType: selectedOriginalDiagArr[i]?.diagnosisType,
  //       modifiedBy: userData?.user.userId,
  //       modifiedDate: constructedTime,
  //       patientId: patientData?.patientId,
  //       status:
  //         selectedOriginalDiagArr[i]?.status === 0
  //           ? 0
  //           : selectedOriginalDiagArr[i]?.status === undefined
  //             ? 1
  //             : selectedOriginalDiagArr[i]?.status,
  //       userId: userData?.user.userId,
  //     };
  //     if (buttonClick === "Update") {
  //       Diagpayload.consultationId = selectedPatient?.consultationId;
  //     }
  //     diagnosisArr.push(Diagpayload);
  //   }

  //   // vitals payload objects
  //   const vitalDate = new Date().toUTCString();
  //   console.log(vitalDate);
  //   const dateArr = vitalDate.split(" ");
  //   const vitMonth = months[dateArr[2]];
  //   const vitDate = dateArr[1];
  //   const vitYear = dateArr[3];
  //   const constructedDate = vitYear + "-" + vitMonth + "-" + vitDate;
  //   const vitTime = dateArr[4];
  //   const constructedDateTime = constructedDate + " " + vitTime;

  //   for (let i = 0; i < VitalKeys.length; i++) {
  //     let vitalPayload = {
  //       account_Id: userData?.user?.account_Id,
  //       clinic_Id: userData?.user?.clinic_Id,
  //       userId: userData?.user?.userId,
  //       createdBy: userData?.user.userId,
  //       createdDate: constructedDateTime,
  //       modifiedBy: userData?.user.userId,
  //       modifiedDate: constructedDateTime,
  //       patientId: patientData?.patientId,
  //       recordedDateTime: constructedDateTime,
  //       status: 1,
  //       units: vitalMasterList.find(
  //         (vital) => vital.vitalCode === VitalKeys[i]
  //       )?.units,
  //       value:
  //         VitalKeys[i] === "BMI"
  //           ? vitalsArrObj[VitalKeys[i]]
  //             ? vitalsArrObj[VitalKeys[i]]
  //             : consulationData?.patientVitals
  //               ? consulationData?.patientVitals?.find(
  //                 (item) => item.vitalCode === "BMI"
  //               )?.value
  //               : ""
  //           : VitalKeys[i] === "BPS"
  //             ? vitalsArrObj[VitalKeys[i]]
  //               ? vitalsArrObj[VitalKeys[i]]
  //               : consulationData?.patientVitals
  //                 ? consulationData?.patientVitals?.find(
  //                   (item) => item.vitalCode === "BPS"
  //                 )?.value
  //                 : ""
  //             : VitalKeys[i] === "BPD"
  //               ? vitalsArrObj[VitalKeys[i]]
  //                 ? vitalsArrObj[VitalKeys[i]]
  //                 : consulationData?.patientVitals
  //                   ? consulationData?.patientVitals?.find(
  //                     (item) => item.vitalCode === "BPD"
  //                   )?.value
  //                   : ""
  //               : VitalKeys[i] === "PO"
  //                 ? vitalsArrObj[VitalKeys[i]]
  //                   ? Number(vitalsArrObj[VitalKeys[i]])
  //                   : consulationData?.patientVitals
  //                     ? consulationData?.patientVitals?.find(
  //                       (item) => item.vitalCode === "PO"
  //                     )?.value
  //                     : ""
  //                 : VitalKeys[i] === "HR"
  //                   ? vitalsArrObj[VitalKeys[i]]
  //                     ? Number(vitalsArrObj[VitalKeys[i]])
  //                     : consulationData?.patientVitals
  //                       ? consulationData?.patientVitals?.find(
  //                         (item) => item.vitalCode === "HR"
  //                       )?.value
  //                       : ""
  //                   : VitalKeys[i] === "RR"
  //                     ? vitalsArrObj[VitalKeys[i]]
  //                       ? Number(vitalsArrObj[VitalKeys[i]])
  //                       : consulationData?.patientVitals
  //                         ? consulationData?.patientVitals?.find(
  //                           (item) => item.vitalCode === "RR"
  //                         )?.value
  //                         : ""
  //                     : VitalKeys[i] === "TP"
  //                       ? vitalsArrObj[VitalKeys[i]]
  //                         ? Number(vitalsArrObj[VitalKeys[i]])
  //                         : consulationData?.patientVitals
  //                           ? consulationData?.patientVitals?.find(
  //                             (item) => item.vitalCode === "TP"
  //                           )?.value
  //                           : ""
  //                       : VitalKeys[i] === "HI"
  //                         ? vitalsArrObj[VitalKeys[i]]
  //                           ? Number(vitalsArrObj[VitalKeys[i]])
  //                           : consulationData?.patientVitals
  //                             ? consulationData?.patientVitals?.find(
  //                               (item) => item.vitalCode === "HI"
  //                             )?.value
  //                             : ""
  //                         : VitalKeys[i] === "WE"
  //                           ? vitalsArrObj[VitalKeys[i]]
  //                             ? Number(vitalsArrObj[VitalKeys[i]])
  //                             : consulationData?.patientVitals
  //                               ? consulationData?.patientVitals?.find(
  //                                 (item) => item.vitalCode === "WE"
  //                               )?.value
  //                               : ""
  //                           : "",
  //       vitalCode: vitalMasterList.find(
  //         (vital) => vital.vitalCode === VitalKeys[i]
  //       )?.vitalCode,
  //       vitalName: vitalMasterList.find(
  //         (vital) => vital.vitalCode === VitalKeys[i]
  //       )?.vitalName,
  //     };

  //     vitalsArr.push(vitalPayload);
  //   }

  //   // medicine payload objects
  //   for (let i = 0; i < medicinesObj?.length; i++) {
  //     const toDate =
  //       medicinesObj[i]?.duration * 24 * 60 * 60 * 1000
  //         ? new Date(
  //           new Date().getTime() +
  //           medicinesObj[i]?.duration * 24 * 60 * 60 * 1000
  //         ).toISOString()
  //         : new Date().toISOString();
  //     const toDays = toDate.split("T")[0];
  //     const constructedToDateTime = toDays + " " + time;
  //     let medicinePayload = {
  //       // ...medicinesObj[i],
  //       account_Id: userData?.user?.account_Id,
  //       clinic_Id: userData?.user?.clinic_Id,
  //       afterBeforeFood:
  //         medicinesObj[i]?.timeTaken === "afterfood" ? "AF" : "BF",
  //       composition: medicinesObj[i]?.composition,
  //       createdBy: userData?.user.userId,
  //       createdDate: constructedTime,
  //       dosage: medicinesObj[i]?.dosage ? medicinesObj[i]?.dosage : null,
  //       drugCode: medicinesObj[i]?.code
  //         ? medicinesObj[i]?.code
  //         : medicinesObj[i]?.drugCode
  //           ? medicinesObj[i]?.drugCode
  //           : null,
  //       drugName: medicinesObj[i]?.drugName ? medicinesObj[i]?.drugName : null,
  //       duration: medicinesObj[i]?.duration ? medicinesObj[i]?.duration : null,
  //       form: null,
  //       frequency: medicinesObj[i]?.dosage ? medicinesObj[i]?.dosage : null,
  //       fromDateTime: constructedTime,
  //       givenDate: constructedTime,
  //       instruction: medicinesObj[i]?.instruction
  //         ? medicinesObj[i]?.instruction
  //         : null,
  //       medicineType: medicinesObj[i]?.productType
  //         ? medicinesObj[i]?.productType
  //         : null,
  //       modifiedBy: userData?.user.userId,
  //       modifiedDate: constructedTime,
  //       patientId: patientData?.patientId,
  //       quantity: medicinesObj[i]?.quantity,
  //       specialAuthority: null,
  //       status:
  //         medicinesObj[i]?.status === 0
  //           ? 0
  //           : medicinesObj[i]?.status === undefined
  //             ? 1
  //             : medicinesObj[i]?.status,
  //       toDateTime: constructedToDateTime,
  //       userId: userData?.user.userId,
  //     };
  //     if (selectedPatient?.consultationId) {
  //       medicinePayload.consultationId = selectedPatient?.consultationId;
  //     }
  //     medicinesArr.push(medicinePayload);
  //   }

  //   //labtest payload objects
  //   for (let i = 0; i < labTestsObj?.length; i++) {
  //     let labPayload = {
  //       ...labTestsObj[i],
  //       account_Id: userData?.user?.account_Id,
  //       clinic_Id: userData?.user?.clinic_Id,
  //       createdBy: userData?.user.userId,
  //       createdDate: constructedTime,
  //       documentReceivedSource: null,
  //       labTestId: labTestsObj[i]?.labTestId ? labTestsObj[i]?.labTestId : null,
  //       labTestName: labTestsObj[i]?.labTestName
  //         ? labTestsObj[i]?.labTestName
  //         : null,
  //       modifiedBy: userData?.user.userId,
  //       modifiedDate: constructedTime,
  //       parentLabTestName: labTestsObj[i]?.parentDescription
  //         ? labTestsObj[i]?.parentDescription
  //         : null,
  //       patientId: patientData?.patientId,
  //       status:
  //         labTestsObj[i]?.status === 0
  //           ? labTestsObj[i]?.status
  //           : labTestsObj[i]?.status === undefined
  //             ? 1
  //             : labTestsObj[i]?.status,
  //       userId: userData?.user.userId,
  //     };
  //     if (selectedPatient?.consultationId) {
  //       labPayload.consultationId = selectedPatient?.consultationId;
  //     }
  //     labtestArr.push(labPayload);
  //   }

  //   let payload = {
  //     account_Id: userData?.user?.account_Id,
  //     clinic_Id: userData?.user?.clinic_Id,
  //     consultationDate: constructedTime,
  //     consultationReason: patientData?.appointmentReason,
  //     consultationType: patientData?.serviceName,
  //     createdBy: userData?.user.userId,
  //     createdDate: constructedTime
  //       ? constructedTime
  //       : consulationData?.createdDate,
  //     drugPrescription: medicinesArr,
  //     followupVisitDate:
  //       followUpDate.length !== 0
  //         ? followUpDate
  //         : consulationData?.followupVisitDate,
  //     image: "",
  //     imageName: null,
  //     imageType: null,
  //     labPrescription: labtestArr,
  //     modifiedBy: userData?.user.userId,
  //     modifiedDate: constructedTime,
  //     patientDocumentID: 0,
  //     patientId: patientData?.patientId,
  //     patientVitals: vitalsArr,
  //     patientDiagnosisDetails: diagnosisArr,
  //     // primaryDiagnosis: selectedPrimaryDiag.code,
  //     // primarySymptoms: selectedPrimaryDiag.description,
  //     recommendation: recommendation
  //       ? recommendation
  //       : consulationData?.recommendation,
  //     // secondaryDiagnosis: selectedSecondaryDiag.code,
  //     // secondarySymptoms: selectedSecondaryDiag.description,
  //     status: 3,
  //     symptoms: symptoms ? symptoms : consulationData?.symptoms,
  //     userId: userData?.user.userId,
  //     appointmentId: patientData?.appointmentId,
  //     specialityCode: userData?.user?.specialityCode,
  //   };
  //   if (selectedPatient?.consultationId) {
  //     payload.appointmentId = selectedPatient?.appointmentId;
  //     payload.consultationId = selectedPatient?.consultationId;
  //   }

  //   if (buttonClick === "Save as draft"){
  //     await ConsultationService.ConsultationNotes(payload);
  //   }
  //   // This condition checks does the patient completed preconsultation.
  //   else if (selectedPatient?.consultationId && buttonClick !== "Update") {
  //     payload.id = consulationData?.id;
  //     payload.status = 2;
  //     const response = await ConsultationService.updateConsultation(payload);
  //     if (response.data.status !== -1) {
  //       const { appointmentId, consultationId } = response.data;
  //       let endConsultationPayload = {
  //         appointmentId,
  //         consultationId,
  //         patientId: patientData.patientId,
  //         userId: userData?.user?.userId,
  //         transactionId: patientData.transactionId
  //           ? patientData.transactionId
  //           : null,
  //       };
  //       await ConsultationService.EndConsultation(endConsultationPayload);
  //       payload = {};
  //       message.success(
  //         "Your consultation updated successfully, e-prescription will be shared with patient"
  //       );
  //       history.push(APP_ROUTES.DASHBOARD);
  //     } else {
  //       message.error("Error occurred. Please try after sometime.");
  //     }
  //     setIsEndConsLoading(false);
  //   }
  //   // This condition checks initial save
  //   else if (buttonClick === "End") {
  //     const response = await ConsultationService.ConsultationNotes(payload);
  //     if (response.data.status !== -1) {
  //       const { appointmentId, consultationId } = response.data;
  //       let endConsultationPayload = {
  //         appointmentId,
  //         consultationId,
  //         patientId: patientData.patientId,
  //         userId: userData?.user?.userId,
  //         transactionId: patientData.transactionId
  //           ? patientData.transactionId
  //           : null,
  //       };
  //       await ConsultationService.EndConsultation(endConsultationPayload);
  //       payload = {};
  //       message.success(
  //         "Your consultation ended successfully, e-prescription will be shared with patient."
  //       );
  //       history.push(APP_ROUTES.DASHBOARD);
  //     } else {
  //       message.error("Error occurred. Please try after sometime.");
  //     }
  //     setIsEndConsLoading(false);
  //   }
  // }

  return (
    <div className="min-h-screen md:block hidden fixed mt-20">
      <ul className={`flex gap-3 flex-col overflow-y-auto scrollBarWidth p-2`}>
        <Modal
          title="Alert!"
          open={showWarning}
          onOk={handleOk}
          onCancel={handleCancel}
          width={"30%"}
          footer={
            <div>
              <div className="">
                <button
                  className="mx-1 p-1 w-36 font-semibold  rounded-md  bg-white border border-blue-600"
                  onClick={handleCancel}
                >
                  No, Continue
                </button>
                <button
                  className="mx-1 p-1 w-36 font-semibold text-white rounded-md"
                  onClick={() => history.push(pageRoute)}
                >
                  Yes, Leave
                </button>
                {/* <button
                  className="mx-1 p-1 min-w-36 w-max font-semibold text-white bg-green-500 rounded-md"
                  // onClick={handleDraft}
                >
                  Save draft and leave
                </button> */}
              </div>
              {/* <div className="flex justify-end my-2">
                <button
                  className="mx-1 p-1 min-w-36 w-max font-semibold text-white bg-green-500 rounded-md"
                  onClick={() => console.log(consultationState.consultationPayload)}
                >
                  Save draft and leave
                </button>
              </div> */}
            </div>
          }
        >
          You haven't saved your changes. Are you sure you want to proceed?
        </Modal>

        <Modal
          title="Logout"
          open={LogoutWarning}
          onOk={handleLogOutOk}
          onCancel={handleLogOutCancel}
          width={"20%"}
          footer={
            <div className="flex">
              <button
                className="mx-1 p-1 w-36 font-semibold bg-white border-blue-600 border  rounded-md"
                onClick={handleLogOutCancel}
              >
                Cancel
              </button>
              <button
                className="mx-1 p-1 w-36 font-semibold  border border-blue-600 text-white rounded-md"
                onClick={handleLogOutOk}
              >
                Yes
              </button>
            </div>
          }
        >
          Are you sure to log out?
        </Modal>
        <li>
          <div
            onClick={() => handleNavigation(APP_ROUTES.DASHBOARD)}
            className={`flex items-center gap-3 md:px-2 py-2  cursor-pointer  ${
              isMenuItemActive(APP_ROUTES.DASHBOARD)
                ? "bg-[#E6F4FF]  rounded-lg"
                : " "
            } `}
          >
            <div>
              <Tooltip placement="right" title={"Dashboard"}>
                <MdDashboard
                  className={` h-5 w-5 ${
                    isMenuItemActive(APP_ROUTES.DASHBOARD)
                      ? "text-white bg-[#007AFC] p-[3px] rounded-lg"
                      : " text-[#0283C6] "
                  }`}
                />
              </Tooltip>
            </div>
            <span
              className={`${
                isMenuItemActive(APP_ROUTES.DASHBOARD)
                  ? "text-[#007AFC] font-bold"
                  : "text-[#041346] "
              }  font-semibold md:text-base text-sm ${
                !isMenuOpen ? "hidden" : ""
              }`}
            >
              Dashboard
            </span>
          </div>
        </li>
        <li>
          <div
            onClick={() => handleNavigation(APP_ROUTES.APPOINTMENTS)}
            className={`flex items-center gap-3 md:px-2 py-2  cursor-pointer  ${
              isMenuItemActive(APP_ROUTES.APPOINTMENTS)
                ? "bg-[#E6F4FF]  rounded-lg"
                : " "
            } `}
          >
            <div>
              <Tooltip placement="right" title={"Appointments"}>
                <FaCalendarAlt
                  className={` h-5 w-5 ${
                    isMenuItemActive(APP_ROUTES.APPOINTMENTS)
                      ? "text-white bg-[#007AFC] p-[3px] rounded-lg"
                      : " text-[#0283C6] "
                  }`}
                />
              </Tooltip>
            </div>
            <span
              className={`${
                isMenuItemActive(APP_ROUTES.APPOINTMENTS)
                  ? "text-[#007AFC] font-bold"
                  : "text-[#041346]"
              } font-semibold md:text-base text-sm ${
                !isMenuOpen ? "hidden" : ""
              }`}
            >
              Appointments
            </span>
          </div>
        </li>
        <li>
          <div
            onClick={() => handleNavigation(APP_ROUTES.PATIENTS)}
            className={`flex items-center gap-3 md:px-2 py-2  cursor-pointer  ${
              isMenuItemActive(APP_ROUTES.PATIENTS)
                ? "bg-[#E6F4FF]  rounded-lg"
                : " "
            } `}
          >
            <div>
              <Tooltip placement="right" title={"Patients"}>
                <FiUsers
                  className={` h-5 w-5 ${
                    isMenuItemActive(APP_ROUTES.PATIENTS)
                      ? "text-white bg-[#007AFC] p-[3px] rounded-lg"
                      : "text-[#0283C6]  "
                  }`}
                />
              </Tooltip>
            </div>
            <span
              className={`${
                isMenuItemActive(APP_ROUTES.PATIENTS)
                  ? "text-[#007AFC] font-bold"
                  : "text-[#041346]"
              } font-semibold md:text-base text-sm ${
                !isMenuOpen ? "hidden" : ""
              }`}
            >
              Patients
            </span>
          </div>
        </li>
        <li>
          <div
            onClick={() => handleNavigation(APP_ROUTES.PAYMENTS)}
            className={`flex items-center gap-3 md:px-2 py-2  cursor-pointer  ${
              isMenuItemActive(APP_ROUTES.PAYMENTS)
                ? "bg-[#E6F4FF]  rounded-lg"
                : " "
            } `}
          >
            <div>
              <Tooltip placement="right" title={"Payments"}>
                <VscCreditCard
                  className={` h-5 w-5 ${
                    isMenuItemActive(APP_ROUTES.PAYMENTS)
                      ? "text-white bg-[#007AFC] p-[3px] rounded-lg"
                      : "text-[#0283C6]  "
                  }`}
                />
              </Tooltip>
            </div>
            <span
              className={`${
                isMenuItemActive(APP_ROUTES.PAYMENTS)
                  ? "text-[#007AFC] font-bold"
                  : "text-[#041346]"
              } font-semibold md:text-base text-sm ${
                !isMenuOpen ? "hidden" : ""
              }`}
            >
              Payments
            </span>
          </div>
        </li>
        <li>
          <div
            onClick={() => handleNavigation(APP_ROUTES.BILLINGREPORTS)}
            className={`flex items-center gap-3 md:px-2 py-2  cursor-pointer  ${
              isMenuItemActive(APP_ROUTES.BILLINGREPORTS)
                ? "bg-[#E6F4FF] rounded-lg"
                : " "
            } `}
          >
            <div>
              <Tooltip placement="right" title={"Billing Reports"}>
                <BiSolidReport
                  className={` h-5 w-5 ${
                    isMenuItemActive(APP_ROUTES.BILLINGREPORTS)
                      ? "text-white bg-[#007AFC] p-[3px] rounded-lg"
                      : "text-[#0283C6]  "
                  }`}
                />
              </Tooltip>
            </div>
            <span
              className={`${
                isMenuItemActive(APP_ROUTES.BILLINGREPORTS)
                  ? "text-[#007AFC] font-bold"
                  : "text-[#041346]"
              } font-semibold md:text-base text-sm ${
                !isMenuOpen ? "hidden" : ""
              }`}
            >
              Billing Reports
            </span>
          </div>
        </li>
        <li>
          <div
            onClick={() => handleNavigation(APP_ROUTES.ORDERREPORT)}
            className={`flex items-center gap-3 md:px-2 py-2  cursor-pointer  ${
              isMenuItemActive(APP_ROUTES.ORDERREPORT)
                ? "bg-[#E6F4FF] rounded-lg"
                : " "
            } `}
          >
            <div>
              <Tooltip placement="right" title={"Order Reports"}>
                <BiSolidReport
                  className={` h-5 w-5 ${
                    isMenuItemActive(APP_ROUTES.ORDERREPORT)
                      ? "text-white bg-[#007AFC] p-[3px] rounded-lg"
                      : "text-[#0283C6]  "
                  }`}
                />
              </Tooltip>
            </div>
            <span
              className={`${
                isMenuItemActive(APP_ROUTES.ORDERREPORT)
                  ? "text-[#007AFC] font-bold"
                  : "text-[#041346]"
              } font-semibold md:text-base text-sm ${
                !isMenuOpen ? "hidden" : ""
              }`}
            >
              Order Reports
            </span>
          </div>
        </li>
        <li>
          <div
            onClick={() => handleNavigation(APP_ROUTES.SETTINGS)}
            className={`flex items-center gap-3 md:px-2 py-2  cursor-pointer  ${
              isMenuItemActive(APP_ROUTES.SETTINGS)
                ? "bg-[#E6F4FF]  rounded-lg"
                : " "
            } `}
          >
            <div>
              <Tooltip placement="right" title={"Change Password"}>
                <IoSettings
                  className={` h-5 w-5 ${
                    isMenuItemActive(APP_ROUTES.SETTINGS)
                      ? "text-white bg-[#007AFC] p-[3px] rounded-lg"
                      : " text-[#0283C6] "
                  }`}
                />
              </Tooltip>
            </div>
            <span
              className={`${
                isMenuItemActive(APP_ROUTES.SETTINGS)
                  ? "text-[#007AFC] font-bold"
                  : "text-[#041346]"
              } font-semibold md:text-base text-sm ${
                !isMenuOpen ? "hidden" : ""
              }`}
            >
              Change Password
            </span>
          </div>
        </li>
        <li>
          <div
            onClick={() => handleLogout(APP_ROUTES.LOGINFORM)}
            className={`flex items-center gap-3 md:px-2 py-2  cursor-pointer  ${
              isMenuItemActive(APP_ROUTES.LOGINFORM)
                ? "bg-[#E6F4FF]  rounded-lg"
                : " "
            } `}
          >
            <div>
              <Tooltip placement="right" title={"Logout"}>
                <IoLogOutSharp
                  className={` h-5 w-5 ${
                    isMenuItemActive(APP_ROUTES.LOGINFORM)
                      ? "text-white bg-[#007AFC] p-[3px] rounded-lg"
                      : "text-[#0283C6]  "
                  }`}
                />
              </Tooltip>
            </div>
            <span
              className={`${
                isMenuItemActive(APP_ROUTES.LOGOUT)
                  ? "text-[#007AFC] font-bold"
                  : "text-[#041346]"
              } font-semibold md:text-base text-sm ${
                !isMenuOpen ? "hidden" : ""
              }`}
            >
              Logout
            </span>
          </div>
        </li>
      </ul>
    </div>
  );
}

export default Sidemenu;
