import React from "react";
import { IoCall } from "react-icons/io5";
import { IoMdMail } from "react-icons/io";

function PharmacyFooter({ clinicDetails }) {
  return (
    <div className=" bg-white border-t-2 border-gray-300 w-full mt-10 mx-auto">
      <div className="md:flex justify-between mt-10 mb-10 text-gray-600 mx-2">
        <div>
          <p className="text-[#184B67]">
            {clinicDetails?.clinic_Name}
            <br />
            {clinicDetails?.clinicAddress}
            <br />
            {clinicDetails?.area_Name}
          </p>
        </div>
        <div className="text-[#184B67]">
          <div className="flex items-center cursor-pointer">
            <div className="bg-[#184B67] text-white p-2 rounded-full w-7 h-7">
              <IoCall className="text-[#F0A1D1]" />
            </div>
            <span className="ml-2">{clinicDetails?.contactNo}</span>
          </div>
          <br />
          <div className="md:flex items-center justify-center cursor-pointer">
            <div className="bg-[#184B67] text-white p-2 rounded-full w-7 h-7">
              <IoMdMail className="text-[#F0A1D1]" />
            </div>
            <span className="md:ml-2">{clinicDetails?.email}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PharmacyFooter;
