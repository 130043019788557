import React, { useEffect } from "react";

function DirectLink() {
  const getCodeFromURL = () => {
    const query = window.location.search;
    const codeMatch = query.match(/ref=([^&]*)/);
    return codeMatch ? codeMatch[1] : null;
  };

  useEffect(() => {
    let param = getCodeFromURL();
    console.log(param);
    window.location.replace(process.env.REACT_APP_IMAGE_URL + param);
  }, []);



  return (
    <div>
      <div class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
        <div className="spinner"></div>
      </div>
    </div>
  );
}

export default DirectLink;
