import { Badge, Drawer, Modal, message } from "antd";
import React, { useEffect, useState } from "react";
import NotificationsIcon from "../../Assets/Images/Svg/Notification.svg";
import { useHistory, useLocation } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import MyProfile from "../Pages/MyProfile";
import hamburger from "../../Assets/Images/hamburger.png";
import doxzonlogo from "../../Assets/Images/doxzonLogo.png";
import UserService from "../../Services/UserService";
import { APP_ROUTES } from "../../PageRouting/approutes";

function Header({ handleSideBarMenu }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [profileData, setProfileData] = useState(null);
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    localStorage.clear();
    history.push("/");
  };

  const handleDisabled = () => {
    message.success(
      "Our support team will contact you shortly regarding your request for edit your Profile"
    );
    setIsModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  let loginData = localStorage.getItem("login_result");
  let loginDataObject = loginData ? JSON.parse(loginData) : null;

  useEffect(() => {
    const loginData = localStorage.getItem("login_result");
    const parsedLoginData = loginData ? JSON.parse(loginData) : null;
    setProfileData(parsedLoginData);
  }, []);

  const handleNavigation = (route) => {
    history.push(route);
  };

  return (
    <div className="flex items-center h-20 border-b z-50 fixed w-full bg-white">
      <div className="flex items-center border-brand-textGray gap-5 mx-2 w-1/2">
        <img
          src={hamburger}
          alt="icon"
          className="w-5 cursor-pointer hidden md:block"
          onClick={handleSideBarMenu}
        />

        <img
          src={hamburger}
          alt="icon"
          className="w-5 cursor-pointer md:hidden block"
          onClick={showDrawer}
        />
        <img src={doxzonlogo} alt="logo" className="mt-2" />
      </div>
      <div className="md:w-1/2 flex justify-end gap-5 mr-10">
        <div className="flex items-center justify-end">
          <div></div>

          <div className=" ">
            <div className="hidden md:block cursor-pointer paste-button">
              {loginDataObject.user.imageName ? (
                <div className="rounded-full  md:w-12 w-24 md:h-12 h-24 ml-6 flex justify-center items-center">
                  <img
                    src={
                      process.env.REACT_APP_IMAGE_URL +
                      loginDataObject.user.imageName
                    }
                    alt="DocImg"
                    className="rounded-full md:w-12 w-6 md:h-12 h-6"
                  />
                </div>
              ) : (
                <div className="rounded-full border border-brand-secondaryColor md:w-12 w-10 md:h-12 h-10 ml-6 flex justify-center items-center">
                  <FaUser className="md:w-8 w-6 md:h-8 h-6" />
                </div>
              )}
              {location.pathname !== APP_ROUTES.VIDEOCALL ? (
                <div className="dropdown-content">
                  <div className="p-2 ">
                    <p className="text-black text-sm mb-0">
                      {`${profileData?.user.salutation} ${profileData?.user.firstName} ${profileData?.user.lastName}`}
                    </p>
                    <p className="text-black text-xs font-thin mb-0 ">
                      {profileData?.user.email}
                    </p>
                  </div>

                  <a id="top" href="#" onClick={showModal}>
                    My Profile
                  </a>
                  <a id="middle" onClick={handleLogout} href="#">
                    Logout
                  </a>
                </div>
              ) : (
                <div className="dropdown-content ">
                  <div className="p-2 ">
                    <p className="text-black text-sm mb-0">
                      {`${profileData?.user.salutation} ${profileData?.user.firstName} ${profileData?.user.lastName}`}
                    </p>
                    <p className="text-black text-xs font-thin mb-0 ">
                      {profileData?.user.email}
                    </p>
                  </div>
                  <div className="flex flex-col justify-center items-start p-2 gap-3 mb-2">
                    <span className="cursor-not-allowed">My Profile</span>
                    <span className="cursor-not-allowed">Logout</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        title={
          <div className="flex justify-between ">
            <h2 className="font-bold text-xl">My Profile</h2>
            <button
              className="px-2 text-white font-normal rounded"
              onClick={handleDisabled}
            >
              Request for Edit
            </button>
          </div>
        }
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
        width="70%"
        style={{
          top: "10%",
        }}
        closeIcon={false}
      >
        <MyProfile closeModal={handleOk} />
      </Modal>
      <Drawer
        title={<div className="text-2xl">Doxzon</div>}
        onClose={onClose}
        open={open}
        placement="left"
        width="50%"
        style={{
          backgroundColor: "whitesmoke",
        }}
      >
        <div className="">
          <ul className="text-base font-medium">
            <li className="mb-4" onClick={() => handleNavigation("/dashboard")}>
              Dashboard
            </li>
            <li
              className="mb-4"
              onClick={() => handleNavigation("/appointments")}
            >
              Appointments
            </li>
            <li className="mb-4" onClick={() => handleNavigation("/patients")}>
              Patients
            </li>
            <li className="mb-4" onClick={() => handleNavigation("/payments")}>
              Payments
            </li>
            <li
              className="mb-4"
              onClick={() => handleNavigation("/billing-repors")}
            >
              Billing Reports
            </li>
            <li
              className="mb-4"
              onClick={() => handleNavigation("/order_report")}
            >
              Order Reports
            </li>
            <li className="mb-4" onClick={() => handleNavigation("/settings")}>
              Change Password
            </li>
            <li className="mb-4" onClick={() => handleNavigation("/")}>
              Logout
            </li>
          </ul>
        </div>
      </Drawer>
    </div>
  );
}

export default Header;
