import React from "react";
import { IoCall } from "react-icons/io5";
import { IoMdMail } from "react-icons/io";

function PrescriptionFooter({ clinicDetails,doctorData }) {
  return (
    <>
      {
        clinicDetails?.prescription_FooterYN === 'Y' ?
          (
            <div className="h-10 my-6"></div>
          )
          :
          clinicDetails?.prescription_FooterYN === 'C' ?
            (
              <div dangerouslySetInnerHTML={{ __html: atob(doctorData.footerDetails).replace(/className=/g, 'class=') }} />
            ) :
            clinicDetails?.prescription_FooterYN === 'N' ?
              (
                <div style={{
                  height: `${clinicDetails.prescription_Footer_Space}px`
                }}
                  className="h-auto">

                </div>
              ) : ''
      }
    </>
    // <div className=" bg-white border-t-2 border-gray-300 w-full mx-auto">
    //   <div className="text-center mb-6">
    //     <h2 className="md:text-lg text-sm font-semibold text-[#184D67] underline">
    //       Services
    //     </h2>
    //     <div className="mt-2 md:flex justify-center space-x-3 text-[#A7527E] font-medium">
    //       <span>General Medicine</span>
    //       <span className="text-blue-800">|</span>
    //       <span>Maternity</span>
    //       <span className="text-blue-800">|</span>
    //       <span>Fertility</span>
    //       <span className="text-blue-800">|</span>
    //       <span>General & Cosmetic Gynecology</span>
    //       <span className="text-blue-800">|</span>
    //       <span>Well Women Services</span>
    //       <span className="text-blue-800">|</span>
    //       <span>Lab & Scans</span>
    //     </div>
    //   </div>

    //   <div className="md:flex justify-between text-gray-600 mx-2">
    //     <div>
    //       <p className="text-[#184B67]">
    //         {clinicDetails?.clinic_Name}
    //         <br />
    //         {clinicDetails?.clinicAddress}
    //         <br />
    //         {clinicDetails?.area_Name}
    //       </p>
    //     </div>
    //     <div className="text-[#184B67]">
    //       <div className="flex items-center cursor-pointer">
    //         <div className="bg-[#184B67] text-white p-2 rounded-full w-7 h-7">
    //           <IoCall className="text-[#F0A1D1]" />
    //         </div>
    //         <span className="ml-2">{clinicDetails?.contactNo}</span>
    //       </div>
    //       <br />
    //       <div className="md:flex items-center justify-center cursor-pointer">
    //         <div className="bg-[#184B67] text-white p-2 rounded-full w-7 h-7">
    //           <IoMdMail className="text-[#F0A1D1]" />
    //         </div>
    //         <span className="md:ml-2">{clinicDetails?.email}</span>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
}

export default PrescriptionFooter;
