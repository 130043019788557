import http from "./http-common";

class LabOrderService {
  queryString(payload) {
    return Object.keys(payload)
      .map((key) => (key && payload[key] ? key + "=" + payload[key] : ""))
      .join("&");
  }

  GetLabList(payload) {
    const queryString = this.queryString(payload);
    return http.get(`/userLabMap/list/filter?${queryString}`);
  }

  getLabReportsData(payload) {
    const queryString = this.queryString(payload);
    return http.get(`labTestOrderResult/getall-labresults?${queryString}`);
  }

  GetLabTestOrderDtl(payload) {
    const queryString = this.queryString(payload);
    return http.get(`/labTestOrderDtl/prescriptionforlab?${queryString}`);
  }

  LabListSave(payload) {
    return http.post(`/labTestOrderHdr/save`, payload);
  }

  LabListGet(payload) {
    const queryString = this.queryString(payload);
    return http.get(`/labTestOrderHdr/list/filter?${queryString}`);
  }

  LabReportUpload(payload) {
    return http.post(`/labTestOrderResult/save`, payload);
  }

  LabReportUploadGet(payload) {
    const queryString = this.queryString(payload);
    return http.get(`/labTestOrderResult/list/filter?${queryString}`);
  }

  UpdateLabStatus(payload) {
    return http.put(`/patientAppointment/update/labPrescriptionStatus`,payload);
  }
}
export default new LabOrderService();
