const APP_ROUTES = {
  LOGINFORM: "/",
  FORGETPASSWORD: "/forgot-password",
  DASHBOARD: "/dashboard",
  APPOINTMENTS: "/appointments",
  PAYMENTS: "/payments",
  PATIENTS: "/patients",
  BILLINGREPORTS: "/billing-repors",
  VIDEOCALL: "/videocall",
  SETTINGS: "/settings",
  CONSULTATIONNOTES: "/consultaion_notes",
  EPRESCRIPTION: "/eprescription",
  SHARECALL: "/vc/:id/:type",
  SHAREPAYMENTS: "/s/v1",
  VCPAGE: "/vcpage",
  CHAT: "/chat",
  MEDICALRECORDS: "/medicalrecords",
  VIEWMEDICALRECORDS: "/viewmedicalrecords",
  SHAREPRESCRIPTION: "/prescription/:id",
  PHONEPEPAYMENT: "/phonepepayment/:transactionId",
  MEDPHARMA: "/sp/v1/p",
  LABPHARMA: "/sp/v1/l",
  ORDERREPORT: "/order_report",
  AUTHENTICATE: "/authenticate",
  MOBILELOGIN: "/mobile-login",
  DIRECTLINK: "/v1/",
};

export { APP_ROUTES };
