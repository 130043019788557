import http from "./http-common";
import _ from "lodash";

class ConsultationService {
  constructor() {
    this.debouncedIcd = _.debounce((values) => {
      return this.ICDData(values);
    }, 150);
  }

  queryString(payload) {
    return Object.keys(payload)
      .map((key) => (key && payload[key] ? key + "=" + payload[key] : ""))
      .join("&");
  }

  ConsultationNotes(payload) {
    return http.post(`/consultation/save`, payload);
  }

  EndConsultation(payload) {
    var queryString = Object.keys(payload)
      .map((key) => (key && payload[key] ? key + "=" + payload[key] : ""))
      .join("&");
    // return http.put(`/consultation/endConsultation?${queryString}`);
    return http.post(`/consultation/endConsultation`, payload);
  }
  updateConsultation(payload) {
    return http.put(`/consultation/update/${payload.id}`, payload);
  }
  getPrescriptions(payload) {
    var queryString = Object.keys(payload)
      .map((key) => (key && payload[key] ? key + "=" + payload[key] : ""))
      .join("&");
    return http.get(`/consultation/list/filter?${queryString}`);
  }

  LabTest(values) {
    return http.get(`/LabTestsAndScans/list/filter?name=${values}`);
  }

  LabTestCode(values) {
    return http.get(`/LabTestsAndScans/list/filter?code=${values}`);
  }

  async ICDCode(values) {
    return await http.get(`/common-icd-codes/list?code=${values}`);
  }

  ICDDescription(values) {
    return http.get(`/common-icd-codes/list?description=${values}`);
  }

  ICDData(values) {
    const queryString = this.queryString(values);
    return http.get(`/common-icd-codes/list?${queryString}`);
  }

  fetchICD(values) {
    return this.debouncedIcd(values);
  }

  getClinicData(payload) {
    return http.get(`/Clinic_Master/list/filter?clinic_Id=${payload}`);
  }

  updateStartTime(payload) {
    return http.put(`/patientAppointment/updateStartDateTime`, payload);
  }

  SharePrescriptionAPI(payload) {
    return http.post(`/consultation/share-Eprescription`, payload);
  }

  SharePharmaLabAPI(payload) {
    return http.post(`/consultation/share-Eprescription/pharmaandlab`, payload);
  }

  UpdatePatientDetailsInConsultation(payload) {
    return http.put(`/patient/update/patientdetails`, payload);
  }
}
export default new ConsultationService();
