import React, { useEffect, useState } from "react";
import prescription from "../../../Assets/Images/prescriptionicon.png";
import share from "../../../Assets/Images/share.png";
import phone from "../../../Assets/Images/phone.png";
import AppointmentService from "../../../Services/AppointmentService";
import PatientService from "../../../Services/PatientService";
import { APP_ROUTES } from "../../../PageRouting/approutes";
import { useHistory } from "react-router-dom";
import videoicon from "../../../Assets/Images/videoicon.png";
import { IoMdVideocam } from "react-icons/io";
import Edit from "../../../Assets/Images/Svg/EditIcon.svg";
import Preview from "../../../Assets/Images/Svg/PreviewIcon.svg";
import { Button, Modal, Tooltip } from "antd";
import PrescriptionDetails from "../ConsultationNotes/VitalTabs/ViewPrescription/PrescriptionDetails";
import ConsultationService from "../../../Services/ConsultationService";
import { RWebShare } from "react-web-share";
import close from "../../../Assets/Images/close.png";
import shareIcon from "../../../Assets/Images/Svg/shareIcon.svg";
import medicineImage from "../../../Assets/Images/Svg/addMedicine.svg";
import labReports from "../../../Assets/Images/Svg/addMedicine@.svg";
import MedicineDisabled from "../../../Assets/Images/Svg/MedicineDisabled.svg";
import LabDisabled from "../../../Assets/Images/Svg/LabDisabled.svg";
import ShareOrders from "./ShareOrders";
import SharePrescription from "./SharePrescription";
import { IoPersonSharp } from "react-icons/io5";

const AppointmentCard = () => {
  const [appointmentData, setAppointmentData] = useState([]);
  const [preview, setPreview] = useState(false);
  const [selectedPrescription, setSelectedPrescription] = useState(null);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [cancelAppointmentID, setCancelAppointmentID] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = (record) => {
    setIsModalOpen(true);
    setCancelAppointmentID(record);
  };

  const handlePreviewOk = () => {
    setPreview(false);
  };
  const handlePreviewCancel = () => {
    setPreview(false);
  };

  useEffect(() => {
    getAppointmentList();
  }, []);

  const storedLoginResult = localStorage.getItem("login_result");
  const loginResultObject = storedLoginResult
    ? JSON.parse(storedLoginResult)
    : null;

  const getAppointmentList = () => {
    const fromDate = new Date();
    const formattedDate = fromDate.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });

    let payload = {
      userId: loginResultObject.user.userId,
      fromDate: formattedDate,
    };

    AppointmentService.AppointmentFilter(payload).then((res) => {
      setAppointmentData(res.data);
    });
  };

  const history = useHistory();

  const handleClick = (record, tab) => {
    record.callMode = tab == "1" ? "JoinCall" : "EditCall";
    let payload = {
      patientId: record.patientId,
    };

    PatientService.PatientListFilter(payload).then((res) => {
      localStorage.setItem("patient", JSON.stringify(res.data[0]));
    });
    history.push(APP_ROUTES.VIDEOCALL, { selectedPatient: record });
    window.scrollTo(0, 0);
  };

  const getInitials = (name) => {
    if (!name) return "";
    name = name.replace(/^Mr\.?\s*/, "").trim();
    const initials = name.slice(0, 1).toUpperCase();
    return initials;
  };

  const formatTime = (time) => {
    const [hours, minutes] = time.split(":");
    const date = new Date();
    date.setHours(parseInt(hours, 10));
    date.setMinutes(parseInt(minutes, 10));
    const formattedTime = date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    return formattedTime;
  };

  const showPreview = async (record) => {
    let patientPayload = {
      patientId: record?.patientId,
    };
    let consultationPayload = {
      consultationId: record?.consultationId,
      detailsYN: "Y",
    };
    try {
      const patientResponse = await PatientService.PatientListFilter(
        patientPayload
      );
      if (patientResponse) {
        setSelectedPatient(patientResponse.data[0]);
      }
      const consultationResponse = await ConsultationService.getPrescriptions(
        consultationPayload
      );
      if (consultationResponse.data) {
        setSelectedPrescription(consultationResponse.data[0]);
        setPreview(true);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const cancelAppointment = () => {
    setIsModalOpen(false);
  };

  const handleOk = () => {
    AppointmentService.AppointmentCancel(
      cancelAppointmentID.appointmentId
    ).then((res) => {
      setIsModalOpen(false);
      getAppointmentList();
    });
    setCancelAppointmentID({});
  };

  return (
    <>
      <div className="container mx-auto">
        {appointmentData.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {appointmentData.map((data, index) => (
              <div
                className=" rounded-lg mt-4 shadow-xl  flex flex-col gap-4 bg-white"
                key={index}
              >
                <div className="flex gap-2 p-4">
                  <div className="w-[20%]">
                    <div className="h-10 w-10 rounded-full flex items-center justify-center text-white bg-blue-500">
                      <span>{getInitials(data.patientName)}</span>
                    </div>
                  </div>
                  <div className="w-[60%] flex flex-col gap-2 font-bold">
                    <span>{data.patientName}</span>
                    <span>{data.patientId}</span>
                  </div>
                  <div className="w-[20%] flex flex-col gap-2 font-bold">
                    <div size="middle" className="">
                      {data.status === 1 ? (
                        <div className="flex justify-center items-center gap-2">
                          <Tooltip placement="bottom" title={"Share Link"}>
                            <div className="border  border-gray-500 p-1 cursor-pointer rounded-lg">
                              <div className="flex items-center gap-2">
                                <RWebShare
                                  data={{
                                    text: "Appointment",
                                    url:
                                      `${process.env.REACT_APP_PATIENT_BASEURL}vc?ref=` +
                                      data?.appointmentId +
                                      "/N",
                                    title: "Share Link",
                                  }}
                                  onClick={() =>
                                    console.log("shared successfully!")
                                  }
                                >
                                  <img
                                    className="w-4"
                                    src={share}
                                    alt="shareImage"
                                  />
                                </RWebShare>
                              </div>
                            </div>
                          </Tooltip>
                          <Tooltip
                            placement="bottom"
                            title={"Cancel Appointment"}
                          >
                            <div
                              onClick={() => showModal(data)}
                              className="border  border-gray-500 p-1 cursor-pointer rounded-lg"
                            >
                              <img
                                className="w-4"
                                src={close}
                                alt="closeImage"
                              />
                            </div>
                          </Tooltip>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="p-4 flex  flex-col  gap-3">
                  <div>
                    <span className="font-bold">Appointment ID: </span>
                    {data.appointmentId}
                  </div>
                  <div>
                    <span className="font-bold">Chief Complaint: </span>
                    {data.appointmentReason}
                  </div>
                  <div>
                    <span className="font-bold">Status: </span>
                    {data.status === 0 && (
                      <span className=" text-red-500 font-semibold">
                        Cancelled
                      </span>
                    )}
                    {data.status === 1 && (
                      <span className=" text-blue-500 font-semibold">
                        Pending
                      </span>
                    )}
                    {data.status === 2 && (
                      <span className=" text-green-500 font-semibold">
                        Completed
                      </span>
                    )}
                  </div>
                  <div className="flex items-center">
                    <span className="font-bold">Rx: </span>
                    {data.status === 2 ? (
                      <div className="flex gap-2 ml-2">
                        <img
                          onClick={() => handleClick(data, "2")}
                          src={Edit}
                          alt=""
                          className="w-8 h-8 bg-white rounded-md cursor-pointer"
                        />
                        <img
                          src={Preview}
                          alt=""
                          className="w-8 h-8 bg-white rounded-md cursor-pointer"
                          role="button"
                          onClick={() => showPreview(data)}
                        />
                        <SharePrescription
                          record={data}
                          className={"w-8 h-8 rounded-md cursor-pointer"}
                          paypendingClass={
                            "w-8 h-8 bg-gray-300 rounded-md cursor-not-allowed"
                          }
                        />
                      </div>
                    ) : (
                      <div className="flex gap-2 ml-2">
                        <img
                          src={Edit}
                          alt=""
                          className="w-8 h-8 bg-gray-300   rounded-md cursor-not-allowed"
                        />
                        <img
                          src={Preview}
                          alt=""
                          className="w-8 h-8 bg-gray-300   rounded-md cursor-not-allowed"
                          role="button"
                        />
                        <img
                          src={shareIcon}
                          alt=""
                          className="w-8 h-8 bg-gray-300   rounded-md cursor-not-allowed"
                          role="button"
                          // onClick={() => showPreview(data)}
                        />
                      </div>
                    )}
                  </div>
                  <div className="flex items-center">
                    <span className="font-bold mr-1">Orders: </span>
                    {data.status === 2 ? (
                      <div className="flex gap-1 items-center cursor-pointer">
                        <ShareOrders record={data} />
                      </div>
                    ) : (
                      <div className="flex gap-1 items-center cursor-pointer">
                        <img
                          src={MedicineDisabled}
                          alt="Preview"
                          className="w-8 h-8 cursor-not-allowed"
                          role="button"
                        />

                        <img
                          src={LabDisabled}
                          alt="Share"
                          className="w-8 h-8 cursor-not-allowed"
                          role="button"
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className={`flex justify-between items-center rounded-b-lg ${
                    data.status === 1
                      ? "bg-blue-600"
                      : data.status === 0
                      ? "bg-blue-200"
                      : data.status === 2
                      ? "bg-blue-600"
                      : ""
                  } p-2`}
                >
                  <span className="font-bold text-white">
                    {data.appointmentDate}
                  </span>

                  {data.status === 2 || data.status === 0 ? (
                    <>
                      {data.serviceCode === "APPT_VIDEO" ? (
                        <div className="w-10 h-10 bg-gray-300 cursor-not-allowed rounded-full flex items-center justify-center">
                          <IoMdVideocam className="w-6 h-6 text-blue-600" />
                        </div>
                      ) : (
                        <div className="w-10 h-10 bg-gray-300 cursor-not-allowed rounded-full flex items-center justify-center">
                          <IoMdVideocam className="w-6 h-6 text-blue-600" />
                        </div>
                      )}
                    </>
                  ) : !data.transactionId &&
                    data.paymentConsultation === "BC" ? (
                    <Tooltip
                      placement="top"
                      title={"payment is not completed on the patient side"}
                    >
                      <>
                        {data.serviceCode === "APPT_VIDEO" ? (
                          <div className="w-10 h-10 bg-gray-300 cursor-not-allowed rounded-full flex items-center justify-center">
                            <IoMdVideocam className="w-6 h-6 text-blue-600" />
                          </div>
                        ) : (
                          <div className="w-10 h-10 bg-gray-300 cursor-not-allowed rounded-full flex items-center justify-center">
                            <IoPersonSharp className="w-6 h-6 text-blue-600" />
                          </div>
                        )}
                      </>
                    </Tooltip>
                  ) : (
                    <>
                      {" "}
                      {data.serviceCode === "APPT_VIDEO" ? (
                        <div
                          onClick={() => handleClick(data, "1")}
                          className="w-10 h-10 bg-white cursor-pointer rounded-full flex items-center justify-center"
                        >
                          <IoMdVideocam className="w-6 h-6 text-blue-600" />
                        </div>
                      ) : (
                        <div
                          onClick={() => handleClick(data, "1")}
                          className="w-10 h-10 bg-white cursor-pointer rounded-full flex items-center justify-center"
                        >
                          <IoPersonSharp className="w-6 h-6 text-blue-600" />
                        </div>
                      )}
                    </>
                  )}

                  <span className="font-bold text-white">
                    {formatTime(data.startTime)}
                  </span>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="text-center font-bold my-40">No Appointments!</div>
        )}
      </div>
      <Modal
        open={preview}
        onOk={handlePreviewOk}
        onCancel={handlePreviewCancel}
        style={{
          top: 40,
        }}
        width={"70%"}
        footer={null}
      >
        <PrescriptionDetails
          prescription={selectedPrescription}
          selectedPatient={selectedPatient}
        />
      </Modal>
      <Modal
        title="Cancel Appointment"
        open={isModalOpen}
        onCancel={cancelAppointment}
        footer={[]}
      >
        Do you want to cancel this appointment
        <div className="flex justify-end border-brand-colorBorder gap-3 mt-10">
          <Button className="h-10 Btnwhite px-8" onClick={cancelAppointment}>
            Cancel
          </Button>
          <Button onClick={handleOk} className="h-10 px-10" type="primary">
            Ok
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default AppointmentCard;
