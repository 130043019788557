import dayjs from "dayjs";
import "dayjs/plugin/utc";
import enrollmentServices from "../../Services/enrollmentServices";
import moment from "moment";
import { Base64 } from "js-base64";
import { Buffer } from "buffer";
import sha256 from "crypto-js/sha256";
import PaymentService from "../../Services/PaymentService";
import { message } from "antd";
import axios from "axios";
import AppointmentService from "../../Services/AppointmentService";
dayjs.extend(require("dayjs/plugin/utc"));

class commonFunctions {
  convertUTCDate(date, format) {
    if (!format) {
      var d = new Date();
      date = new Date(
        dayjs(date, "MM-DD-YYYY").format("MM/DD/YYYY") +
          " " +
          d.getHours() +
          ":" +
          d.getMinutes()
      );
    }
    format = format || "MM/DD/YYYY";
    return dayjs(date).utc().format(format);
  }

  ConvertTime(time) {
    const timePart = time.format("HH:mm");
    return `${timePart}`;
  }

  convertDate = (date) => {
    const datePart = dayjs(date, "MM-DD-YYYY").format("MM/DD/YYYY");
    const time = `${datePart}`;
    return time;
  };

  async NewPatientPayment(data) {
    try {
      const result = await enrollmentServices.Invite(data);

      if (!result.data) {
        message.error("Registration Failed");
        return;
      }

      const convertUTCDate = (date, format = "MM/DD/YYYY") => {
        const d = new Date();
        date = new Date(
          moment(date, "MM-DD-YYYY").format("MM/DD/YYYY") +
            " " +
            d.getHours() +
            ":" +
            d.getMinutes()
        );
        return moment(new Date(date)).utc().format(format);
      };

      const storedLoginResult = localStorage.getItem("login_result");
      const loginResultObject = storedLoginResult
        ? JSON.parse(storedLoginResult)
        : null;

      if (result.data.code === "FAILED") {
        message.error(result.data.message);
        return;
      }

      if (result.data.code === "SUCCESS") {
        let date = moment(new Date()).format("YYYYMMDDHHmmssSSS");
        let transactionId = "MT" + date;
        let appointmentDetails =
          transactionId +
          "|" +
          result.data?.responseDetails.patientId +
          "|" +
          loginResultObject.user.userId +
          "|" +
          result.data?.responseDetails.appointmentId +
          "|" +
          loginResultObject.user.account_Id +
          "|" +
          loginResultObject.user.clinic_Id;
        let base64string = Base64.encode(appointmentDetails);
        let paymentPayload = {
          merchantId: "DOXZONUAT",
          merchantTransactionId: transactionId,
          merchantUserId: "MUID123",
          amount: parseFloat(result.data.responseDetails?.totalAmount) * 100,
          redirectUrl: `${process.env.REACT_APP_BASEURL}phonepepayment/${base64string}`,
          redirectMode: "POST",
          callbackUrl: `${process.env.REACT_APP_API_BASEURL}phonePeResponse1/paymentCallBackDetails`,
          mobileNumber: result.data.responseDetails?.mobile,
          paymentInstrument: {
            type: "PAY_PAGE",
          },
        };

        const SALT_KEY = "58d4bcb6-f046-4e58-8c62-66505c0e32d9";
        const PHONE_PE_HOST_URL =
          "https://api-preprod.phonepe.com/apis/pg-sandbox/pg/v1/pay";
        let bufferObj = Buffer.from(JSON.stringify(paymentPayload), "utf8");
        let base64EncodedPayload = bufferObj.toString("base64");
        let string = base64EncodedPayload + "/pg/v1/pay" + SALT_KEY;
        let sha256_val = sha256(string);
        let xVerifyChecksum = sha256_val + "###" + 1;

        let phonepeTransaction = {
          account_Id: loginResultObject.user?.account_Id,
          checksum: xVerifyChecksum,
          clinic_id: loginResultObject.user?.clinic_Id,
          createdBy: loginResultObject.user.userId,
          createdDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
          modifiedDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
          paidDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
          modifiedBy: loginResultObject.user.userId,
          patientId: result.data.responseDetails?.patientId,
          enoCodePayload: base64EncodedPayload,
          providerreferenceId: "",
          serviceCode: `${
            result.data.responseDetails?.patientRegFeeYN == "Y"
              ? "APPT, REG_FEE"
              : "APPT"
          }`,
          serviceName: `${
            result.data.responseDetails?.patientRegFeeYN == "Y"
              ? "Appointment Consultation, Patient Registration Fee"
              : "Appointment Consultation"
          }`,
          status: 1,
          serviceTransId: result.data?.responseDetails.appointmentId,
          transactionId: transactionId,
          userId: loginResultObject.user.userId,
          amount: parseFloat(result.data.responseDetails?.totalAmount),
        };

        const res = await PaymentService.PhonepeTransaction(phonepeTransaction);

        if (res.data === 1) {
          let appointmentId = result.data.responseDetails?.appointmentId;
          const appointmentRes = await AppointmentService.GetAppointmentId(
            appointmentId
          );

          let appointmentUpdate = {
            appointmentId: result.data.responseDetails?.appointmentId,
            email: result.data.responseDetails?.email,
            meetingLink: result.data.responseDetails?.meetingLink,
            mobile: result.data.responseDetails?.mobile,
            id: appointmentRes.data[0].id,
            patientId: result.data.responseDetails?.patientId,
            status: 1,
            paymentReceived: "Y",
            userId: loginResultObject.user?.userId,
            createdBy: loginResultObject.user?.userId,
            modifiedBy: loginResultObject.user?.userId,
          };

          const updateRes = await AppointmentService.AppointmentUpdate(
            appointmentUpdate
          );

          let billingTransaction = {
            account_Id: loginResultObject.user?.account_Id,
            amount: result.data.responseDetails?.totalAmount,
            clinic_id: loginResultObject.user?.clinic_Id,
            createdBy: loginResultObject.user.userId,
            createdDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
            discountAmount: 0,
            discountPercentage: 0,
            gst: 0,
            modifiedBy: loginResultObject.user.userId,
            modifiedDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
            paidAmount: result.data.responseDetails?.totalAmount,
            patientId: result.data.responseDetails?.patientId,
            paymentDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
            paymode: "PhonePe",
            serviceCode: "",
            serviceCost: result.data.responseDetails?.totalAmount,
            serviceName: "",
            serviceTransId: result.data.responseDetails?.appointmentId,
            status: 0,
            transactionId: transactionId,
            userId: loginResultObject.user.userId,
            billingTransactionServices: [],
          };

          let data1 = JSON.parse(data);

          console.log("data", data1);
          let array1 = {
            account_Id: loginResultObject.user?.account_Id,
            amount: data1.consultationFee,
            clinic_id: loginResultObject.user?.clinic_Id,
            createdBy: loginResultObject.user.userId,
            createdDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
            fromDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
            modifiedBy: loginResultObject.user.userId,
            modifiedDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
            paidAmount: data1.consultationFee,
            patientId: result.data.responseDetails?.patientId,
            paymentDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
            paymode: "PhonePe",
            serviceCode: "APPT",
            serviceCost: data1.consultationFee,
            serviceName: "Appointment Consultation",
            serviceTransId: result.data.responseDetails?.appointmentId,
            status: 0,
            toDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
            transactionId: transactionId,
            userId: loginResultObject.user.userId,
          };
          billingTransaction.billingTransactionServices.push(array1);

          if (data1.patientRegFeeYN == "Y") {
            let array2 = {
              account_Id: loginResultObject.user?.account_Id,
              amount: data1.patientRegFee,
              clinic_id: loginResultObject.user?.clinic_Id,
              createdBy: loginResultObject.user.userId,
              createdDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
              fromDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
              modifiedBy: loginResultObject.user.userId,
              modifiedDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
              paidAmount: data1.patientRegFee,
              patientId: result.data.responseDetails?.patientId,
              paymentDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
              paymode: "PhonePe",
              serviceCode: "REG_FEE",
              serviceCost: data1.patientRegFee,
              serviceName: "Patient Registration Fee",
              serviceTransId: result.data.responseDetails?.appointmentId,
              status: 0,
              toDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
              transactionId: transactionId,
              userId: loginResultObject.user.userId,
            };
            billingTransaction.billingTransactionServices.push(array2);
          }

          console.log("billingTransaction", billingTransaction);
          // return;

          const billingRes = await PaymentService.BillingTransaction(
            billingTransaction
          );

          if (billingRes.data === 1) {
            const configurationObject = {
              method: "POST",
              url: PHONE_PE_HOST_URL,
              data: { request: base64EncodedPayload },
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-VERIFY": xVerifyChecksum,
              },
            };
            const paymentRes = await axios(configurationObject);
            window.location.replace(
              paymentRes.data.data.instrumentResponse.redirectInfo.url
            );
          }
        }
      }
    } catch (error) {
      console.error("An error occurred:", error);
      message.error("This patient already exists");
    }
  }

  async ExisTingPatientPayment(data) {
    try {
      const result = await enrollmentServices.Invite(data);

      if (!result.data) {
        message.error("Registration Failed, Retry");
        return;
      }

      const convertUTCDate = (date, format = "MM/DD/YYYY") => {
        const d = new Date();
        date = new Date(
          moment(date, "MM-DD-YYYY").format("MM/DD/YYYY") +
            " " +
            d.getHours() +
            ":" +
            d.getMinutes()
        );
        return moment(new Date(date)).utc().format(format);
      };

      const storedLoginResult = localStorage.getItem("login_result");
      const loginResultObject = storedLoginResult
        ? JSON.parse(storedLoginResult)
        : null;

      if (result.data.code === "SUCCESS") {
        let date = moment(new Date()).format("YYYYMMDDHHmmssSSS");
        let transactionId = "MT" + date;
        let appointmentDetails =
          transactionId +
          "|" +
          result.data?.responseDetails.patientId +
          "|" +
          loginResultObject.user.userId +
          "|" +
          result.data?.responseDetails.appointmentId +
          "|" +
          loginResultObject.user.account_Id +
          "|" +
          loginResultObject.user.clinic_Id;
        let base64string = Base64.encode(appointmentDetails);
        let paymentPayload = {
          merchantId: "DOXZONUAT",
          merchantTransactionId: transactionId,
          merchantUserId: "MUID123",
          amount: parseFloat(result.data.responseDetails?.totalAmount) * 100,
          redirectUrl: `${process.env.REACT_APP_BASEURL}phonepepayment/${base64string}`,
          redirectMode: "POST",
          callbackUrl: `${process.env.REACT_APP_API_BASEURL}phonePeResponse1/paymentCallBackDetails`,
          mobileNumber: result.data.responseDetails?.mobile,
          paymentInstrument: {
            type: "PAY_PAGE",
          },
        };

        const SALT_KEY = "58d4bcb6-f046-4e58-8c62-66505c0e32d9";
        const PHONE_PE_HOST_URL =
          "https://api-preprod.phonepe.com/apis/pg-sandbox/pg/v1/pay";
        let bufferObj = Buffer.from(JSON.stringify(paymentPayload), "utf8");
        let base64EncodedPayload = bufferObj.toString("base64");
        let string = base64EncodedPayload + "/pg/v1/pay" + SALT_KEY;
        let sha256_val = sha256(string);
        let xVerifyChecksum = sha256_val + "###" + 1;

        let phonepeTransaction = {
          account_Id: loginResultObject.user?.account_Id,
          checksum: xVerifyChecksum,
          clinic_id: loginResultObject.user?.clinic_Id,
          createdBy: loginResultObject.user.userId,
          createdDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
          modifiedDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
          paidDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
          modifiedBy: loginResultObject.user.userId,
          patientId: result.data.responseDetails?.patientId,
          enoCodePayload: base64EncodedPayload,
          providerreferenceId: "",
          serviceCode: "APPT",
          serviceName: "Appointment Consultation",
          status: 1,
          serviceTransId: result.data?.responseDetails.appointmentId,
          transactionId: transactionId,
          userId: loginResultObject.user.userId,
          amount: result.data.responseDetails?.totalAmount,
        };

        const res = await PaymentService.PhonepeTransaction(phonepeTransaction);

        if (res.data === 1) {
          let appointmentId = result.data.responseDetails?.appointmentId;
          const appointmentRes = await AppointmentService.GetAppointmentId(
            appointmentId
          );

          let appointmentUpdate = {
            appointmentId: result.data.responseDetails?.appointmentId,
            email: result.data.responseDetails?.email,
            meetingLink: result.data.responseDetails?.meetingLink,
            mobile: result.data.responseDetails?.mobile,
            id: appointmentRes.data[0].id,
            patientId: result.data.responseDetails?.patientId,
            status: 1,
            userId: loginResultObject.user?.userId,
            paymentReceived: "Y",
            createdBy: loginResultObject.user?.userId,
            modifiedBy: loginResultObject.user?.userId,
          };

          const updateRes = await AppointmentService.AppointmentUpdate(
            appointmentUpdate
          );

          let billingTransaction = {
            account_Id: loginResultObject.user?.account_Id,
            amount: result.data.responseDetails?.totalAmount,
            clinic_id: loginResultObject.user?.clinic_Id,
            createdBy: loginResultObject.user.userId,
            createdDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
            discountAmount: 0,
            discountPercentage: 0,
            gst: 0,
            modifiedBy: loginResultObject.user.userId,
            modifiedDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
            paidAmount: result.data.responseDetails?.totalAmount,
            patientId: result.data.responseDetails?.patientId,
            paymentDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
            paymode: "PhonePe",
            serviceCode: "",
            serviceCost: result.data.responseDetails?.totalAmount,
            serviceName: "",
            serviceTransId: result.data.responseDetails?.appointmentId,
            status: 0,
            transactionId: transactionId,
            userId: loginResultObject.user.userId,
            billingTransactionServices: [],
          };

          // let data1 = JSON.parse(data);

          console.log("data", data);
          let array1 = {
            account_Id: loginResultObject.user?.account_Id,
            amount: data.consultationFee,
            clinic_id: loginResultObject.user?.clinic_Id,
            createdBy: loginResultObject.user.userId,
            createdDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
            fromDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
            modifiedBy: loginResultObject.user.userId,
            modifiedDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
            paidAmount: data.consultationFee,
            patientId: result.data.responseDetails?.patientId,
            paymentDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
            paymode: "PhonePe",
            serviceCode: "APPT",
            serviceCost: data.consultationFee,
            serviceName: "Appointment Consultation",
            serviceTransId: result.data.responseDetails?.appointmentId,
            status: 0,
            toDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
            transactionId: transactionId,
            userId: loginResultObject.user.userId,
          };
          billingTransaction.billingTransactionServices.push(array1);

          const billingRes = await PaymentService.BillingTransaction(
            billingTransaction
          );

          if (billingRes.data === 1) {
            const configurationObject = {
              method: "POST",
              url: PHONE_PE_HOST_URL,
              data: { request: base64EncodedPayload },
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-VERIFY": xVerifyChecksum,
              },
            };
            const paymentRes = await axios(configurationObject);
            window.location.replace(
              paymentRes.data.data.instrumentResponse.redirectInfo.url
            );
          }
        }
      }
    } catch (error) {
      console.error("An error occurred:", error);
      message.error(
        "An error occurred while sending invite. Please try again."
      );
    }
  }
}

export default new commonFunctions();
