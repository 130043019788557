import React, { useEffect, useState } from "react";
import Layouts from "../../Layouts";
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Pagination,
  Row,
  Space,
  Table,
} from "antd";
import { FaFilter } from "react-icons/fa";
import OrderService from "../../../Services/OrderService";
import { useForm } from "antd/es/form/Form";

function OrderReport() {
  const [OrderList, setOrderList] = useState([]);
  const [pageNum, setPageNum] = useState("0");
  const [pageCount, setPageCount] = useState();

  const [apiPayload, setApiPayload] = useState({});
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const storedLoginResult = localStorage.getItem("login_result");
  const loginResultObject = storedLoginResult
    ? JSON.parse(storedLoginResult)
    : null;

  const formatDate = (date) => {
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth();
  const firstDate = new Date(year, month, 1);
  const lastDate = new Date(year, month + 1, 0);

  const getOrderList = async () => {
    setFromDate(formatDate(firstDate));
    setToDate(formatDate(lastDate));

    let payload = {
      userId: loginResultObject.user.userId,
      status: 1,
      fromDate: formatDate(firstDate),
      toDate: formatDate(lastDate),
      pageNumber: "0",
    };

    console.log("payload", payload);
    try {
      let result = await OrderService.GetOrderList(payload);
      setOrderList(result.data);
      setApiPayload(payload);
    } catch (error) {
      console.log(error);
    }

    let payload2 = {
      userId: loginResultObject.user.userId,
      status: 1,
      fromDate: formatDate(firstDate),
      toDate: formatDate(lastDate),
      // pageNumber: pageNum,
    };

    OrderService.GetOrderListCount(payload2)
      .then((res) => {
        console.log("res", res);
        setPageCount(res.data.TOTAL_COUNT_STATUS);
      })
      .catch((error) => {
        console.error("Error fetching billing records:", error);
      });
  };

  useEffect(() => {
    getOrderList();
  }, []);

  const columns = [
    {
      title: "Patient Name",
      dataIndex: "patientName",
      render: (_, record) => (
        <Space size="middle" className="">
          <div>{record.patientName}</div>
        </Space>
      ),
    },
    {
      title: "Patient ID",
      dataIndex: "patientId",
    },
    {
      title: "Appointment Id",
      dataIndex: "serviceTransId",
    },
    {
      title: "Transaction ID",
      dataIndex: "transactionId",
    },
    {
      title: "Paid Date",
      dataIndex: "paymentDate",
      // render: (_, record) => convertUTCDateLocal(record.paymentDate),
    },
    {
      title: "Type of Service",
      dataIndex: "type",
      render: (_, record) => (
        <Space size="middle" className="">
          <div className="text-black px-2 rounded py-1">Video</div>
        </Space>
      ),
    },
    {
      title: "Mode Of Payment",
      dataIndex: "paymode",
    },
    // {
    //   title: "Amount",
    //   dataIndex: "amount",
    //   render: (_, record) => (
    //     <Space size="middle" className="">
    //       <div className="text-black px-2 rounded py-1">{record.amount}</div>
    //     </Space>
    //   ),
    // },
    {
      title: "Discount Amount",
      dataIndex: "discountAmount",
      render: (_, record) => (
        <Space size="middle" className="">
          <div className="text-black px-2 rounded py-1">
            {record.discountAmount}
          </div>
        </Space>
      ),
    },
    {
      title: "Consultation Amount",
      dataIndex: "amount",
      render: (_, record) => (
        <Space size="middle" className="">
          <div className="text-black px-2 rounded py-1">{record.amount}</div>
        </Space>
      ),
    },
    {
      title: "Medicine Amount",
      dataIndex: "drugAmount",
      render: (_, record) => (
        <Space size="middle" className="">
          <div className="text-black px-2 rounded py-1">
            {record.drugAmount}
          </div>
        </Space>
      ),
    },
    {
      title: "Pharmacy Name",
      dataIndex: "pharmacyName",
      render: (_, record) => (
        <Space size="middle" className="">
          <div className="text-black px-2 rounded py-1">
            {record.pharmacyName}
          </div>
        </Space>
      ),
    },
    {
      title: "Lab Amount",
      dataIndex: "labAmount",
      render: (_, record) => (
        <Space size="middle" className="">
          <div className="text-black px-2 rounded py-1">{record.labAmount}</div>
        </Space>
      ),
    },
    {
      title: "Lab Name",
      dataIndex: "labName",
      render: (_, record) => (
        <Space size="middle" className="">
          <div className="text-black px-2 rounded py-1">{record.labName}</div>
        </Space>
      ),
    },
  ];

  const handlePageChange = async (pageNumber) => {
    apiPayload.pageNumber = `${pageNumber - 1}`;

    try {
      let result = await OrderService.GetOrderList(apiPayload);
      setOrderList(result.data);
      console.log("te", result.data);
    } catch (error) {
      console.log(error);
    }
  };

  console.log("test");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form, setForm] = useForm();

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  const handleOrderFilter = async (payload) => {
    console.log(payload);

    payload.userId = loginResultObject.user.userId;
    payload.status = 1;
    payload.fromDate = formatDate(firstDate);
    payload.toDate = formatDate(lastDate);
    payload.pageNumber = "0";

    try {
      let result = await OrderService.GetOrderList(payload);
      setOrderList(result.data);
    } catch (error) {
      console.log(error);
    }
    payload.pageNumber = null;
    OrderService.GetOrderListCount(payload)
      .then((res) => {
        console.log("res", res);
        setPageCount(res.data.TOTAL_COUNT_STATUS);
        handleCancel();
      })
      .catch((error) => {
        console.error("Error fetching billing records:", error);
      });
    setApiPayload(payload);
  };

  return (
    <Layouts>
      <Modal
        title="Filter"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={[]}
      >
        <Form
          className="formDesign"
          name="trigger"
          layout="vertical"
          form={form}
          onFinish={handleOrderFilter}
        >
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item label="Patient Name" name="patientName">
                <Input className="w-full" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Patient ID" name="patientId">
                <Input className="w-full" />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label="Appointment Id" name="serviceTransId">
                <Input className="w-full" />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <div className="flex justify-end  border-brand-colorBorder gap-3 pt-2">
              <Button className="h-10 Btnwhite px-8" onClick={handleCancel}>
                Cancel
              </Button>
              <Button className="h-10 px-10" type="primary" htmlType="submit">
                Filter
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>

      <div className="flex justify-between items-center">
        <div className="w-[40%] cursor-pointer font-bold">Order Report</div>
        <div className="flex ml-4 items-center justify-end">
          <button
            onClick={() => {
              setIsModalOpen(true);
            }}
            className="text-white font-semibold p-2 rounded-md flex items-center "
          >
            <FaFilter className="mr-1" />
            Filter
          </button>
        </div>
      </div>
      <div className="mt-10 mb-10  rounded-lg h-full">
        <div className="p-1">
          <Table
            className="p-0"
            columns={columns}
            dataSource={OrderList}
            pagination={false}
            scroll={{ x: "max-content" }}
          />
          <div className="flex justify-end items-end my-4">
            <Pagination
              defaultCurrent={1}
              total={pageCount}
              onChange={handlePageChange}
              showSizeChanger={false}
            />
          </div>
        </div>
      </div>
    </Layouts>
  );
}

export default OrderReport;
