import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { APP_ROUTES } from "./approutes";
import LoginForm from "../Components/Authentication/Login";
import ForgotPassword from "../Components/Authentication/ForgotPassword";
import Dashboard from "../Components/Pages/Dashboard";
import Appointments from "../Components/Pages/Appointments";
import Payments from "../Components/Pages/Payments";
import Patients from "../Components/Pages/Patients";
import Settings from "../Components/Pages/Settings";
import ConsultationNotes from "../Components/Pages/ConsultationNotes/index";
import EPrescription from "../Components/Pages/ConsultationNotes/EPrescription";
import ShareCall from "../Components/Pages/ConsultationNotes/VideoConsultation/ShareCall";
import SharePayment from "../Components/Pages/Payments/SharePayment";
import VCPage from "../Components/Pages/VCPage";
import MedicalRecords from "../Components/Pages/ConsultationNotes/VitalTabs/MedicalRecords";
import ViewMedicalRecords from "../Components/Pages/VCPage/ViewMedicalRecords";
import BillingReports from "../Components/Pages/BillingReports";
import SharePrescription from "../Components/Pages/UpcomingAppointments/SharePrescription";
import Videocall from "../Components/Pages/videocall";
import PaymentInitiation from "../Components/Pages/PaymentInitiation";
import MedPharma from "../Components/Pages/PharmaMed/MedPharma";
import LabPharma from "../Components/Pages/PharmaMed/LabPharma";
import OrderReport from "../Components/Pages/OrderReport";
import AutoLogin from "../Components/Authentication/AutoLogin";
import MobileLogin from "../Components/Authentication/MobileLogin";
import DirectLink from "../Components/Pages/PharmaMed/DirectLink";

const PageRouting = () => {
  return (
    <Router>
      <Switch>
        <Route exact path={APP_ROUTES.LOGINFORM} component={LoginForm} />
        <Route exact path={APP_ROUTES.AUTHENTICATE} component={AutoLogin} />
        <Route exact path={APP_ROUTES.SHARECALL} component={ShareCall} />
        <Route exact path={APP_ROUTES.SHAREPAYMENTS} component={SharePayment} />

        <Route path={APP_ROUTES.FORGETPASSWORD} component={ForgotPassword} />
        <Route path={APP_ROUTES.DASHBOARD} component={Dashboard} />
        <Route path={APP_ROUTES.APPOINTMENTS} component={Appointments} />
        <Route path={APP_ROUTES.PATIENTS} component={Patients} />
        <Route path={APP_ROUTES.PAYMENTS} component={Payments} />
        <Route path={APP_ROUTES.SETTINGS} component={Settings} />
        <Route path={APP_ROUTES.EPRESCRIPTION} component={EPrescription} />
        <Route path={APP_ROUTES.VCPAGE} component={VCPage} />
        <Route path={APP_ROUTES.MEDICALRECORDS} component={MedicalRecords} />
        <Route
          path={APP_ROUTES.VIEWMEDICALRECORDS}
          component={ViewMedicalRecords}
        />
        <Route path={APP_ROUTES.BILLINGREPORTS} component={BillingReports} />
        <Route
          path={APP_ROUTES.SHAREPRESCRIPTION}
          component={SharePrescription}
        />

        <Route
          path={APP_ROUTES.CONSULTATIONNOTES}
          component={ConsultationNotes}
        />
        <Route path={APP_ROUTES.VIDEOCALL} component={Videocall} />
        <Route path={APP_ROUTES.DIRECTLINK} component={DirectLink} />
        <Route path={APP_ROUTES.MOBILELOGIN} component={MobileLogin} />
        <Route path={APP_ROUTES.PHONEPEPAYMENT} component={PaymentInitiation} />
        <Route path={APP_ROUTES.MEDPHARMA} component={MedPharma} />
        <Route path={APP_ROUTES.LABPHARMA} component={LabPharma} />
        <Route path={APP_ROUTES.ORDERREPORT} component={OrderReport} />
        <Redirect from="/" to={APP_ROUTES.LOGINFORM} />
      </Switch>
    </Router>
  );
};

export { PageRouting };
