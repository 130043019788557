import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { message } from "antd";
import { generateAesKey } from "../../../utils/passwordEncrypt";
import AuthService from "../../../Services/AuthService";
import CommonFunctions from "../../Shared/CommonFunctions";
import { useHistory } from "react-router-dom";
import { APP_ROUTES } from "../../../PageRouting/approutes";
import Layouts from "../../Layouts";

function Settings() {
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const history = useHistory();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const togglePasswordVisibility1 = () => {
    setShowPassword1(!showPassword1);
  };
  const togglePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2);
  };


  const handleCancelChanges = () => {
    setCurrentPassword("");
    setNewPassword("");
    setConfirmNewPassword("");
  };
  const handleSaveChanges = () => {
    if (!currentPassword || !newPassword || !confirmNewPassword) {
      message.error("Enter valid credentials to change the password");
      return;
    }
    if (newPassword !== confirmNewPassword) {
      message.error("New Password and Confirm Password do not match");
      return;
    }
    if (newPassword.length < 8 || newPassword.length > 16) {
      message.error("Password must be between 8 and 16 characters");
      return;
    }
    if (!/[0-9]/.test(newPassword)) {
      message.error("Password must include at least one digit (0-9)");
      return;
    }
    if (!/[a-z]/.test(newPassword)) {
      message.error(
        "Password must include at least one lowercase letter (a-z)"
      );
      return;
    }
    if (!/[A-Z]/.test(newPassword)) {
      message.error(
        "Password must include at least one uppercase letter (A-Z)"
      );
      return;
    }
    if (!/[!@#$%^&+=]/.test(newPassword)) {
      message.error(
        "Password must include at least one special character (!@#$%^&+=)"
      );
      return;
    }

    const encryptedCurrentPassword = generateAesKey(currentPassword);
    const encryptedNewPassword = generateAesKey(newPassword);

    const storedLoginResult = localStorage.getItem("login_result");
    const loginResultObject = storedLoginResult
      ? JSON.parse(storedLoginResult)
      : null;

    if (!loginResultObject) {
      message.error("User not found");
      return;
    }

    const data = {
      createdBy: loginResultObject.user.userId,
      createdDate: CommonFunctions.convertUTCDate(
        new Date(),
        "YYYY-MM-DD HH:mm:ss"
      ),
      modifiedBy: loginResultObject.user.userId,
      modifiedDate: CommonFunctions.convertUTCDate(
        new Date(),
        "YYYY-MM-DD HH:mm:ss"
      ),
      email: loginResultObject.user.email,
      mobile: loginResultObject.user.mobile,
      currentVal: encryptedCurrentPassword,
      newVal: encryptedNewPassword,
      status: 1,
      userCode: loginResultObject.user.userId,
    };

    AuthService.changePassword(data)
      .then((res) => {
        if (res.data.error) {
          message.error("Current Password is Wrong");
        } else {
          history.push(APP_ROUTES.DASHBOARD);
          message.success("Password Changed Successfully");
        }
      })
      .catch((error) => {
        console.error("Error changing password:", error);
        message.error("Failed to change password");
      });
  };

  return (
    <Layouts>
      <div className="w-full">
        <div className="flex flex-col justify-center items-center  ">
          <div className="relative mt-16 flex md:w-[460px] w-full flex-col  rounded-xl bg-white bg-clip-border text-gray-700 shadow-md">
            <div className="relative mx-4 -mt-6 mb-4 grid h-16 place-items-center overflow-hidden rounded-xl bg-gradient-to-tr from-cyan-600 to-cyan-400 bg-clip-border text-white shadow-lg shadow-cyan-500/40">
              <h3 className="block font-sans text-xl font-semibold leading-snug tracking-normal text-white antialiased">
                Change Password
              </h3>
            </div>
            <div className="flex flex-col gap-6 p-6">
              <div className="relative h-11 w-full min-w-[200px]">
                <input
                  value={currentPassword}
                  type={showPassword2 ? "text" : "password"}
                  autoComplete="off"
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  placeholder=""
                  className="peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-cyan-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                />
                <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-cyan-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-cyan-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-cyan-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                  Current Password
                </label>
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                  <span className="" onClick={togglePasswordVisibility2}>
                    {showPassword2 ? (
                      <FaEye className="text-gray-500" />
                    ) : (
                      <FaEyeSlash className="text-gray-500" />
                    )}
                  </span>
                </div>
              </div>

              <div className="relative h-11 w-full min-w-[200px] focus-within:show">
                <input
                  type={showPassword1 ? "text" : "password"}
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  placeholder=""
                  autoComplete="off"
                  spellCheck="false"
                  autoCapitalize="none"
                  className="peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-cyan-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                />
                <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-cyan-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-cyan-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-cyan-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                  New Password
                </label>
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                  <span className="" onClick={togglePasswordVisibility1}>
                    {showPassword1 ? (
                      <FaEye className="text-gray-500" />
                    ) : (
                      <FaEyeSlash className="text-gray-500" />
                    )}
                  </span>
                </div>
                <div className="flex gap-3 text-[#1FCCE8] mt-2 opacity-0 max-h-0 transition-all duration-300 peer-focus:opacity-100 peer-focus:max-h-full">
                  <p className="text-sm">8-16 characters</p>
                  <p className="text-sm">1 special character</p>
                  <p className="text-sm">1 uppercase</p>
                  <p className="text-sm">1 numeric</p>
                </div>
              </div>
              <div className="relative h-11 w-full min-w-[200px] mt-5">
                <input
                  type={showPassword ? "text" : "password"}
                  value={confirmNewPassword}
                  onChange={(e) => setConfirmNewPassword(e.target.value)}
                  name="password"
                  id="password"
                  placeholder=""
                  className="peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-cyan-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                  autoComplete="off"
                  spellCheck="false"
                  autoCapitalize="none"
                />
                <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-cyan-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-cyan-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-cyan-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                  Confirm Password
                </label>
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                  <span className="" onClick={togglePasswordVisibility}>
                    {showPassword ? (
                      <FaEye className="text-gray-500" />
                    ) : (
                      <FaEyeSlash className="text-gray-500" />
                    )}
                  </span>
                </div>
              </div>
            </div>
            <div className="p-6 pt-0 flex justify-center gap-5">
              <button
                onClick={handleCancelChanges}
                className="block select-none  border border-cyan-400 rounded-lg bg-white py-3 px-6 text-center align-middle font-sans text-xs font-bold  text-cyan-500 shadow-md shadow-cyan-500/20 transition-all hover:shadow-lg hover:shadow-cyan-500/40 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
              >
                Cancel
              </button>
              <button
                onClick={handleSaveChanges}
                data-ripple-light="true"
                type="button"
                className="block select-none rounded-lg bg-gradient-to-tr from-cyan-600 to-cyan-400 py-3 px-6 text-center align-middle font-sans text-xs font-bold  text-white shadow-md shadow-cyan-500/20 transition-all hover:shadow-lg hover:shadow-cyan-500/40 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </Layouts>
  );
}

export default Settings;
