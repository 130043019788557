import http from "./http-common";

class BillingService {
  BillingRecordsList(payload) {
    const queryString = Object.entries(payload)
      .filter(
        ([_, value]) => value !== undefined && value !== null && value !== ""
      )
      .map(([key, value]) => `${key}=${value}`)
      .join("&");

    return http.get(`/billingTransactionServices/list/filter?${queryString}`);
  }

  BillingRecordsListInvoice(payload) {
    const queryString = Object.entries(payload)
      .filter(
        ([_, value]) => value !== undefined && value !== null && value !== ""
      )
      .map(([key, value]) => `${key}=${value}`)
      .join("&");

    return http.get(`/paymentReceipt/list/filter?${queryString}`);
  }

  BillingCount(payload) {
    const queryString = Object.entries(payload)
      .filter(
        ([_, value]) => value !== undefined && value !== null && value !== ""
      )
      .map(([key, value]) => `${key}=${value}`)
      .join("&");

    return http.get(
      `/billingTransactionServices/getBillingTransactionServicesCountStatus?${queryString}`
    );
  }
}
export default new BillingService();
