import React, { useState } from "react";
import Header from "../Shared/Header";
import Sidemenu from "../Shared/Sidemenu";

const Layouts = ({ children }) => {
  const [isMenuOpen, setMenuOpen] = useState(true);

  const handleSideBarMenu = () => {
    setMenuOpen((prevState) => !prevState);
  };

  return (
    <div className="h-full flex flex-col bg-[#FFFFFF]">
      <Header handleSideBarMenu={handleSideBarMenu} />
      <div className="w-full md:flex ">
        <div
          className={`${
            isMenuOpen ? "w-[20%]" : "w-[5%]"
          } my-1 border-[1px] rounded-lg md:block hidden`}
        >
          <Sidemenu
            isMenuOpen={isMenuOpen}
            handleSideBarMenu={handleSideBarMenu}
          />
        </div>
        <div
          className={`${
            isMenuOpen ? "md:w-[80%] w-full" : "w-[95%]"
          }  mt-20 p-3 overflow-auto rounded scrollWidth bg-[#f6f6f6]`}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default Layouts;
