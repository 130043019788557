// import React, { useEffect, useRef, useState } from "react";
// import Layout from "../../Layouts";
// import { HiMiniArrowTopRightOnSquare } from "react-icons/hi2";
// import { IoShareSocialSharp } from "react-icons/io5";
// import doctor from "../../../Assets/Images/VideoImage.png";
// import Vitals from "./Vitals";
// import ConsultationNotes from "./ConsultationNotes";
// import vitalpng from "../../../Assets/Images/Arrow right.png";
// import ConsultationService from "../../../Services/ConsultationService";
// import { useHistory, useLocation } from "react-router-dom";
// import { useForm } from "antd/es/form/Form";
// import PatientService from "../../../Services/PatientService";
// import { RiNurseFill } from "react-icons/ri";
// import { APP_ROUTES } from "../../../PageRouting/approutes";
// import dayjs from "dayjs";
// import { SlArrowUp } from "react-icons/sl";
// import { IoIosCloseCircle } from "react-icons/io";
// import Draggable from "react-draggable";
// import { FaVideoSlash } from "react-icons/fa6";
// import {
//   Button,
//   Col,
//   Collapse,
//   DatePicker,
//   Form,
//   Row,
//   Input,
//   Modal,
//   Space,
//   Segmented,
//   Tooltip,
//   message,
//   Flex,
//   Spin,
// } from "antd";
// import enrollmentServices from "../../../Services/enrollmentServices";
// import VitalService from "../../../Services/VitalService";
// import PrescriptionDetails from "../ConsultationNotes/VitalTabs/ViewPrescription/PrescriptionDetails";
// import patientqueue from "../../../Assets/Images/person-icon-blue.png";
// import PatientQueue from "../VCPage/PatientQueue";
// import medicalHistoryIcon from "../../../Assets/Images/mdhistory.png";
// import medicalPrescriptionIcon from "../../../Assets/Images/mdpres.png";
// import medicalVitalsIcon from "../../../Assets/Images/mdvitals.png";
// import medicalReportsIcon from "../../../Assets/Images/mdreport.png";
// import NoDataFound from "../../../Assets/Images/Svg/No data-found.svg";
// import MedicalHistory from "../../Pages/ConsultationNotes/VitalTabs/MedicalHistory";
// import MyPresctription from "../../Pages/ConsultationNotes/VitalTabs/ViewPrescription";
// import MyVitals from "../../Pages/ConsultationNotes/VitalTabs/PatientVitals/PatientVitals";
// import Reports from "../../Pages/ConsultationNotes/VitalTabs/Reports";
// import VideoConsultation from "../ConsultationNotes/VideoConsultation";
// import { RiArrowLeftDoubleFill } from "react-icons/ri";
// import { MdKeyboardDoubleArrowUp } from "react-icons/md";

// function Videocall() {
//   const months = {
//     Jan: "01",
//     Feb: "02",
//     Mar: "03",
//     Apr: "04",
//     May: "05",
//     Jun: "06",
//     Jul: "07",
//     Aug: "08",
//     Sep: "09",
//     Oct: "10",
//     Nov: "11",
//     Dec: "12",
//   };

//   const formRef = useRef();
//   const [selectedRadio, setSelectedRadio] = useState("primary");
//   const [diagnosticList, setDiagnosticList] = useState([]);
//   const [currPrimaryDiagList, setCurrPrimaryDiagList] = useState([]);
//   const [currSecondaryDiagList, setCurrSecondaryDiagList] = useState([]);
//   const [selectedPrimaryDiag, setSelectedPrimaryDiag] = useState({});
//   const [selectedSecondaryDiag, setSelectedSecondaryDiag] = useState({});
//   const [selectedPrimaryDiagVal, setSelectedPrimaryDiagVal] = useState("");
//   const [selectedSecondaryDiagVal, setSelectedSecondaryDiagVal] = useState("");
//   const [selectedDiagArr, setSelectedDiagArr] = useState([]);
//   const [selectedOriginalDiagArr, setSelectedOriginalDiagArr] = useState([]);
//   const [selectedCode, setSelectedCode] = useState(null);
//   const [selectedSecondaryCode, setSelectedSecondaryCode] = useState(null);
//   const [secondaryDiag, setSecondaryDiag] = useState([]);
//   const [isEditScreenEnabled, setIsEditScreenEnabled] = useState(false);
//   const [isEndConsLoading, setIsEndConsLoading] = useState(false);
//   const [vitalsObj, setVitalsObj] = useState([]);
//   const [medicinesObj, setMedicinesObj] = useState([]);
//   const [labTestsObj, setLabTestsObj] = useState([]);
//   const [followUpDate, setFollowUpDate] = useState("");
//   const [bmiValue, setBmiValue] = useState();
//   const [height, setHeight] = useState();
//   const [weight, setWeight] = useState();
//   const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
//   const [prescription, setPrescription] = useState(null);
//   const [icdCode, setIcdCode] = useState();
//   const [primdiag, setPrimdiag] = useState();
//   const [tabledata, setTabledata] = useState([]);
//   const [showTable, setShowTable] = useState(false);
//   const [vitalMasterList, setVitalMasterList] = useState([]);
//   const [bloodPressure, setBloodPressure] = useState("");
//   const [isLoading, setIsLoading] = useState(false);
//   const [vitalsArrObj, setVitalsArrObj] = useState([]);
//   const [sys, setSys] = useState("");
//   const [dia, setDia] = useState("");
//   const [key, setKey] = useState(0);
//   const [secKey, setSecKey] = useState(0);
//   const [chiefComplaint, setChiefComplaint] = useState("");
//   const [symptoms, setSymptoms] = useState("");
//   const [shareModal, setShareModal] = useState(false);
//   const [selectedPat, setSelectedPat] = useState(null);
//   const { state } = useLocation();
//   const history = useHistory();
//   const [consulationData, setConsulationData] = useState();
//   const [recommendation, setRecommendation] = useState("");
//   const [videoUrl, setUrl] = useState("");
//   const [selectedPatientDetails, setSelectedPatientDetails] =
//     useState(RiNurseFill);
//   const [age, setAge] = useState(null);
//   const [isDrawerOpen, setIsDrawerOpen] = useState(false);
//   const [isImageVisible, setIsImageVisible] = useState(false);
//   const [queueData, setQueueData] = useState();
//   const [permissionData, setPermissionData] = useState(null);
//   const [selectedOption, setSelectedOption] = useState(null);
//   const [form] = useForm();
//   const location = useLocation(null);
//   let selectedPatient = location?.state?.selectedPatient;
//   console.log("selectedPatient", selectedPatient);

//   useEffect(() => {
//     // if (location?.state?.vitalsArrObjData) {
//     //   setVitalsArrObj(prevState => ({
//     //     ...prevState,
//     //     ...location.state.vitalsArrObjData
//     //   }));
//     // }

//     handleEditScreen();
//   }, []);

//   useEffect(() => {
//     console.log("vitalsArrObj updated: ", vitalsArrObj);
//   }, [vitalsArrObj]);

//   useEffect(() => {
//     if (consulationData?.recommendation) {
//       setRecommendation(consulationData?.recommendation);
//     }
//     if (consulationData?.symptoms) {
//       setSymptoms(consulationData?.symptoms);
//     }
//     if (consulationData?.followupVisitDate) {
//       setFollowUpDate(consulationData?.followupVisitDate);
//     }
//   }, [consulationData]);

//   useEffect(() => {
//     if (consulationData?.labPrescription) {
//       setLabTestsObj(consulationData?.labPrescription);
//     }
//     if (consulationData?.drugPrescription) {
//       setMedicinesObj(consulationData?.drugPrescription);
//     }
//     if (consulationData?.patientVitals) {
//       form.setFieldsValue({
//         BPS: consulationData?.patientVitals
//           ? consulationData?.patientVitals?.find(
//               (item) => item.vitalCode === "BPS"
//             )?.value
//           : "",
//         BPD: consulationData?.patientVitals
//           ? consulationData?.patientVitals?.find(
//               (item) => item.vitalCode === "BPD"
//             )?.value
//           : "",
//         PO: consulationData?.patientVitals
//           ? consulationData?.patientVitals?.find(
//               (item) => item.vitalCode === "PO"
//             )?.value
//           : "",
//         HR: consulationData?.patientVitals
//           ? consulationData?.patientVitals?.find(
//               (item) => item.vitalCode === "HR"
//             )?.value
//           : "",
//         RR: consulationData?.patientVitals
//           ? consulationData?.patientVitals?.find(
//               (item) => item.vitalCode === "RR"
//             )?.value
//           : "",
//         TP: consulationData?.patientVitals
//           ? consulationData?.patientVitals?.find(
//               (item) => item.vitalCode === "TP"
//             )?.value
//           : "",
//         HI: consulationData?.patientVitals
//           ? consulationData?.patientVitals?.find(
//               (item) => item.vitalCode === "HI"
//             )?.value
//           : "",
//         WE: consulationData?.patientVitals
//           ? consulationData?.patientVitals?.find(
//               (item) => item.vitalCode === "WE"
//             )?.value
//           : "",
//         BMI: consulationData?.patientVitals
//           ? consulationData?.patientVitals?.find(
//               (item) => item.vitalCode === "BMI"
//             )?.value
//           : "",
//         symptoms: consulationData?.symptoms ? consulationData?.symptoms : "",
//         recommendation: consulationData?.recommendation
//           ? consulationData?.recommendation
//           : "",
//       });
//       console.log(" edit vit ", consulationData?.patientVitals);
//       // const vitalsObj = {};
//       // consulationData?.patientVitals.forEach((item) => vitalsObj[item.vitalCode]=item.value);
//       // setVitalsArrObj(vitalsObj);
//       setVitalsObj(consulationData?.patientVitals);
//     }
//   }, [consulationData]);

//   useEffect(() => {
//     fetchVitals();
//   }, []);

//   useEffect(() => {
//     let payload = {
//       patientId: selectedPatient.patientId,
//     };
//     PatientService.PatientListFilter(payload)
//       .then((result) => {
//         setSelectedPatientDetails(result.data[0]);
//         const dobData = result.data[0].dob;
//         const dobYear = dobData?.split("/")[2];
//         const currYear = new Date().getFullYear();
//         console.log("age ", Number(dobYear));
//         if (dobYear) {
//           const patientAge = currYear - Number(dobYear);
//           console.log("age ", patientAge);
//           setAge(patientAge);
//         }
//       })
//       .catch((error) => {
//         console.error("Error fetching patient list:", error);
//       });
//   }, [selectedPatient.patientId]);

//   useEffect(() => {
//     if (
//       selectedPatient?.consultationId
//       //  &&
//       // selectedPatient?.callMode === "EditCall"
//     ) {
//       getPrescriptionDetails();
//     }
//   }, []);

//   const showDrawer = () => {
//     let payload = {
//       userId: selectedPatient.userId,
//       queueStatus: "Y",
//     };
//     PatientService.patientQueueList(payload).then((res) => {
//       if (res.data.length) {
//         let validate = res?.data?.filter(
//           (x) => x.appointmentId != selectedPatient?.appointmentId
//         );
//         if (validate && validate.length) {
//           setQueueData(validate);
//           setIsDrawerOpen(true);
//         } else {
//           message.warning("No patient available in queue");
//         }
//       } else {
//         message.warning("No patient available in queue");
//       }
//     });
//   };

//   const onClose = () => {
//     setIsDrawerOpen(false);
//   };

//   const toggleImageVisibility = () => {
//     setIsImageVisible(!isImageVisible);
//   };

//   const handleEditScreen = () => {
//     if (
//       selectedPatient.callMode === "EditCall" ||
//       selectedPatient.serviceCode === "APPT_INPERSON"
//     ) {
//       setIsEditScreenEnabled(true);
//     }
//   };

//   const handleFullScreen = () => {
//     window.open(videoUrl, "_blank").focus();
//   };

//   const showShareModal = (record) => {
//     setShareModal(true);
//     setSelectedPat(record);
//   };

//   const handleShareLink = (value) => {
//     console.log(value);
//     const { enteredVal } = value;
//     var validation =
//       /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|([0-9]{10})+$/;
//     var number = /^\d+$/;
//     let payload = {};
//     setIsLoading(true);
//     if (validation.test(enteredVal)) {
//       if (number.test(enteredVal)) {
//         payload.mobile = enteredVal;
//       } else {
//         payload.email = enteredVal;
//       }
//       payload.patientId = selectedPatient.patientId;
//       payload.userId = selectedPatient.userId;
//       payload.consultationType = selectedPatient.consultationType;
//       payload.appointmentId = selectedPatient.appointmentId;

//       enrollmentServices
//         .InvitationShare(payload)
//         .then((res) => {
//           console.log("res", res.data);
//           message.success("Invitation sent successfully");
//           setShareModal(false);
//           setIsLoading(false);
//           form.resetFields();
//         })
//         .catch((e) => {
//           message.error("An error occurred, Try again");
//           setIsLoading(false);
//         });
//     } else {
//       message.error("Please enter the valid email or phone");
//       setIsLoading(false);
//     }
//   };

//   const getPrescriptionDetails = async () => {
//     let load = {
//       consultationId: selectedPatient.consultationId,
//       detailsYN: "Y",
//     };
//     const consultationResponse = await ConsultationService.getPrescriptions(
//       load
//     );
//     if (consultationResponse?.data?.length) {
//       setConsulationData(consultationResponse?.data[0]);
//       console.log("koiiii", consultationResponse?.data[0]);
//       if (consultationResponse?.data[0].patientDiagnosisDetails.length) {
//         console.log(
//           "lollsss",
//           consultationResponse?.data[0].patientDiagnosisDetails
//         );
//         setSelectedDiagArr(
//           consultationResponse?.data[0].patientDiagnosisDetails
//         );
//         setSelectedOriginalDiagArr(
//           consultationResponse?.data[0].patientDiagnosisDetails
//         );
//         setShowTable(true);
//       }
//     }
//   };

//   const fetchVitals = async () => {
//     let payload = {
//       status: 1,
//     };
//     const res = await VitalService.VitalMasterlist(payload);
//     setVitalMasterList(res.data);
//   };

//   const handleChiefComplaint = (e) => {
//     setChiefComplaint(e.target.value);
//   };

//   const handleSymptoms = (e) => {
//     setSymptoms(e.target.value);
//   };

//   const handleCancel = () => {
//     setIsPreviewModalOpen(false);
//   };

//   const handlePrescription = async (e) => {
//     e.preventDefault();
//     setIsEndConsLoading(true);
//     const currDate = new Date().toISOString();
//     const date = currDate.split("T")[0];
//     const time = currDate.split("T")[1].slice(0, 8);
//     const constructedTime = date + " " + time;
//     const buttonClick = e.target.innerHTML;

//     const patientData = state?.selectedPatient;
//     const userData = JSON.parse(localStorage.getItem("login_result"));
//     const VitalKeys = Object.keys(vitalsArrObj);
//     let diagnosisArr = [];
//     let vitalsArr = [];
//     let medicinesArr = [];
//     let labtestArr = [];
//     console.log("diag ", selectedOriginalDiagArr);
//     // diagnosis payload objects
//     for (let i = 0; i < selectedOriginalDiagArr?.length; i++) {
//       let Diagpayload = {
//         ...selectedOriginalDiagArr[i],
//         account_Id: userData?.user?.account_Id,
//         clinic_Id: userData?.user?.clinic_Id,
//         createdBy: userData?.user.userId,
//         createdDate: constructedTime,
//         diagnosisCode: selectedOriginalDiagArr[i]?.diagnosisCode,
//         diagnosisDescription: selectedOriginalDiagArr[i]?.diagnosisDescription,
//         diagnosisType: selectedOriginalDiagArr[i]?.diagnosisType,
//         modifiedBy: userData?.user.userId,
//         modifiedDate: constructedTime,
//         patientId: patientData?.patientId,
//         status:
//           selectedOriginalDiagArr[i]?.status === 0
//             ? 0
//             : selectedOriginalDiagArr[i]?.status === undefined
//             ? 1
//             : selectedOriginalDiagArr[i]?.status,
//         userId: userData?.user.userId,
//       };
//       if (buttonClick === "Update") {
//         Diagpayload.consultationId = selectedPatient?.consultationId;
//       }
//       diagnosisArr.push(Diagpayload);
//     }

//     // vitals payload objects
//     const vitalDate = new Date().toUTCString();
//     console.log(vitalDate);
//     const dateArr = vitalDate.split(" ");
//     const vitMonth = months[dateArr[2]];
//     const vitDate = dateArr[1];
//     const vitYear = dateArr[3];
//     const constructedDate = vitYear + "-" + vitMonth + "-" + vitDate;
//     const vitTime = dateArr[4];
//     const constructedDateTime = constructedDate + " " + vitTime;

//     // for (let i = 0; i < VitalKeys.length; i++) {
//     //   console.log("formRef.current.getFieldValue(VitalKeys[i]) ",formRef.current.getFieldValue(VitalKeys[i]));
//     //   let payload = {
//     //     createdBy: userData?.user.userId,
//     //     createdDate: constructedDateTime,
//     //     modifiedBy: userData?.user.userId,
//     //     modifiedDate: constructedDateTime,
//     //     patientId: patientData?.patientId,
//     //     recordedDateTime: constructedDateTime,
//     //     status: 1,
//     //     units: VitalKeys[i] === 'systolic' || VitalKeys[i] === 'diastolic' ? 'mmHg' :
//     //       VitalKeys[i] === 'PO' ? '%' : VitalKeys[i] === 'HR' || VitalKeys[i] === 'RR' ? 'Beats/min' :
//     //         VitalKeys[i] === 'TP' ? 'F' : VitalKeys[i] === 'HI' ? 'cm' : VitalKeys[i] === 'WE' ? 'kg' : 'kg/m2',
//     //     value: VitalKeys[i] === 'bmi' ? bmiValue ? bmiValue : consulationData?.patientVitals?.find((item) => item.vitalCode === "BMI").value :
//     //       VitalKeys[i] === 'systolic' ? sys ? sys : consulationData?.patientVitals?.find((item) => item.vitalCode === "BPS").value :
//     //         VitalKeys[i] === 'diastolic' ? dia ? dia : consulationData?.patientVitals?.find((item) => item.vitalCode === "BPD").value :
//     //           VitalKeys[i] === 'PO' ? formRef.current.getFieldValue(VitalKeys[i]) ? formRef.current.getFieldValue(VitalKeys[i]) : consulationData?.patientVitals?.find((item) => item.vitalCode === "PO").value :
//     //             VitalKeys[i] === 'HR' ? formRef.current.getFieldValue(VitalKeys[i]) ? formRef.current.getFieldValue(VitalKeys[i]) : consulationData?.patientVitals?.find((item) => item.vitalCode === "HR").value :
//     //               VitalKeys[i] === 'RR' ? formRef.current.getFieldValue(VitalKeys[i]) ? formRef.current.getFieldValue(VitalKeys[i]) : consulationData?.patientVitals?.find((item) => item.vitalCode === "RR").value :
//     //                 VitalKeys[i] === 'TP' ? formRef.current.getFieldValue(VitalKeys[i]) ? formRef.current.getFieldValue(VitalKeys[i]) : consulationData?.patientVitals?.find((item) => item.vitalCode === "TP").value :
//     //                   VitalKeys[i] === 'Hi' ? formRef.current.getFieldValue(VitalKeys[i]) ? formRef.current.getFieldValue(VitalKeys[i]) : consulationData?.patientVitals?.find((item) => item.vitalCode === "HI").value :
//     //                     VitalKeys[i] === 'WE' ? formRef.current.getFieldValue(VitalKeys[i]) ? formRef.current.getFieldValue(VitalKeys[i]) : consulationData?.patientVitals?.find((item) => item.vitalCode === "WE").value : formRef.current.getFieldValue(VitalKeys[i]),
//     //     vitalCode: VitalKeys[i] === 'systolic' ? 'BPS' : VitalKeys[i] === 'diastolic' ? 'BPD' :
//     //       VitalKeys[i] === 'PO' ? 'PO' : VitalKeys[i] === 'HR' ? 'HR' : VitalKeys[i] === 'RR' ? 'RR' :
//     //         VitalKeys[i] === 'TP' ? 'TP' : VitalKeys[i] === 'HI' ? 'HI' : VitalKeys[i] === 'WE' ? 'WE' : VitalKeys[i] === 'bmi' ? 'BMI' : '',
//     //     vitalName: VitalKeys[i]
//     //   }
//     //   vitalsArr.push(payload);
//     // }

//     if (
//       consulationData &&
//       consulationData?.patientVitals.length !== 0 &&
//       VitalKeys.length !== 0
//     ) {
//       const patientSavedVitals = consulationData?.patientVitals;
//       VitalKeys &&
//         VitalKeys.forEach((vitalItem) => {
//           const idx = patientSavedVitals?.findIndex(
//             (vit) => vit.vitalCode === vitalItem
//           );
//           if (idx !== -1) {
//             patientSavedVitals[idx].value = vitalsArrObj[vitalItem];
//             patientSavedVitals[idx].modifiedDate = constructedDateTime;
//           } else {
//             let vitalPayload = {
//               account_Id: userData?.user?.account_Id,
//               clinic_Id: userData?.user?.clinic_Id,
//               userId: userData?.user?.userId,
//               createdBy: userData?.user.userId,
//               createdDate: constructedDateTime,
//               modifiedBy: userData?.user.userId,
//               modifiedDate: constructedDateTime,
//               patientId: patientData?.patientId,
//               recordedDateTime: constructedDateTime,
//               status: 1,
//               units: vitalMasterList.find(
//                 (vital) => vital.vitalCode === vitalItem
//               )?.units,
//               value: vitalsArrObj[vitalItem],
//               vitalCode: vitalItem,
//               vitalName: vitalMasterList.find(
//                 (vital) => vital.vitalCode === vitalItem
//               )?.vitalName,
//             };
//             patientSavedVitals.push(vitalPayload);
//           }
//         });
//       vitalsArr = patientSavedVitals;
//     } else if (
//       consulationData &&
//       consulationData?.patientVitals.length !== 0 &&
//       VitalKeys.length === 0
//     ) {
//       vitalsArr = consulationData?.patientVitals;
//     } else {
//       for (let i = 0; i < VitalKeys.length; i++) {
//         let vitalPayload = {
//           account_Id: userData?.user?.account_Id,
//           clinic_Id: userData?.user?.clinic_Id,
//           userId: userData?.user?.userId,
//           createdBy: userData?.user.userId,
//           createdDate: constructedDateTime,
//           modifiedBy: userData?.user.userId,
//           modifiedDate: constructedDateTime,
//           patientId: patientData?.patientId,
//           recordedDateTime: constructedDateTime,
//           status: 1,
//           units: vitalMasterList.find(
//             (vital) => vital.vitalCode === VitalKeys[i]
//           )?.units,
//           value:
//             VitalKeys[i] === "BMI"
//               ? vitalsArrObj[VitalKeys[i]]
//                 ? vitalsArrObj[VitalKeys[i]]
//                 : consulationData?.patientVitals
//                 ? consulationData?.patientVitals?.find(
//                     (item) => item.vitalCode === "BMI"
//                   )?.value
//                 : ""
//               : VitalKeys[i] === "BPS"
//               ? vitalsArrObj[VitalKeys[i]]
//                 ? vitalsArrObj[VitalKeys[i]]
//                 : consulationData?.patientVitals
//                 ? consulationData?.patientVitals?.find(
//                     (item) => item.vitalCode === "BPS"
//                   )?.value
//                 : ""
//               : VitalKeys[i] === "BPD"
//               ? vitalsArrObj[VitalKeys[i]]
//                 ? vitalsArrObj[VitalKeys[i]]
//                 : consulationData?.patientVitals
//                 ? consulationData?.patientVitals?.find(
//                     (item) => item.vitalCode === "BPD"
//                   )?.value
//                 : ""
//               : VitalKeys[i] === "PO"
//               ? vitalsArrObj[VitalKeys[i]]
//                 ? Number(vitalsArrObj[VitalKeys[i]])
//                 : consulationData?.patientVitals
//                 ? consulationData?.patientVitals?.find(
//                     (item) => item.vitalCode === "PO"
//                   )?.value
//                 : ""
//               : VitalKeys[i] === "HR"
//               ? vitalsArrObj[VitalKeys[i]]
//                 ? Number(vitalsArrObj[VitalKeys[i]])
//                 : consulationData?.patientVitals
//                 ? consulationData?.patientVitals?.find(
//                     (item) => item.vitalCode === "HR"
//                   )?.value
//                 : ""
//               : VitalKeys[i] === "RR"
//               ? vitalsArrObj[VitalKeys[i]]
//                 ? Number(vitalsArrObj[VitalKeys[i]])
//                 : consulationData?.patientVitals
//                 ? consulationData?.patientVitals?.find(
//                     (item) => item.vitalCode === "RR"
//                   )?.value
//                 : ""
//               : VitalKeys[i] === "TP"
//               ? vitalsArrObj[VitalKeys[i]]
//                 ? Number(vitalsArrObj[VitalKeys[i]])
//                 : consulationData?.patientVitals
//                 ? consulationData?.patientVitals?.find(
//                     (item) => item.vitalCode === "TP"
//                   )?.value
//                 : ""
//               : VitalKeys[i] === "HI"
//               ? vitalsArrObj[VitalKeys[i]]
//                 ? Number(vitalsArrObj[VitalKeys[i]])
//                 : consulationData?.patientVitals
//                 ? consulationData?.patientVitals?.find(
//                     (item) => item.vitalCode === "HI"
//                   )?.value
//                 : ""
//               : VitalKeys[i] === "WE"
//               ? vitalsArrObj[VitalKeys[i]]
//                 ? Number(vitalsArrObj[VitalKeys[i]])
//                 : consulationData?.patientVitals
//                 ? consulationData?.patientVitals?.find(
//                     (item) => item.vitalCode === "WE"
//                   )?.value
//                 : ""
//               : "",
//           vitalCode: vitalMasterList.find(
//             (vital) => vital.vitalCode === VitalKeys[i]
//           )?.vitalCode,
//           vitalName: vitalMasterList.find(
//             (vital) => vital.vitalCode === VitalKeys[i]
//           )?.vitalName,
//         };
//         if (buttonClick === "Update") {
//           // vitalPayload.consultationId = selectedPatient?.consultationId;
//           // vitalPayload.id = consulationData?.patientVitals?.find(
//           //   (vital) => vital.vitalCode === vitalPayload.vitalCode
//           // )?.id;
//         }
//         vitalsArr.push(vitalPayload);
//         console.log(vitalPayload, "vitalPayload");
//       }
//     }

//     // medicine payload objects
//     for (let i = 0; i < medicinesObj?.length; i++) {
//       const toDate =
//         medicinesObj[i]?.duration * 24 * 60 * 60 * 1000
//           ? new Date(
//               new Date().getTime() +
//                 medicinesObj[i]?.duration * 24 * 60 * 60 * 1000
//             ).toISOString()
//           : new Date().toISOString();
//       const toDays = toDate.split("T")[0];
//       const constructedToDateTime = toDays + " " + time;
//       let medicinePayload = {
//         // ...medicinesObj[i],
//         account_Id: userData?.user?.account_Id,
//         clinic_Id: userData?.user?.clinic_Id,
//         afterBeforeFood:
//           medicinesObj[i]?.timeTaken === "afterfood" ? "AF" : "BF",
//         composition: medicinesObj[i]?.composition,
//         createdBy: userData?.user.userId,
//         createdDate: constructedTime,
//         dosage: medicinesObj[i]?.dosage ? medicinesObj[i]?.dosage : null,
//         drugCode: medicinesObj[i]?.code
//           ? medicinesObj[i]?.code
//           : medicinesObj[i]?.drugCode
//           ? medicinesObj[i]?.drugCode
//           : null,
//         drugName: medicinesObj[i]?.drugName ? medicinesObj[i]?.drugName : null,
//         duration: medicinesObj[i]?.duration ? medicinesObj[i]?.duration : null,
//         form: null,
//         frequency: medicinesObj[i]?.dosage ? medicinesObj[i]?.dosage : null,
//         fromDateTime: constructedTime,
//         givenDate: constructedTime,
//         instruction: medicinesObj[i]?.instruction
//           ? medicinesObj[i]?.instruction
//           : null,
//         medicineType: medicinesObj[i]?.productType
//           ? medicinesObj[i]?.productType
//           : null,
//         modifiedBy: userData?.user.userId,
//         modifiedDate: constructedTime,
//         patientId: patientData?.patientId,
//         quantity: medicinesObj[i]?.quantity,
//         specialAuthority: null,
//         status:
//           medicinesObj[i]?.status === 0
//             ? 0
//             : medicinesObj[i]?.status === undefined
//             ? 1
//             : medicinesObj[i]?.status,
//         toDateTime: constructedToDateTime,
//         userId: userData?.user.userId,
//       };
//       if (selectedPatient?.consultationId) {
//         medicinePayload.consultationId = selectedPatient?.consultationId;
//       }
//       if (buttonClick === "Update") {
//         medicinePayload.consultationId = selectedPatient?.consultationId;
//       }
//       medicinesArr.push(medicinePayload);
//     }

//     //labtest payload objects
//     for (let i = 0; i < labTestsObj?.length; i++) {
//       let labPayload = {
//         ...labTestsObj[i],
//         account_Id: userData?.user?.account_Id,
//         clinic_Id: userData?.user?.clinic_Id,
//         createdBy: userData?.user.userId,
//         createdDate: constructedTime,
//         documentReceivedSource: null,
//         labTestId: labTestsObj[i]?.labTestId ? labTestsObj[i]?.labTestId : null,
//         labTestName: labTestsObj[i]?.labTestName
//           ? labTestsObj[i]?.labTestName
//           : null,
//         modifiedBy: userData?.user.userId,
//         modifiedDate: constructedTime,
//         parentLabTestName: labTestsObj[i]?.parentDescription
//           ? labTestsObj[i]?.parentDescription
//           : null,
//         patientId: patientData?.patientId,
//         status:
//           labTestsObj[i]?.status === 0
//             ? labTestsObj[i]?.status
//             : labTestsObj[i]?.status === undefined
//             ? 1
//             : labTestsObj[i]?.status,
//         userId: userData?.user.userId,
//       };
//       if (selectedPatient?.consultationId) {
//         labPayload.consultationId = selectedPatient?.consultationId;
//       }
//       if (buttonClick === "Update") {
//         labPayload.consultationId = selectedPatient?.consultationId;
//       }
//       labtestArr.push(labPayload);
//     }

//     let payload = {
//       account_Id: userData?.user?.account_Id,
//       clinic_Id: userData?.user?.clinic_Id,
//       consultationDate: constructedTime,
//       consultationReason: patientData?.appointmentReason,
//       consultationType: patientData?.serviceName,
//       createdBy: userData?.user.userId,
//       createdDate: constructedTime
//         ? constructedTime
//         : consulationData?.createdDate,
//       drugPrescription: medicinesArr,
//       followupVisitDate:
//         followUpDate.length !== 0
//           ? followUpDate
//           : consulationData?.followupVisitDate,
//       image: "",
//       imageName: null,
//       imageType: null,
//       labPrescription: labtestArr,
//       modifiedBy: userData?.user.userId,
//       modifiedDate: constructedTime,
//       patientDocumentID: 0,
//       patientId: patientData?.patientId,
//       patientVitals: vitalsArr,
//       patientDiagnosisDetails: diagnosisArr,
//       // primaryDiagnosis: selectedPrimaryDiag.code,
//       // primarySymptoms: selectedPrimaryDiag.description,
//       recommendation: recommendation
//         ? recommendation
//         : consulationData?.recommendation,
//       // secondaryDiagnosis: selectedSecondaryDiag.code,
//       // secondarySymptoms: selectedSecondaryDiag.description,
//       status: 1,
//       symptoms: symptoms ? symptoms : consulationData?.symptoms,
//       userId: userData?.user.userId,
//       appointmentId: patientData?.appointmentId,
//       specialityCode: userData?.user?.specialityCode,
//     };
//     if (selectedPatient?.consultationId) {
//       payload.appointmentId = selectedPatient?.appointmentId;
//       payload.consultationId = selectedPatient?.consultationId;
//     }
//     if (buttonClick === "Update") {
//       payload.id = consulationData?.id;
//       payload.appointmentId = selectedPatient?.appointmentId;
//       payload.consultationId = selectedPatient?.consultationId;
//     }

//     setPrescription(payload);
//     if (buttonClick === "Preview") {
//       setIsPreviewModalOpen(true);
//       setIsEndConsLoading(false);
//     } else if (selectedPatient?.consultationId && buttonClick !== "Update") {
//       payload.id = consulationData?.id;
//       payload.status = 2;
//       const response = await ConsultationService.updateConsultation(payload);
//       if (response.data.status !== -1) {
//         const { appointmentId, consultationId } = response.data;
//         let endConsultationPayload = {
//           appointmentId,
//           consultationId,
//           patientId: patientData.patientId,
//           userId: userData?.user?.userId,
//           transactionId: patientData.transactionId
//             ? patientData.transactionId
//             : null,
//         };
//         await ConsultationService.EndConsultation(endConsultationPayload);
//         payload = {};
//         message.success(
//           "Your consultation updated successfully, e-prescription will be shared with patient"
//         );
//         history.push(APP_ROUTES.DASHBOARD);
//       } else {
//         message.error("Error occurred. Please try after sometime.");
//       }
//       setIsEndConsLoading(false);
//     } else if (buttonClick === "Update") {
//       payload.id = consulationData?.id;
//       const response = await ConsultationService.updateConsultation(payload);
//       if (response.data.status !== -1) {
//         payload = {};
//         message.success(
//           "Your consultation updated successfully, e-prescription will be shared with patient"
//         );
//         history.push(APP_ROUTES.DASHBOARD);
//       } else {
//         message.error("Error occurred. Please try after sometime.");
//       }
//       setIsEndConsLoading(false);
//     } else if (buttonClick === "End") {
//       const response = await ConsultationService.ConsultationNotes(payload);
//       if (response.data.status !== -1) {
//         const { appointmentId, consultationId } = response.data;
//         let endConsultationPayload = {
//           appointmentId,
//           consultationId,
//           patientId: patientData.patientId,
//           userId: userData?.user?.userId,
//           transactionId: patientData.transactionId
//             ? patientData.transactionId
//             : null,
//         };
//         await ConsultationService.EndConsultation(endConsultationPayload);
//         payload = {};
//         message.success(
//           "Your consultation ended successfully, e-prescription will be shared with patient."
//         );
//         history.push(APP_ROUTES.DASHBOARD);
//       } else {
//         message.error("Error occurred. Please try after sometime.");
//       }
//       setIsEndConsLoading(false);
//     }
//   };

//   const handleRecommendation = (e) => {
//     if (e.target.value) {
//       setRecommendation(e.target.value);
//     } else {
//       setRecommendation("");
//     }
//   };

//   const disabledDate = (current) => {
//     return current && current < dayjs().startOf("day");
//   };

//   const handleFollowUpDate = (date, dateString) => {
//     console.log(dateString);
//     if (dateString) {
//       const constructedTime = `${dateString.split("/")[1]}/${
//         dateString.split("/")[0]
//       }/${dateString.split("/")[2]}`;
//       console.log(constructedTime);
//       setFollowUpDate(constructedTime);
//     } else {
//       setFollowUpDate("");
//     }
//   };

//   const handleAddVitals = (data) => {
//     const VitalsObj = {
//       ...vitalsArrObj,
//       ...data,
//     };
//     console.log(VitalsObj);
//     setVitalsArrObj(VitalsObj);
//   };

//   const handleAddMedicines = (data) => {
//     setMedicinesObj(data);
//   };

//   const handleAddLabTests = (data) => {
//     setLabTestsObj(data);
//   };

//   const handleDiagonsisArr = (data) => {
//     setSelectedOriginalDiagArr(data);
//   };

//   const fetchUrl = (value) => {
//     setUrl(value);
//   };

//   const [medicalOpen, setMedicalOpen] = useState(false);

//   const handleClick = () => {
//     setMedicalOpen(true);
//     const fetchPermittedData = async () => {
//       await fetchPatientPermission();
//     };
//     fetchPermittedData();
//   };
//   const handleMedicalCancel = () => {
//     setMedicalOpen(false);
//   };

//   const fetchPatientPermission = async () => {
//     const userObj = localStorage.getItem("login_result");
//     const userData = userObj ? JSON.parse(userObj) : null;
//     const patientObj = localStorage.getItem("patient");
//     const patientData = patientObj ? JSON.parse(patientObj) : null;

//     const currDate = new Date().toISOString();
//     const date = currDate.split("T")[0];
//     const time = currDate.split("T")[1].slice(0, 8);
//     const constructedTime = date + " " + time;

//     let permissionPayload = {
//       patientId: patientData.patientId,
//       userId: userData?.user?.userId,
//       accessDate: constructedTime,
//       status: 1,
//     };
//     try {
//       const response = await PatientService.isPermissionGranted(
//         permissionPayload
//       );
//       console.log(response, "response");
//       if (response?.data?.length !== 0) {
//         if (response?.data[0]) {
//           setPermissionData(response?.data[0]);
//         }
//       }
//     } catch (e) {
//       console.log(e);
//     }
//   };

//   console.log(permissionData, "permissiondata");
//   useEffect(() => {
//     if (permissionData) {
//       if (permissionData.medicalHistory === "Y") {
//         setSelectedOption("Medical history");
//       } else if (permissionData.prescription === "Y") {
//         setSelectedOption("Prescription");
//       } else if (permissionData.vitals === "Y") {
//         setSelectedOption("Vitals");
//       } else if (permissionData.reports === "Y") {
//         setSelectedOption("Reports");
//       }
//     }
//   }, [permissionData]);

//   const handleOptionChange = (option) => {
//     if (permissionData) {
//       if (
//         option === "Medical history" &&
//         permissionData.medicalHistory === "Y"
//       ) {
//         setSelectedOption(option);
//       } else if (
//         option === "Prescription" &&
//         permissionData.prescription === "Y"
//       ) {
//         setSelectedOption(option);
//       } else if (option === "Vitals" && permissionData.vitals === "Y") {
//         setSelectedOption(option);
//       } else if (option === "Reports" && permissionData.reports === "Y") {
//         setSelectedOption(option);
//       }
//     }
//   };

//   const [isVerticalOpen, setIsVerticalOpen] = useState(true);
//   const QueuehandleClick = () => {
//     setIsVerticalOpen(true);
//   };
//   const closeCircle = () => {
//     setIsVerticalOpen(false);
//   };
//   const [showVitals, setShowVitals] = useState(true);
//   const [showNav, setShowNav] = useState(false);

//   const addvitals = () => {
//     setShowVitals(false);
//     setShowNav(true);
//   };
//   const shownavvital = () => {
//     setShowNav(false);
//     setShowVitals(true);
//   };
//   return (
//     <div>
//       <Layout>
//         <div className="w-full">
//           <div className="md:flex gap-3">
//             <div
//               className={`transition-all duration-300 ${
//                 showVitals ? "md:w-[100%]" : "md:w-[200%]"
//               }`}
//             >
//               {!isEditScreenEnabled && (
//                 <div className="flex justify-between">
//                   <HiMiniArrowTopRightOnSquare
//                     className="w-5 h-5 cursor-pointer"
//                     onClick={handleFullScreen}
//                   />
//                   <IoShareSocialSharp
//                     className="w-5 h-5 cursor-pointer text-blue-500"
//                     onClick={showShareModal}
//                   />
//                 </div>
//               )}
//               {!isEditScreenEnabled ? (
//                 <VideoConsultation
//                   selectedPatient={selectedPatient}
//                   fetchUrl={fetchUrl}
//                 />
//               ) : (
//                 <div className="md:h-[500px] sticky mb-2 flex justify-center items-center">
//                   {/* <img src={VcImage} className="h-full" alt="Video Consultation" /> */}
//                   <FaVideoSlash className="md:w-36 md:h-36 text-gray-400" />
//                 </div>
//               )}
//               {/* <div className="w-full"></div> */}
//               <div className="md:flex justify-between bg-gray-300 p-2 md:h-10">
//                 <p className="font-medium text-base text-blue-700">
//                   {/* Patient Name: */}
//                   <span className="text-green-700 text-base">
//                     {" "}
//                     {selectedPatientDetails?.firstName ||
//                     selectedPatientDetails?.lastName
//                       ? selectedPatientDetails?.firstName +
//                         " " +
//                         selectedPatientDetails?.lastName
//                       : ""}
//                   </span>
//                 </p>
//                 <p className="font-medium text-base text-blue-700">
//                   {/* Age/Gender: */}
//                   <span className="text-green-700 text-base">
//                     {selectedPatientDetails?.age
//                       ? selectedPatientDetails?.age
//                       : selectedPatientDetails?.dob
//                       ? age
//                       : ""}{" "}
//                     /
//                     {selectedPatientDetails?.gender
//                       ? selectedPatientDetails?.gender === "M"
//                         ? "Male"
//                         : "Female"
//                       : ""}
//                   </span>
//                 </p>
//                 <p className="font-medium text-base text-blue-700">
//                   {/* Mobile: */}
//                   <span className="text-green-700 text-base">
//                     {" "}
//                     {selectedPatientDetails?.mobile
//                       ? selectedPatientDetails?.mobile
//                       : ""}
//                   </span>
//                 </p>

//                 <p className="font-medium text-base text-blue-700">
//                   {/* Email: */}
//                   <span className="text-green-700 text-base">
//                     {" "}
//                     {selectedPatientDetails?.email == null
//                       ? ""
//                       : selectedPatientDetails?.email}
//                   </span>
//                 </p>
//               </div>
//             </div>

//             <div
//               className={`transition-all duration-300 ${
//                 showVitals ? "md:w-[15%]" : "md:w-[9%]"
//               }`}
//             >
//               {showVitals && (
//                 <div className="mt-5">
//                   <div className="flex justify-center bg-white md:w-[160px] rounded-sm border border-gray-200">
//                     <h3 className="text-base font-bold ml-2 mt-3">Vitals</h3>
//                     <span
//                       className="ml-auto cursor-pointer mr-2 mt-3"
//                       onClick={addvitals}
//                     >
//                       <IoIosCloseCircle size={20} />
//                     </span>
//                   </div>
//                   <Vitals
//                     consulationData={consulationData}
//                     handleAddVitals={handleAddVitals}
//                   />
//                 </div>
//               )}
//             </div>
//           </div>
//           <div className="mt-3">
//             <ConsultationNotes
//               consulationData={consulationData}
//               handleDiagonsisArr={handleDiagonsisArr}
//               handleChiefComplaint={handleChiefComplaint}
//               handleSymptoms={handleSymptoms}
//               symptoms={symptoms}
//               handleAddMedicines={handleAddMedicines}
//               handleAddLabTests={handleAddLabTests}
//               handleRecommendation={handleRecommendation}
//               handleFollowUpDate={handleFollowUpDate}
//               disabledDate={disabledDate}
//               followUpDate={followUpDate}
//               recommendation={recommendation}
//             />
//           </div>
//         </div>

//         <div className="mt-3 p-3">
//           <div className="flex justify-end gap-5">
//             <button
//               className="text-white bg-green-500 p-2 rounded-lg w-32"
//               onClick={handlePrescription}
//             >
//               Preview
//             </button>
//             {selectedPatient?.consultationId &&
//             selectedPatient?.callMode === "EditCall" ? (
//               <button
//                 disabled={isEndConsLoading}
//                 className="text-white bg-green-500 p-2 rounded-lg w-32 disabled:opacity-50 disabled:cursor-not-allowed"
//                 onClick={handlePrescription}
//               >
//                 Update
//               </button>
//             ) : (
//               <button
//                 disabled={isEndConsLoading}
//                 className="text-white bg-blue-500 p-2 rounded-lg w-32 disabled:opacity-50 disabled:cursor-not-allowed"
//                 onClick={handlePrescription}
//               >
//                 End
//               </button>
//             )}
//           </div>
//         </div>
//         <div>
//           {showNav && (
//             <div onClick={shownavvital}>
//               <div
//                 className="vertical"
//                 style={{ marginTop: "-97px", width: "72px", zIndex: "2" }}
//               >
//                 <p className="vertical-p" style={{ marginTop: "-22px" }}>
//                   <div className="verical-icon" style={{ marginLeft: "-60px" }}>
//                     <MdKeyboardDoubleArrowUp />
//                   </div>
//                   Vitals
//                 </p>
//               </div>
//             </div>
//           )}

//           <div
//             className="vertical"
//             onClick={handleClick}
//             style={{ marginTop: "-12px", width: "85px", zIndex: "1" }}
//           >
//             <p
//               className="vertical-p"
//               style={{ fontSize: "13px", color: "#FFF" }}
//             >
//               <div className="verical-icon">
//                 <MdKeyboardDoubleArrowUp className=" vertical-a" />
//               </div>{" "}
//               Records
//             </p>
//           </div>
//           {/* patient queue design */}
//           {isVerticalOpen ? (
//             <Draggable>
//               <div className="draggable-container fixed top-1/2 right-2 w-14 transform -translate-y-1/2 z-50">
//                 <div
//                   className="hidden md:block relative mb-5 cursor-pointer"
//                   onClick={closeCircle}
//                 >
//                   <IoIosCloseCircle className="hidden md:block absolute mb-1 right-0 top-0" />
//                 </div>

//                 <div className="p-2 flex justify-center items-center rounded-lg bg-[#5584f4]">
//                   <>
//                     <img
//                       src={patientqueue}
//                       alt="patientqueue"
//                       className="w-8 h-8 cursor-pointer bg-[#5584f4]"
//                       title="Patient Queue"
//                       onClick={showDrawer}
//                     />
//                   </>
//                 </div>
//               </div>
//             </Draggable>
//           ) : (
//             <div
//               className="vertical"
//               onClick={QueuehandleClick}
//               style={{ position: "fixed", top: "294px" }}
//             >
//               <p className="vertical-p">
//                 <div
//                   className="verical-icon ml-[0px]"
//                   style={{ marginLeft: "-48px" }}
//                 >
//                   <MdKeyboardDoubleArrowUp />
//                 </div>
//                 Queue
//               </p>
//             </div>
//           )}

//           {isDrawerOpen && (
//             <div className="grid justify-end mt-5">
//               {isDrawerOpen && (
//                 <PatientQueue
//                   queueData={queueData ? queueData : ""}
//                   onClose={onClose}
//                 />
//               )}
//             </div>
//           )}
//           {/* patient queue design end */}
//         </div>
//         <Modal
//           width={"70%"}
//           open={isPreviewModalOpen}
//           onCancel={handleCancel}
//           footer={[]}
//         >
//           {prescription && state?.selectedPatient ? (
//             <PrescriptionDetails
//               prescription={prescription}
//               selectedPatient={state?.selectedPatient}
//             />
//           ) : (
//             <div className="flex items-center justify-center mt-[10%]">
//               <Flex align="center" gap="middle">
//                 <Spin size="large" tip="Loading" />
//               </Flex>
//             </div>
//           )}
//         </Modal>
//         <Modal
//           width={"90%"}
//           title={
//             <div className="text-2xl flex gap-2  items-center">
//               <span>Medical Records</span>
//             </div>
//           }
//           open={medicalOpen}
//           onCancel={handleMedicalCancel}
//           footer={null}
//         >
//           <div className=" h-screen px-5 pt-5">
//             <div
//               className="flex justify-center items-center"
//               style={{ marginTop: "-2rem" }}
//             >
//               <div className="w-7/12">
//                 <Segmented
//                   className="appSegment"
//                   onChange={handleOptionChange}
//                   value={selectedOption}
//                   block
//                   options={[
//                     {
//                       label: (
//                         <Tooltip
//                           placement="top"
//                           title={
//                             !permissionData ||
//                             permissionData.medicalHistory === "N"
//                               ? "Patient should provide permission to access medical history records."
//                               : "Medical History"
//                           }
//                         >
//                           <div className="bg-[inherit] w-[inherit] h-[inherit] flex gap-2 disabled:cursor-not-allowed">
//                             <img
//                               src={medicalHistoryIcon}
//                               alt="Medical history"
//                               className="w-5 h-5 mt-1 text-white"
//                             />
//                             Medical history
//                           </div>
//                         </Tooltip>
//                       ),
//                       value: "Medical history",
//                       disabled:
//                         !permissionData ||
//                         permissionData.medicalHistory === "N",
//                     },
//                     {
//                       label: (
//                         <Tooltip
//                           placement="top"
//                           title={
//                             !permissionData ||
//                             permissionData.prescription === "N"
//                               ? "Patient should provide permission to access prescription records."
//                               : "Prescription"
//                           }
//                         >
//                           <div className="bg-[inherit] w-[inherit] h-[inherit] flex gap-2 disabled:cursor-not-allowed">
//                             <img
//                               src={medicalPrescriptionIcon}
//                               alt="Prescription"
//                               className="w-5 h-5 mt-1 text-white"
//                             />
//                             Prescription
//                           </div>
//                         </Tooltip>
//                       ),
//                       value: "Prescription",
//                       disabled:
//                         !permissionData || permissionData.prescription === "N",
//                     },
//                     {
//                       label: (
//                         <Tooltip
//                           placement="top"
//                           title={
//                             !permissionData || permissionData.vitals === "N"
//                               ? "Patient should provide permission to access vitals records."
//                               : "Vitals"
//                           }
//                         >
//                           <div className="bg-[inherit] w-[inherit] h-[inherit] flex gap-2 disabled:cursor-not-allowed">
//                             <img
//                               src={medicalVitalsIcon}
//                               alt="Vitals"
//                               className="w-5 h-5 mt-1 text-white"
//                             />
//                             Vitals
//                           </div>
//                         </Tooltip>
//                       ),
//                       value: "Vitals",
//                       disabled:
//                         !permissionData || permissionData.vitals === "N",
//                     },
//                     {
//                       label: (
//                         <Tooltip
//                           placement="top"
//                           title={
//                             !permissionData || permissionData.reports === "N"
//                               ? "Patient should provide permission to access reports records."
//                               : "Reports"
//                           }
//                         >
//                           <div className="bg-[inherit] w-[inherit] h-[inherit] flex gap-2 disabled:cursor-not-allowed">
//                             <img
//                               src={medicalReportsIcon}
//                               alt="Reports"
//                               className="w-5 h-5 mt-1 text-white"
//                             />
//                             Reports
//                           </div>
//                         </Tooltip>
//                       ),
//                       value: "Reports",
//                       disabled:
//                         !permissionData || permissionData.reports === "N",
//                     },
//                   ]}
//                 />
//               </div>
//             </div>
//             {!permissionData ||
//             (permissionData.vitals === "N" &&
//               permissionData.reports === "N" &&
//               permissionData.medicalHistory === "N" &&
//               permissionData.prescription === "N") ? (
//               <div className="w-full flex h-screen justify-center items-center">
//                 <div>
//                   {!permissionData ? (
//                     <div className="w-full flex h-screen justify-center items-center">
//                       <div className="spinner"></div>
//                     </div>
//                   ) : (
//                     <>
//                       {" "}
//                       <img
//                         src={NoDataFound}
//                         alt="No Data"
//                         className="w-51 h-31"
//                       />
//                       <p className="text-center ">
//                         Patient should provide permission to access medical
//                         records
//                       </p>
//                     </>
//                   )}
//                 </div>
//               </div>
//             ) : (
//               <div
//                 className="relative p-1 h-full customHeight"
//                 style={{
//                   maxHeight: "70vh",
//                   overflowY: "auto",
//                   marginTop: "1rem",
//                 }}
//               >
//                 {selectedOption === "Medical history" && (
//                   <MedicalHistory
//                     permissionData={permissionData}
//                     selectedPatient={selectedPatient}
//                   />
//                 )}

//                 {selectedOption === "Prescription" && (
//                   <MyPresctription
//                     selectedPatient={selectedPatient}
//                     permissionData={permissionData}
//                   />
//                 )}

//                 {selectedOption === "Vitals" && (
//                   <MyVitals
//                     selectedPatient={selectedPatient}
//                     permissionData={permissionData}
//                   />
//                 )}
//                 {selectedOption === "Reports" && (
//                   <Reports
//                     selectedPatient={selectedPatient}
//                     permissionData={permissionData}
//                   />
//                 )}
//               </div>
//             )}
//           </div>
//         </Modal>
//         <Modal
//           title={
//             <div className="text-2xl flex gap-2  items-center">
//               <span>Share</span>
//             </div>
//           }
//           open={shareModal}
//           onCancel={() => {
//             setShareModal(false);
//             form.resetFields();
//           }}
//           footer={[]}
//           height="15%"
//         >
//           <Form form={form} onFinish={handleShareLink} layout="vertical">
//             <Form.Item name="enteredVal">
//               <Input placeholder="Enter" className="h-10" />
//             </Form.Item>
//             <Form.Item>
//               <div className="flex justify-end">
//                 <Button
//                   disabled={isLoading}
//                   htmlType="submit"
//                   className="h-10 mt-2 text-white font-semibold p-2 rounded-md disabled:cursor-not-allowed"
//                 >
//                   Send
//                 </Button>
//               </div>
//             </Form.Item>
//           </Form>
//         </Modal>
//       </Layout>
//     </div>
//   );
// }

// export default Videocall;

import React, { useEffect, useRef, useState } from "react";
import Layout from "../../Layouts";
import { HiMiniArrowTopRightOnSquare } from "react-icons/hi2";
import { IoShareSocialSharp } from "react-icons/io5";
import doctor from "../../../Assets/Images/VideoImage.png";
import Vitals from "./Vitals";
import ConsultationNotes from "./ConsultationNotes";
import vitalpng from "../../../Assets/Images/Arrow right.png";
import ConsultationService from "../../../Services/ConsultationService";
import { useHistory, useLocation } from "react-router-dom";
import { useForm } from "antd/es/form/Form";
import PatientService from "../../../Services/PatientService";
import { RiNurseFill } from "react-icons/ri";
import { APP_ROUTES } from "../../../PageRouting/approutes";
import dayjs from "dayjs";
import { SlArrowUp } from "react-icons/sl";
import { IoIosCloseCircle } from "react-icons/io";
import Draggable from "react-draggable";
import { FaVideoSlash } from "react-icons/fa6";
import {
  Button,
  Col,
  Collapse,
  DatePicker,
  Form,
  Row,
  Input,
  Modal,
  Space,
  Segmented,
  Tooltip,
  message,
  Flex,
  Spin,
  Select,
} from "antd";
import enrollmentServices from "../../../Services/enrollmentServices";
import VitalService from "../../../Services/VitalService";
import PrescriptionDetails from "../ConsultationNotes/VitalTabs/ViewPrescription/PrescriptionDetails";
import patientqueue from "../../../Assets/Images/person-icon-blue.png";
import PatientQueue from "../VCPage/PatientQueue";
import medicalHistoryIcon from "../../../Assets/Images/mdhistory.png";
import medicalPrescriptionIcon from "../../../Assets/Images/mdpres.png";
import medicalVitalsIcon from "../../../Assets/Images/mdvitals.png";
import medicalReportsIcon from "../../../Assets/Images/mdreport.png";
import NoDataFound from "../../../Assets/Images/Svg/No data-found.svg";
import MedicalHistory from "../../Pages/ConsultationNotes/VitalTabs/MedicalHistory";
import MyPresctription from "../../Pages/ConsultationNotes/VitalTabs/ViewPrescription";
import MyVitals from "../../Pages/ConsultationNotes/VitalTabs/PatientVitals/PatientVitals";
import Reports from "../../Pages/ConsultationNotes/VitalTabs/Reports";
import VideoConsultation from "../ConsultationNotes/VideoConsultation";
import { RiArrowLeftDoubleFill } from "react-icons/ri";
import { MdKeyboardDoubleArrowUp } from "react-icons/md";

function Videocall() {
  //newly added line
  const { Option } = Select;

  const months = {
    Jan: "01",
    Feb: "02",
    Mar: "03",
    Apr: "04",
    May: "05",
    Jun: "06",
    Jul: "07",
    Aug: "08",
    Sep: "09",
    Oct: "10",
    Nov: "11",
    Dec: "12",
  };

  const formRef = useRef();
  const [selectedRadio, setSelectedRadio] = useState("primary");
  const [diagnosticList, setDiagnosticList] = useState([]);
  const [currPrimaryDiagList, setCurrPrimaryDiagList] = useState([]);
  const [currSecondaryDiagList, setCurrSecondaryDiagList] = useState([]);
  const [selectedPrimaryDiag, setSelectedPrimaryDiag] = useState({});
  const [selectedSecondaryDiag, setSelectedSecondaryDiag] = useState({});
  const [selectedPrimaryDiagVal, setSelectedPrimaryDiagVal] = useState("");
  const [selectedSecondaryDiagVal, setSelectedSecondaryDiagVal] = useState("");
  const [selectedDiagArr, setSelectedDiagArr] = useState([]);
  const [selectedOriginalDiagArr, setSelectedOriginalDiagArr] = useState([]);
  const [selectedCode, setSelectedCode] = useState(null);
  const [selectedSecondaryCode, setSelectedSecondaryCode] = useState(null);
  const [secondaryDiag, setSecondaryDiag] = useState([]);
  const [isEditScreenEnabled, setIsEditScreenEnabled] = useState(false);
  const [isEndConsLoading, setIsEndConsLoading] = useState(false);
  const [vitalsObj, setVitalsObj] = useState([]);
  const [medicinesObj, setMedicinesObj] = useState([]);
  const [labTestsObj, setLabTestsObj] = useState([]);
  const [followUpDate, setFollowUpDate] = useState("");
  const [bmiValue, setBmiValue] = useState();
  const [height, setHeight] = useState();
  const [weight, setWeight] = useState();
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [prescription, setPrescription] = useState(null);
  const [icdCode, setIcdCode] = useState();
  const [primdiag, setPrimdiag] = useState();
  const [tabledata, setTabledata] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [vitalMasterList, setVitalMasterList] = useState([]);
  const [bloodPressure, setBloodPressure] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [vitalsArrObj, setVitalsArrObj] = useState([]);
  const [sys, setSys] = useState("");
  const [dia, setDia] = useState("");
  const [key, setKey] = useState(0);
  const [secKey, setSecKey] = useState(0);
  const [chiefComplaint, setChiefComplaint] = useState("");
  const [symptoms, setSymptoms] = useState("");
  const [shareModal, setShareModal] = useState(false);
  const [selectedPat, setSelectedPat] = useState(null);
  const { state } = useLocation();
  const history = useHistory();
  const [consulationData, setConsulationData] = useState();
  const [recommendation, setRecommendation] = useState("");
  const [videoUrl, setUrl] = useState("");
  const [selectedPatientDetails, setSelectedPatientDetails] =
    useState(RiNurseFill);
  const [age, setAge] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isImageVisible, setIsImageVisible] = useState(false);
  const [queueData, setQueueData] = useState();
  const [permissionData, setPermissionData] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [form] = useForm();
  const location = useLocation(null);
  let selectedPatient = location?.state?.selectedPatient;
  console.log("selectedPatient", selectedPatient);

  useEffect(() => {
    // if (location?.state?.vitalsArrObjData) {
    //   setVitalsArrObj(prevState => ({
    //     ...prevState,
    //     ...location.state.vitalsArrObjData
    //   }));
    // }

    handleEditScreen();
  }, []);

  useEffect(() => {
    console.log("vitalsArrObj updated: ", vitalsArrObj);
  }, [vitalsArrObj]);

  useEffect(() => {
    if (consulationData?.recommendation) {
      setRecommendation(consulationData?.recommendation);
    }
    if (consulationData?.symptoms) {
      setSymptoms(consulationData?.symptoms);
    }
    if (consulationData?.followupVisitDate) {
      setFollowUpDate(consulationData?.followupVisitDate);
    }
  }, [consulationData]);

  useEffect(() => {
    if (consulationData?.labPrescription) {
      setLabTestsObj(consulationData?.labPrescription);
    }
    if (consulationData?.drugPrescription) {
      setMedicinesObj(consulationData?.drugPrescription);
    }
    if (consulationData?.patientVitals) {
      form.setFieldsValue({
        BPS: consulationData?.patientVitals
          ? consulationData?.patientVitals?.find(
              (item) => item.vitalCode === "BPS"
            )?.value
          : "",
        BPD: consulationData?.patientVitals
          ? consulationData?.patientVitals?.find(
              (item) => item.vitalCode === "BPD"
            )?.value
          : "",
        PO: consulationData?.patientVitals
          ? consulationData?.patientVitals?.find(
              (item) => item.vitalCode === "PO"
            )?.value
          : "",
        HR: consulationData?.patientVitals
          ? consulationData?.patientVitals?.find(
              (item) => item.vitalCode === "HR"
            )?.value
          : "",
        RR: consulationData?.patientVitals
          ? consulationData?.patientVitals?.find(
              (item) => item.vitalCode === "RR"
            )?.value
          : "",
        TP: consulationData?.patientVitals
          ? consulationData?.patientVitals?.find(
              (item) => item.vitalCode === "TP"
            )?.value
          : "",
        HI: consulationData?.patientVitals
          ? consulationData?.patientVitals?.find(
              (item) => item.vitalCode === "HI"
            )?.value
          : "",
        WE: consulationData?.patientVitals
          ? consulationData?.patientVitals?.find(
              (item) => item.vitalCode === "WE"
            )?.value
          : "",
        BMI: consulationData?.patientVitals
          ? consulationData?.patientVitals?.find(
              (item) => item.vitalCode === "BMI"
            )?.value
          : "",
        symptoms: consulationData?.symptoms ? consulationData?.symptoms : "",
        recommendation: consulationData?.recommendation
          ? consulationData?.recommendation
          : "",
      });
      console.log(" edit vit ", consulationData?.patientVitals);
      // const vitalsObj = {};
      // consulationData?.patientVitals.forEach((item) => vitalsObj[item.vitalCode]=item.value);
      // setVitalsArrObj(vitalsObj);
      setVitalsObj(consulationData?.patientVitals);
    }
  }, [consulationData]);

  useEffect(() => {
    fetchVitals();
  }, []);

  useEffect(() => {
    let payload = {
      patientId: selectedPatient.patientId,
    };
    PatientService.PatientListFilter(payload)
      .then((result) => {
        setSelectedPatientDetails(result.data[0]);
        console.error("Error fetching patient list:", result.data[0]);

        // const dobData = result.data[0].dob;
        // const dobYear = dobData?.split("/")[2];
        // const currYear = new Date().getFullYear();
        // console.log("age ", Number(dobYear));
        // if (dobYear) {
        //   const patientAge = currYear - Number(dobYear);
        //   console.log("age ", patientAge);
        //   setAge(patientAge);
        // } else {
        //   console.log("age", age);
        // }
      })
      .catch((error) => {
        console.error("Error fetching patient list:", error);
      });
  }, [selectedPatient.patientId]);

  useEffect(() => {
    if (
      selectedPatient?.consultationId
      //  &&
      // selectedPatient?.callMode === "EditCall"
    ) {
      getPrescriptionDetails();
    }
  }, []);

  const showDrawer = () => {
    let payload = {
      userId: selectedPatient.userId,
      queueStatus: "Y",
    };
    PatientService.patientQueueList(payload).then((res) => {
      if (res.data.length) {
        let validate = res?.data?.filter(
          (x) => x.appointmentId != selectedPatient?.appointmentId
        );
        if (validate && validate.length) {
          setQueueData(validate);
          setIsDrawerOpen(true);
        } else {
          message.warning("No patient available in queue");
        }
      } else {
        message.warning("No patient available in queue");
      }
    });
  };

  const onClose = () => {
    setIsDrawerOpen(false);
  };

  const toggleImageVisibility = () => {
    setIsImageVisible(!isImageVisible);
  };

  const handleEditScreen = () => {
    if (
      selectedPatient.callMode === "EditCall" ||
      selectedPatient.serviceCode === "APPT_INPERSON"
    ) {
      setIsEditScreenEnabled(true);
    }
  };

  const handleFullScreen = () => {
    window.open(videoUrl, "_blank").focus();
  };

  const showShareModal = (record) => {
    setShareModal(true);
    setSelectedPat(record);
  };

  const handleShareLink = (value) => {
    console.log(value);
    const { enteredVal } = value;
    var validation =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|([0-9]{10})+$/;
    var number = /^\d+$/;
    let payload = {};
    setIsLoading(true);
    if (validation.test(enteredVal)) {
      if (number.test(enteredVal)) {
        payload.mobile = enteredVal;
      } else {
        payload.email = enteredVal;
      }
      payload.patientId = selectedPatient.patientId;
      payload.userId = selectedPatient.userId;
      payload.consultationType = selectedPatient.consultationType;
      payload.appointmentId = selectedPatient.appointmentId;

      enrollmentServices
        .InvitationShare(payload)
        .then((res) => {
          console.log("res", res.data);
          message.success("Invitation sent successfully");
          setShareModal(false);
          setIsLoading(false);
          form.resetFields();
        })
        .catch((e) => {
          message.error("An error occurred, Try again");
          setIsLoading(false);
        });
    } else {
      message.error("Please enter the valid email or phone");
      setIsLoading(false);
    }
  };

  const getPrescriptionDetails = async () => {
    let load = {
      consultationId: selectedPatient.consultationId,
      detailsYN: "Y",
    };
    const consultationResponse = await ConsultationService.getPrescriptions(
      load
    );
    if (consultationResponse?.data?.length) {
      setConsulationData(consultationResponse?.data[0]);
      console.log("koiiii", consultationResponse?.data[0]);
      if (consultationResponse?.data[0].patientDiagnosisDetails.length) {
        console.log(
          "lollsss",
          consultationResponse?.data[0].patientDiagnosisDetails
        );
        setSelectedDiagArr(
          consultationResponse?.data[0].patientDiagnosisDetails
        );
        setSelectedOriginalDiagArr(
          consultationResponse?.data[0].patientDiagnosisDetails
        );
        setShowTable(true);
      }
    }
  };

  const fetchVitals = async () => {
    let payload = {
      status: 1,
    };
    const res = await VitalService.VitalMasterlist(payload);
    setVitalMasterList(res.data);
  };

  const handleChiefComplaint = (e) => {
    setChiefComplaint(e.target.value);
  };

  const handleSymptoms = (e) => {
    setSymptoms(e.target.value);
  };

  const handleCancel = () => {
    setIsPreviewModalOpen(false);
  };

  const handlePrescription = async (e) => {
    e.preventDefault();
    setIsEndConsLoading(true);
    const currDate = new Date().toISOString();
    const date = currDate.split("T")[0];
    const time = currDate.split("T")[1].slice(0, 8);
    const constructedTime = date + " " + time;
    const buttonClick = e.target.innerHTML;

    const patientData = state?.selectedPatient;
    const userData = JSON.parse(localStorage.getItem("login_result"));
    const VitalKeys = Object.keys(vitalsArrObj);
    let diagnosisArr = [];
    let vitalsArr = [];
    let medicinesArr = [];
    let labtestArr = [];
    console.log("diag ", selectedOriginalDiagArr);
    // diagnosis payload objects
    for (let i = 0; i < selectedOriginalDiagArr?.length; i++) {
      let Diagpayload = {
        ...selectedOriginalDiagArr[i],
        account_Id: userData?.user?.account_Id,
        clinic_Id: userData?.user?.clinic_Id,
        createdBy: userData?.user.userId,
        createdDate: constructedTime,
        diagnosisCode: selectedOriginalDiagArr[i]?.diagnosisCode,
        diagnosisDescription: selectedOriginalDiagArr[i]?.diagnosisDescription,
        diagnosisType: selectedOriginalDiagArr[i]?.diagnosisType,
        modifiedBy: userData?.user.userId,
        modifiedDate: constructedTime,
        patientId: patientData?.patientId,
        status:
          selectedOriginalDiagArr[i]?.status === 0
            ? 0
            : selectedOriginalDiagArr[i]?.status === undefined
            ? 1
            : selectedOriginalDiagArr[i]?.status,
        userId: userData?.user.userId,
      };
      if (buttonClick === "Update") {
        Diagpayload.consultationId = selectedPatient?.consultationId;
      }
      diagnosisArr.push(Diagpayload);
    }

    // vitals payload objects
    const vitalDate = new Date().toUTCString();
    console.log(vitalDate);
    const dateArr = vitalDate.split(" ");
    const vitMonth = months[dateArr[2]];
    const vitDate = dateArr[1];
    const vitYear = dateArr[3];
    const constructedDate = vitYear + "-" + vitMonth + "-" + vitDate;
    const vitTime = dateArr[4];
    const constructedDateTime = constructedDate + " " + vitTime;

    // for (let i = 0; i < VitalKeys.length; i++) {
    //   console.log("formRef.current.getFieldValue(VitalKeys[i]) ",formRef.current.getFieldValue(VitalKeys[i]));
    //   let payload = {
    //     createdBy: userData?.user.userId,
    //     createdDate: constructedDateTime,
    //     modifiedBy: userData?.user.userId,
    //     modifiedDate: constructedDateTime,
    //     patientId: patientData?.patientId,
    //     recordedDateTime: constructedDateTime,
    //     status: 1,
    //     units: VitalKeys[i] === 'systolic' || VitalKeys[i] === 'diastolic' ? 'mmHg' :
    //       VitalKeys[i] === 'PO' ? '%' : VitalKeys[i] === 'HR' || VitalKeys[i] === 'RR' ? 'Beats/min' :
    //         VitalKeys[i] === 'TP' ? 'F' : VitalKeys[i] === 'HI' ? 'cm' : VitalKeys[i] === 'WE' ? 'kg' : 'kg/m2',
    //     value: VitalKeys[i] === 'bmi' ? bmiValue ? bmiValue : consulationData?.patientVitals?.find((item) => item.vitalCode === "BMI").value :
    //       VitalKeys[i] === 'systolic' ? sys ? sys : consulationData?.patientVitals?.find((item) => item.vitalCode === "BPS").value :
    //         VitalKeys[i] === 'diastolic' ? dia ? dia : consulationData?.patientVitals?.find((item) => item.vitalCode === "BPD").value :
    //           VitalKeys[i] === 'PO' ? formRef.current.getFieldValue(VitalKeys[i]) ? formRef.current.getFieldValue(VitalKeys[i]) : consulationData?.patientVitals?.find((item) => item.vitalCode === "PO").value :
    //             VitalKeys[i] === 'HR' ? formRef.current.getFieldValue(VitalKeys[i]) ? formRef.current.getFieldValue(VitalKeys[i]) : consulationData?.patientVitals?.find((item) => item.vitalCode === "HR").value :
    //               VitalKeys[i] === 'RR' ? formRef.current.getFieldValue(VitalKeys[i]) ? formRef.current.getFieldValue(VitalKeys[i]) : consulationData?.patientVitals?.find((item) => item.vitalCode === "RR").value :
    //                 VitalKeys[i] === 'TP' ? formRef.current.getFieldValue(VitalKeys[i]) ? formRef.current.getFieldValue(VitalKeys[i]) : consulationData?.patientVitals?.find((item) => item.vitalCode === "TP").value :
    //                   VitalKeys[i] === 'Hi' ? formRef.current.getFieldValue(VitalKeys[i]) ? formRef.current.getFieldValue(VitalKeys[i]) : consulationData?.patientVitals?.find((item) => item.vitalCode === "HI").value :
    //                     VitalKeys[i] === 'WE' ? formRef.current.getFieldValue(VitalKeys[i]) ? formRef.current.getFieldValue(VitalKeys[i]) : consulationData?.patientVitals?.find((item) => item.vitalCode === "WE").value : formRef.current.getFieldValue(VitalKeys[i]),
    //     vitalCode: VitalKeys[i] === 'systolic' ? 'BPS' : VitalKeys[i] === 'diastolic' ? 'BPD' :
    //       VitalKeys[i] === 'PO' ? 'PO' : VitalKeys[i] === 'HR' ? 'HR' : VitalKeys[i] === 'RR' ? 'RR' :
    //         VitalKeys[i] === 'TP' ? 'TP' : VitalKeys[i] === 'HI' ? 'HI' : VitalKeys[i] === 'WE' ? 'WE' : VitalKeys[i] === 'bmi' ? 'BMI' : '',
    //     vitalName: VitalKeys[i]
    //   }
    //   vitalsArr.push(payload);
    // }

    if (
      consulationData &&
      consulationData?.patientVitals.length !== 0 &&
      VitalKeys.length !== 0
    ) {
      const patientSavedVitals = consulationData?.patientVitals;
      VitalKeys &&
        VitalKeys.forEach((vitalItem) => {
          const idx = patientSavedVitals?.findIndex(
            (vit) => vit.vitalCode === vitalItem
          );
          if (idx !== -1) {
            patientSavedVitals[idx].value = vitalsArrObj[vitalItem];
            patientSavedVitals[idx].modifiedDate = constructedDateTime;
          } else {
            let vitalPayload = {
              account_Id: userData?.user?.account_Id,
              clinic_Id: userData?.user?.clinic_Id,
              userId: userData?.user?.userId,
              createdBy: userData?.user.userId,
              createdDate: constructedDateTime,
              modifiedBy: userData?.user.userId,
              modifiedDate: constructedDateTime,
              patientId: patientData?.patientId,
              recordedDateTime: constructedDateTime,
              status: 1,
              units: vitalMasterList.find(
                (vital) => vital.vitalCode === vitalItem
              )?.units,
              value: vitalsArrObj[vitalItem],
              vitalCode: vitalItem,
              vitalName: vitalMasterList.find(
                (vital) => vital.vitalCode === vitalItem
              )?.vitalName,
            };
            patientSavedVitals.push(vitalPayload);
          }
        });
      vitalsArr = patientSavedVitals;
    } else if (
      consulationData &&
      consulationData?.patientVitals.length !== 0 &&
      VitalKeys.length === 0
    ) {
      vitalsArr = consulationData?.patientVitals;
    } else {
      for (let i = 0; i < VitalKeys.length; i++) {
        let vitalPayload = {
          account_Id: userData?.user?.account_Id,
          clinic_Id: userData?.user?.clinic_Id,
          userId: userData?.user?.userId,
          createdBy: userData?.user.userId,
          createdDate: constructedDateTime,
          modifiedBy: userData?.user.userId,
          modifiedDate: constructedDateTime,
          patientId: patientData?.patientId,
          recordedDateTime: constructedDateTime,
          status: 1,
          units: vitalMasterList.find(
            (vital) => vital.vitalCode === VitalKeys[i]
          )?.units,
          value:
            VitalKeys[i] === "BMI"
              ? vitalsArrObj[VitalKeys[i]]
                ? vitalsArrObj[VitalKeys[i]]
                : consulationData?.patientVitals
                ? consulationData?.patientVitals?.find(
                    (item) => item.vitalCode === "BMI"
                  )?.value
                : ""
              : VitalKeys[i] === "BPS"
              ? vitalsArrObj[VitalKeys[i]]
                ? vitalsArrObj[VitalKeys[i]]
                : consulationData?.patientVitals
                ? consulationData?.patientVitals?.find(
                    (item) => item.vitalCode === "BPS"
                  )?.value
                : ""
              : VitalKeys[i] === "BPD"
              ? vitalsArrObj[VitalKeys[i]]
                ? vitalsArrObj[VitalKeys[i]]
                : consulationData?.patientVitals
                ? consulationData?.patientVitals?.find(
                    (item) => item.vitalCode === "BPD"
                  )?.value
                : ""
              : VitalKeys[i] === "PO"
              ? vitalsArrObj[VitalKeys[i]]
                ? Number(vitalsArrObj[VitalKeys[i]])
                : consulationData?.patientVitals
                ? consulationData?.patientVitals?.find(
                    (item) => item.vitalCode === "PO"
                  )?.value
                : ""
              : VitalKeys[i] === "HR"
              ? vitalsArrObj[VitalKeys[i]]
                ? Number(vitalsArrObj[VitalKeys[i]])
                : consulationData?.patientVitals
                ? consulationData?.patientVitals?.find(
                    (item) => item.vitalCode === "HR"
                  )?.value
                : ""
              : VitalKeys[i] === "RR"
              ? vitalsArrObj[VitalKeys[i]]
                ? Number(vitalsArrObj[VitalKeys[i]])
                : consulationData?.patientVitals
                ? consulationData?.patientVitals?.find(
                    (item) => item.vitalCode === "RR"
                  )?.value
                : ""
              : VitalKeys[i] === "TP"
              ? vitalsArrObj[VitalKeys[i]]
                ? Number(vitalsArrObj[VitalKeys[i]])
                : consulationData?.patientVitals
                ? consulationData?.patientVitals?.find(
                    (item) => item.vitalCode === "TP"
                  )?.value
                : ""
              : VitalKeys[i] === "HI"
              ? vitalsArrObj[VitalKeys[i]]
                ? Number(vitalsArrObj[VitalKeys[i]])
                : consulationData?.patientVitals
                ? consulationData?.patientVitals?.find(
                    (item) => item.vitalCode === "HI"
                  )?.value
                : ""
              : VitalKeys[i] === "WE"
              ? vitalsArrObj[VitalKeys[i]]
                ? Number(vitalsArrObj[VitalKeys[i]])
                : consulationData?.patientVitals
                ? consulationData?.patientVitals?.find(
                    (item) => item.vitalCode === "WE"
                  )?.value
                : ""
              : "",
          vitalCode: vitalMasterList.find(
            (vital) => vital.vitalCode === VitalKeys[i]
          )?.vitalCode,
          vitalName: vitalMasterList.find(
            (vital) => vital.vitalCode === VitalKeys[i]
          )?.vitalName,
        };
        if (buttonClick === "Update") {
          // vitalPayload.consultationId = selectedPatient?.consultationId;
          // vitalPayload.id = consulationData?.patientVitals?.find(
          //   (vital) => vital.vitalCode === vitalPayload.vitalCode
          // )?.id;
        }
        vitalsArr.push(vitalPayload);
        console.log(vitalPayload, "vitalPayload");
      }
    }

    // medicine payload objects
    for (let i = 0; i < medicinesObj?.length; i++) {
      const toDate =
        medicinesObj[i]?.duration * 24 * 60 * 60 * 1000
          ? new Date(
              new Date().getTime() +
                medicinesObj[i]?.duration * 24 * 60 * 60 * 1000
            ).toISOString()
          : new Date().toISOString();
      const toDays = toDate.split("T")[0];
      const constructedToDateTime = toDays + " " + time;
      let medicinePayload = {
        // ...medicinesObj[i],
        account_Id: userData?.user?.account_Id,
        clinic_Id: userData?.user?.clinic_Id,
        afterBeforeFood:
          medicinesObj[i]?.timeTaken === "afterfood" ? "AF" : "BF",
        composition: medicinesObj[i]?.composition,
        createdBy: userData?.user.userId,
        createdDate: constructedTime,
        dosage: medicinesObj[i]?.dosage ? medicinesObj[i]?.dosage : null,
        drugCode: medicinesObj[i]?.code
          ? medicinesObj[i]?.code
          : medicinesObj[i]?.drugCode
          ? medicinesObj[i]?.drugCode
          : null,
        drugName: medicinesObj[i]?.drugName ? medicinesObj[i]?.drugName : null,
        duration: medicinesObj[i]?.duration ? medicinesObj[i]?.duration : null,
        form: null,
        frequency: medicinesObj[i]?.dosage ? medicinesObj[i]?.dosage : null,
        fromDateTime: constructedTime,
        givenDate: constructedTime,
        instruction: medicinesObj[i]?.instruction
          ? medicinesObj[i]?.instruction
          : null,
        medicineType: medicinesObj[i]?.productType
          ? medicinesObj[i]?.productType
          : null,
        modifiedBy: userData?.user.userId,
        modifiedDate: constructedTime,
        patientId: patientData?.patientId,
        quantity: medicinesObj[i]?.quantity,
        specialAuthority: null,
        status:
          medicinesObj[i]?.status === 0
            ? 0
            : medicinesObj[i]?.status === undefined
            ? 1
            : medicinesObj[i]?.status,
        toDateTime: constructedToDateTime,
        userId: userData?.user.userId,
      };
      if (selectedPatient?.consultationId) {
        medicinePayload.consultationId = selectedPatient?.consultationId;
      }
      if (buttonClick === "Update") {
        medicinePayload.consultationId = selectedPatient?.consultationId;
      }
      medicinesArr.push(medicinePayload);
    }

    //labtest payload objects
    for (let i = 0; i < labTestsObj?.length; i++) {
      let labPayload = {
        ...labTestsObj[i],
        account_Id: userData?.user?.account_Id,
        clinic_Id: userData?.user?.clinic_Id,
        createdBy: userData?.user.userId,
        createdDate: constructedTime,
        documentReceivedSource: null,
        labTestId: labTestsObj[i]?.labTestId ? labTestsObj[i]?.labTestId : null,
        labTestName: labTestsObj[i]?.labTestName
          ? labTestsObj[i]?.labTestName
          : null,
        modifiedBy: userData?.user.userId,
        modifiedDate: constructedTime,
        parentLabTestName: labTestsObj[i]?.parentDescription
          ? labTestsObj[i]?.parentDescription
          : null,
        patientId: patientData?.patientId,
        status:
          labTestsObj[i]?.status === 0
            ? labTestsObj[i]?.status
            : labTestsObj[i]?.status === undefined
            ? 1
            : labTestsObj[i]?.status,
        userId: userData?.user.userId,
      };
      if (selectedPatient?.consultationId) {
        labPayload.consultationId = selectedPatient?.consultationId;
      }
      if (buttonClick === "Update") {
        labPayload.consultationId = selectedPatient?.consultationId;
      }
      labtestArr.push(labPayload);
    }

    let payload = {
      account_Id: userData?.user?.account_Id,
      clinic_Id: userData?.user?.clinic_Id,
      consultationDate: constructedTime,
      consultationReason: patientData?.appointmentReason,
      consultationType: patientData?.serviceName,
      createdBy: userData?.user.userId,
      createdDate: constructedTime
        ? constructedTime
        : consulationData?.createdDate,
      drugPrescription: medicinesArr,
      followupVisitDate:
        followUpDate.length !== 0
          ? followUpDate
          : consulationData?.followupVisitDate,
      image: "",
      imageName: null,
      imageType: null,
      labPrescription: labtestArr,
      modifiedBy: userData?.user.userId,
      modifiedDate: constructedTime,
      patientDocumentID: 0,
      patientId: patientData?.patientId,
      patientVitals: vitalsArr,
      patientDiagnosisDetails: diagnosisArr,
      // primaryDiagnosis: selectedPrimaryDiag.code,
      // primarySymptoms: selectedPrimaryDiag.description,
      recommendation: recommendation
        ? recommendation
        : consulationData?.recommendation,
      // secondaryDiagnosis: selectedSecondaryDiag.code,
      // secondarySymptoms: selectedSecondaryDiag.description,
      status: 1,
      symptoms: symptoms ? symptoms : consulationData?.symptoms,
      userId: userData?.user.userId,
      appointmentId: patientData?.appointmentId,
      specialityCode: userData?.user?.specialityCode,
    };
    if (selectedPatient?.consultationId) {
      payload.appointmentId = selectedPatient?.appointmentId;
      payload.consultationId = selectedPatient?.consultationId;
    }
    if (buttonClick === "Update") {
      payload.id = consulationData?.id;
      payload.appointmentId = selectedPatient?.appointmentId;
      payload.consultationId = selectedPatient?.consultationId;
      payload.status = 2;
    }

    setPrescription(payload);
    // preview check
    if (buttonClick === "Preview") {
      setIsPreviewModalOpen(true);
      setIsEndConsLoading(false);
    }
    // This condition checks does the patient completed preconsultation.
    else if (selectedPatient?.consultationId && buttonClick !== "Update") {
      payload.id = consulationData?.id;
      payload.status = 2;
      const response = await ConsultationService.updateConsultation(payload);

      if (response.data.status !== -1) {
        const { appointmentId, consultationId } = response.data;
        let endConsultationPayload = {
          appointmentId,
          consultationId,
          patientId: patientData.patientId,
          userId: userData?.user?.userId,
          transactionId: patientData.transactionId
            ? patientData.transactionId
            : null,
        };
        await ConsultationService.EndConsultation(endConsultationPayload);
        payload = {};
        message.success(
          "Your consultation updated successfully, e-prescription will be shared with patient"
        );
        history.push(APP_ROUTES.DASHBOARD);
      } else {
        message.error("Error occurred. Please try after sometime.");
      }
      setIsEndConsLoading(false);
    }
    // This condition checks update
    else if (buttonClick === "Update") {
      const response = await ConsultationService.updateConsultation(payload);

      if (response.data.status !== -1) {
        payload = {};
        message.success(
          "Your consultation updated successfully, e-prescription will be shared with patient"
        );
        history.push(APP_ROUTES.DASHBOARD);
      } else {
        message.error("Error occurred. Please try after sometime.");
      }
      setIsEndConsLoading(false);
    }
    // This condition checks initial save
    else if (buttonClick === "End") {
      const response = await ConsultationService.ConsultationNotes(payload);

      //newly added line

      if (Object.keys(editedPatientDetails).length > 0) {
        let updatepatientDetails =
          await ConsultationService.UpdatePatientDetailsInConsultation(
            editedPatientDetails
          );
      }
      if (response.data.status !== -1) {
        const { appointmentId, consultationId } = response.data;
        let endConsultationPayload = {
          appointmentId,
          consultationId,
          patientId: patientData.patientId,
          userId: userData?.user?.userId,
          transactionId: patientData.transactionId
            ? patientData.transactionId
            : null,
        };
        await ConsultationService.EndConsultation(endConsultationPayload);
        payload = {};
        message.success(
          "Your consultation ended successfully, e-prescription will be shared with patient."
        );
        history.push(APP_ROUTES.DASHBOARD);
      } else {
        message.error("Error occurred. Please try after sometime.");
      }
      setIsEndConsLoading(false);
    }
  };

  const handleRecommendation = (e) => {
    if (e.target.value) {
      setRecommendation(e.target.value);
    } else {
      setRecommendation("");
    }
  };

  const disabledDate = (current) => {
    return current && current < dayjs().startOf("day");
  };

  const handleFollowUpDate = (date, dateString) => {
    console.log(dateString);
    if (dateString) {
      const constructedTime = `${dateString.split("/")[1]}/${
        dateString.split("/")[0]
      }/${dateString.split("/")[2]}`;
      console.log(constructedTime);
      setFollowUpDate(constructedTime);
    } else {
      setFollowUpDate("");
    }
  };

  const handleAddVitals = (data) => {
    const VitalsObj = {
      ...vitalsArrObj,
      ...data,
    };
    console.log(VitalsObj);
    setVitalsArrObj(VitalsObj);
  };

  const handleAddMedicines = (data) => {
    setMedicinesObj(data);
  };

  const handleAddLabTests = (data) => {
    setLabTestsObj(data);
  };

  const handleDiagonsisArr = (data) => {
    setSelectedOriginalDiagArr(data);
  };

  const fetchUrl = (value) => {
    setUrl(value);
  };

  const [medicalOpen, setMedicalOpen] = useState(false);

  const handleClick = () => {
    setMedicalOpen(true);
    fetchPatientPermission();
  };
  const handleMedicalCancel = () => {
    setMedicalOpen(false);
    setPermissionData(null);
    setSelectedOption(null);
  };

  const fetchPatientPermission = async () => {
    const userObj = localStorage.getItem("login_result");
    const userData = userObj ? JSON.parse(userObj) : null;
    const patientObj = localStorage.getItem("patient");
    const patientData = patientObj ? JSON.parse(patientObj) : null;

    const currDate = new Date().toISOString();
    const date = currDate.split("T")[0];
    const time = currDate.split("T")[1].slice(0, 8);
    const constructedTime = date + " " + time;

    let permissionPayload = {
      patientId: patientData.patientId,
      userId: userData?.user?.userId,
      accessDate: constructedTime,
      status: 1,
    };
    setIsLoading(true);
    try {
      const response = await PatientService.isPermissionGranted(
        permissionPayload
      );
      console.log(response.data, "response");
      if (response?.data?.length !== 0) {
        if (response?.data[0]) {
          setPermissionData(response?.data[0]);
        }
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  console.log(permissionData, "permissiondata");
  useEffect(() => {
    if (permissionData) {
      if (permissionData.medicalHistory === "Y") {
        setSelectedOption("Medical history");
      } else if (permissionData.prescription === "Y") {
        setSelectedOption("Prescription");
      } else if (permissionData.vitals === "Y") {
        setSelectedOption("Vitals");
      } else if (permissionData.reports === "Y") {
        setSelectedOption("Reports");
      }
    }
  }, [permissionData]);

  const handleOptionChange = (option) => {
    if (permissionData) {
      if (
        option === "Medical history" &&
        permissionData.medicalHistory === "Y"
      ) {
        setSelectedOption(option);
      } else if (
        option === "Prescription" &&
        permissionData.prescription === "Y"
      ) {
        setSelectedOption(option);
      } else if (option === "Vitals" && permissionData.vitals === "Y") {
        setSelectedOption(option);
      } else if (option === "Reports" && permissionData.reports === "Y") {
        setSelectedOption(option);
      }
    }
  };

  const [isVerticalOpen, setIsVerticalOpen] = useState(true);
  const QueuehandleClick = () => {
    setIsVerticalOpen(true);
  };
  const closeCircle = () => {
    setIsVerticalOpen(false);
  };
  const [showVitals, setShowVitals] = useState(true);
  const [showNav, setShowNav] = useState(false);

  const addvitals = () => {
    setShowVitals(false);
    setShowNav(true);
  };
  const shownavvital = () => {
    setShowNav(false);
    setShowVitals(true);
  };

  //newly added line
  const calculateAge = (dob) => {
    console.log("ageDob", dob);
    const dobData = dob;
    const dobYear = dobData?.split("/")[2];
    const currYear = new Date().getFullYear();
    if (dobYear) {
      const patientAge = currYear - Number(dobYear);
      console.log("ageDob", patientAge);

      return patientAge;
    }
  };

  //newly added line
  const [editedPatientDetails, setEditPatientDetails] = useState({});

  //newly added line
  const handleChange = (e, name) => {
    let value = e.target.value;

    setEditPatientDetails((prevDetails) => ({
      ...prevDetails,
      patientId: selectedPatient.patientId,
      [name]: value,
    }));
  };

  //newly added line
  const handleGender = (value) => {
    console.log(`Selected value: ${value}`);
    setEditPatientDetails((prevDetails) => ({
      ...prevDetails,
      patientId: selectedPatient.patientId,
      gender: value,
    }));
  };

  return (
    <div>
      <Layout>
        <div className="w-full">
          <div className="md:flex gap-3">
            <div
              className={`transition-all duration-300 ${
                showVitals ? "md:w-[100%]" : "md:w-[200%]"
              }`}
            >
              {!isEditScreenEnabled && (
                <div className="flex justify-between">
                  <HiMiniArrowTopRightOnSquare
                    className="w-5 h-5 cursor-pointer"
                    onClick={handleFullScreen}
                  />
                  <IoShareSocialSharp
                    className="w-5 h-5 cursor-pointer text-blue-500"
                    onClick={showShareModal}
                  />
                </div>
              )}
              {!isEditScreenEnabled ? (
                <VideoConsultation
                  selectedPatient={selectedPatient}
                  fetchUrl={fetchUrl}
                />
              ) : (
                <div className="md:h-[500px] sticky mb-2 flex justify-center items-center">
                  {/* <img src={VcImage} className="h-full" alt="Video Consultation" /> */}
                  <FaVideoSlash className="md:w-36 md:h-36 text-gray-400" />
                </div>
              )}
              {/* <div className="w-full"></div> */}
              <div className="md:flex justify-between bg-gray-300 p-2 md:h-10">
                <p className="font-medium text-base text-blue-700">
                  {/* Patient Name: */}
                  <span className="text-green-700 text-base">
                    {" "}
                    {selectedPatientDetails?.firstName ||
                    selectedPatientDetails?.lastName
                      ? selectedPatientDetails?.firstName +
                        " " +
                        selectedPatientDetails?.lastName
                      : ""}
                  </span>
                </p>
                <p className="font-medium text-base text-blue-700">
                  {/* Age/Gender: */}
                  <span className="text-green-700 text-base">
                    {selectedPatientDetails?.ageDob ? (
                      calculateAge(selectedPatientDetails?.ageDob)
                    ) : (
                      <input
                        onChange={(e) => handleChange(e, "age")}
                        placeholder="age"
                        maxLength={3}
                        autoComplete="off"
                        className="w-12 rounded-md outline-none border-gray-600 px-2 ml-1"
                      />
                    )}
                    /
                    {selectedPatientDetails?.gender ? (
                      selectedPatientDetails?.gender === "M" ? (
                        "Male"
                      ) : (
                        "Female"
                      )
                    ) : (
                      <Select
                        className="w-24 h-6 mr-1"
                        placeholder="Gender"
                        onChange={handleGender}
                      >
                        <Option value="M">Male</Option>
                        <Option value="F">Female</Option>
                      </Select>
                    )}
                  </span>
                </p>
                <p className="font-medium text-base text-blue-700">
                  {/* Mobile: */}
                  <span className="text-green-700 text-base">
                    {" "}
                    {selectedPatientDetails?.mobile
                      ? selectedPatientDetails?.mobile
                      : ""}
                  </span>
                </p>

                <p className="font-medium text-base text-blue-700">
                  {/* Email: */}
                  <span className="text-green-700 text-base">
                    {" "}
                    {selectedPatientDetails?.email == null ? (
                      <input
                        onChange={(e) => handleChange(e, "email")}
                        placeholder="Enter email"
                        className="w-64 rounded-md outline-none border-gray-600 px-2"
                      />
                    ) : (
                      selectedPatientDetails?.email
                    )}
                  </span>
                </p>
              </div>
            </div>

            <div
              className={`transition-all duration-300 ${
                showVitals ? "md:w-[15%]" : "md:w-[9%]"
              }`}
            >
              {showVitals && (
                <div className="mt-5">
                  <div className="flex justify-center bg-white md:w-[160px] rounded-sm border border-gray-200">
                    <h3 className="text-base font-bold ml-2 mt-3">Vitals</h3>
                    <span
                      className="ml-auto cursor-pointer mr-2 mt-3"
                      onClick={addvitals}
                    >
                      <IoIosCloseCircle size={20} />
                    </span>
                  </div>
                  <Vitals
                    consulationData={consulationData}
                    handleAddVitals={handleAddVitals}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="mt-3">
            <ConsultationNotes
              consulationData={consulationData}
              handleDiagonsisArr={handleDiagonsisArr}
              handleChiefComplaint={handleChiefComplaint}
              handleSymptoms={handleSymptoms}
              symptoms={symptoms}
              handleAddMedicines={handleAddMedicines}
              handleAddLabTests={handleAddLabTests}
              handleRecommendation={handleRecommendation}
              handleFollowUpDate={handleFollowUpDate}
              disabledDate={disabledDate}
              followUpDate={followUpDate}
              recommendation={recommendation}
            />
          </div>
        </div>

        <div className="mt-3 p-3">
          <div className="flex justify-end gap-5">
            <button
              className="text-white bg-green-500 p-2 rounded-lg w-32"
              onClick={handlePrescription}
            >
              Preview
            </button>
            {selectedPatient?.consultationId &&
            selectedPatient?.callMode === "EditCall" ? (
              <button
                disabled={isEndConsLoading}
                className="text-white bg-green-500 p-2 rounded-lg w-32 disabled:opacity-50 disabled:cursor-not-allowed"
                onClick={handlePrescription}
              >
                Update
              </button>
            ) : (
              <button
                disabled={isEndConsLoading}
                className="text-white bg-blue-500 p-2 rounded-lg w-32 disabled:opacity-50 disabled:cursor-not-allowed"
                onClick={handlePrescription}
              >
                End
              </button>
            )}
          </div>
        </div>
        <div>
          {showNav && (
            <div onClick={shownavvital}>
              <div
                className="vertical"
                style={{ marginTop: "-97px", width: "72px", zIndex: "2" }}
              >
                <p className="vertical-p" style={{ marginTop: "-22px" }}>
                  <div className="verical-icon" style={{ marginLeft: "-60px" }}>
                    <MdKeyboardDoubleArrowUp />
                  </div>
                  Vitals
                </p>
              </div>
            </div>
          )}

          <div
            className="vertical"
            onClick={handleClick}
            style={{ marginTop: "-12px", width: "85px", zIndex: "1" }}
          >
            <p
              className="vertical-p"
              style={{ fontSize: "13px", color: "#FFF" }}
            >
              <div className="verical-icon">
                <MdKeyboardDoubleArrowUp className=" vertical-a" />
              </div>{" "}
              Records
            </p>
          </div>
          {/* patient queue design */}
          {isVerticalOpen ? (
            <Draggable>
              <div className="draggable-container fixed top-1/2 right-2 w-14 transform -translate-y-1/2 z-50">
                <div
                  className="hidden md:block relative mb-5 cursor-pointer"
                  onClick={closeCircle}
                >
                  <IoIosCloseCircle className="hidden md:block absolute mb-1 right-0 top-0" />
                </div>

                <div className="p-2 flex justify-center items-center rounded-lg bg-[#5584f4]">
                  <>
                    <img
                      src={patientqueue}
                      alt="patientqueue"
                      className="w-8 h-8 cursor-pointer bg-[#5584f4]"
                      title="Patient Queue"
                      onClick={showDrawer}
                    />
                  </>
                </div>
              </div>
            </Draggable>
          ) : (
            <div
              className="vertical"
              onClick={QueuehandleClick}
              style={{ position: "fixed", top: "294px" }}
            >
              <p className="vertical-p">
                <div
                  className="verical-icon ml-[0px]"
                  style={{ marginLeft: "-48px" }}
                >
                  <MdKeyboardDoubleArrowUp />
                </div>
                Queue
              </p>
            </div>
          )}

          {isDrawerOpen && (
            <div className="grid justify-end mt-5">
              {isDrawerOpen && (
                <PatientQueue
                  queueData={queueData ? queueData : ""}
                  onClose={onClose}
                />
              )}
            </div>
          )}
          {/* patient queue design end */}
        </div>
        <Modal
          width={"70%"}
          open={isPreviewModalOpen}
          onCancel={handleCancel}
          footer={[]}
        >
          {prescription && state?.selectedPatient ? (
            <PrescriptionDetails
              prescription={prescription}
              selectedPatient={state?.selectedPatient}
            />
          ) : (
            <div className="flex items-center justify-center mt-[10%]">
              <Flex align="center" gap="middle">
                <Spin size="large" tip="Loading" />
              </Flex>
            </div>
          )}
        </Modal>
        <Modal
          width={"90%"}
          destroyOnClose={true}
          title={
            <div className="text-2xl flex gap-2  items-center">
              <span>Medical Records</span>
            </div>
          }
          open={medicalOpen}
          onCancel={handleMedicalCancel}
          footer={null}
        >
          <div className=" h-screen px-5 pt-5">
            <div
              className="flex justify-center items-center"
              style={{ marginTop: "-2rem" }}
            >
              <div className="w-7/12">
                <Segmented
                  className="appSegment"
                  onChange={handleOptionChange}
                  value={selectedOption}
                  block
                  options={[
                    {
                      label: (
                        <Tooltip
                          placement="top"
                          title={
                            !permissionData ||
                            permissionData.medicalHistory === "N"
                              ? "Patient should provide permission to access medical history records."
                              : "Medical History"
                          }
                        >
                          <div className="bg-[inherit] w-[inherit] h-[inherit] flex gap-2 disabled:cursor-not-allowed">
                            <img
                              src={medicalHistoryIcon}
                              alt="Medical history"
                              className="w-5 h-5 mt-1 text-white"
                            />
                            Medical history
                          </div>
                        </Tooltip>
                      ),
                      value: "Medical history",
                      disabled:
                        !permissionData ||
                        permissionData.medicalHistory === "N",
                    },
                    {
                      label: (
                        <Tooltip
                          placement="top"
                          title={
                            !permissionData ||
                            permissionData.prescription === "N"
                              ? "Patient should provide permission to access prescription records."
                              : "Prescription"
                          }
                        >
                          <div className="bg-[inherit] w-[inherit] h-[inherit] flex gap-2 disabled:cursor-not-allowed">
                            <img
                              src={medicalPrescriptionIcon}
                              alt="Prescription"
                              className="w-5 h-5 mt-1 text-white"
                            />
                            Prescription
                          </div>
                        </Tooltip>
                      ),
                      value: "Prescription",
                      disabled:
                        !permissionData || permissionData.prescription === "N",
                    },
                    {
                      label: (
                        <Tooltip
                          placement="top"
                          title={
                            !permissionData || permissionData.vitals === "N"
                              ? "Patient should provide permission to access vitals records."
                              : "Vitals"
                          }
                        >
                          <div className="bg-[inherit] w-[inherit] h-[inherit] flex gap-2 disabled:cursor-not-allowed">
                            <img
                              src={medicalVitalsIcon}
                              alt="Vitals"
                              className="w-5 h-5 mt-1 text-white"
                            />
                            Vitals
                          </div>
                        </Tooltip>
                      ),
                      value: "Vitals",
                      disabled:
                        !permissionData || permissionData.vitals === "N",
                    },
                    {
                      label: (
                        <Tooltip
                          placement="top"
                          title={
                            !permissionData || permissionData.reports === "N"
                              ? "Patient should provide permission to access reports records."
                              : "Reports"
                          }
                        >
                          <div className="bg-[inherit] w-[inherit] h-[inherit] flex gap-2 disabled:cursor-not-allowed">
                            <img
                              src={medicalReportsIcon}
                              alt="Reports"
                              className="w-5 h-5 mt-1 text-white"
                            />
                            Reports
                          </div>
                        </Tooltip>
                      ),
                      value: "Reports",
                      disabled:
                        !permissionData || permissionData.reports === "N",
                    },
                  ]}
                />
              </div>
            </div>
            {(!permissionData || permissionData.length === 0) && !isLoading ? (
              <div className="w-full flex h-screen justify-center items-center">
                <div>
                  {(!permissionData || permissionData.length === 0) &&
                  isLoading ? (
                    <div className="w-full flex h-screen justify-center items-center">
                      <div className="spinner"></div>
                    </div>
                  ) : (
                    <>
                      {" "}
                      <img
                        src={NoDataFound}
                        alt="No Data"
                        className="w-51 h-31"
                      />
                      <p className="text-center ">
                        Patient should provide permission to access medical
                        records
                      </p>
                    </>
                  )}
                </div>
              </div>
            ) : permissionData?.vitals === "N" &&
              permissionData?.reports === "N" &&
              permissionData?.medicalHistory === "N" &&
              permissionData?.prescription === "N" ? (
              <div className="w-full flex flex-col items-center mt-4">
                {" "}
                <img src={NoDataFound} alt="No Data" className="w-51 h-31" />
                <p className="text-center ">
                  Patient should provide permission to access medical records
                </p>
              </div>
            ) : (
              <div
                className="relative p-1 h-full customHeight"
                style={{
                  maxHeight: "70vh",
                  overflowY: "auto",
                  marginTop: "1rem",
                }}
              >
                {selectedOption === "Medical history" && (
                  <MedicalHistory
                    permissionData={permissionData}
                    selectedPatient={selectedPatient}
                  />
                )}

                {selectedOption === "Prescription" && (
                  <MyPresctription
                    selectedPatient={selectedPatient}
                    permissionData={permissionData}
                  />
                )}

                {selectedOption === "Vitals" && (
                  <MyVitals
                    selectedPatient={selectedPatient}
                    permissionData={permissionData}
                  />
                )}
                {selectedOption === "Reports" && (
                  <Reports
                    selectedPatient={selectedPatient}
                    permissionData={permissionData}
                  />
                )}
              </div>
            )}
          </div>
        </Modal>
        <Modal
          title={
            <div className="text-2xl flex gap-2  items-center">
              <span>Share</span>
            </div>
          }
          open={shareModal}
          onCancel={() => {
            setShareModal(false);
            form.resetFields();
          }}
          footer={[]}
          height="15%"
        >
          <Form form={form} onFinish={handleShareLink} layout="vertical">
            <Form.Item name="enteredVal">
              <Input placeholder="Enter" className="h-10" />
            </Form.Item>
            <Form.Item>
              <div className="flex justify-end">
                <Button
                  disabled={isLoading}
                  htmlType="submit"
                  className="h-10 mt-2 text-white font-semibold p-2 rounded-md disabled:cursor-not-allowed"
                >
                  Send
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Modal>
      </Layout>
    </div>
  );
}

export default Videocall;
