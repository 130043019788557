import React, { useEffect, useState } from "react";
import { getDocument } from "pdfjs-dist/build/pdf";
import {
  Button,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Spin,
  Upload,
} from "antd";
import { useParams } from "react-router-dom";
import PharmacyService from "../../../Services/PharmacyService";
import PharmacyHeader from "./PharmacyHeader";
import PharmacyFooter from "./PharmacyFooter";
import dayjs from "dayjs";
import PrescriptionPharma from "./PrescriptionPharma";
import deleteHoverIcon from "../../../Assets/Images/Svg/deleteicon.svg";
import LabOrderService from "../../../Services/LabOrderService";
import Preview from "../../../Assets/Images/Svg/PreviewIcon.svg";
import moment from "moment/moment";
import { CloudUploadOutlined } from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";

function LabPharma() {
  const [params, setParams] = useState();
  const [loading, setLoading] = useState(true);
  const [labs, setLabs] = useState([]);
  const [doctorData, setDoctorData] = useState();
  const [hospitalAccountData, setHospitalAccountData] = useState(null);
  const [clinicDetails, setClinicDetails] = useState(null);
  const [consultationDetails, setConsultationDetails] = useState(null);
  const [patientDetails, setPatientDetails] = useState(null);
  const [labDetails, setLabDetails] = useState(null);
  const [dispensed, setDispensed] = useState(true);
  const [hasLabOrderId, setHasLabOrderId] = useState(false);
  const [updatePayload, setUpdatePayload] = useState({});

  const getCodeFromURL = () => {
    const query = window.location.search;
    const codeMatch = query.match(/ref=([^&]*)/);
    return codeMatch ? codeMatch[1] : null;
  };

  const onDispenseBoxChange = () => {
    setDispensed(!dispensed);
  };

  const [prices, setPrices] = useState({});
  const [total, setTotal] = useState(0);

  const calculateAge = (dob) => {
    const dobData = dob;
    const dobYear = dobData?.split("/")[2];
    const currYear = new Date().getFullYear();
    if (dobYear) {
      const patientAge = currYear - Number(dobYear);
      return patientAge;
    }
  };

  const convertUTCDate = (date, format) => {
    if (!format) {
      var d = new Date();
      date = new Date(
        dayjs(date, "MM-DD-YYYY").format("MM/DD/YYYY") +
          " " +
          d.getHours() +
          ":" +
          d.getMinutes()
      );
    }
    format = format || "MM/DD/YYYY";
    return dayjs(date).utc().format(format);
  };

  const shareDetails = async () => {
    setLoading(true);

    let prescriptionDetailsPayload = {
      refNumber: params,
    };

    console.log("prescriptionDetailsPayload", prescriptionDetailsPayload);

    let prescriptionDetails = await PharmacyService.GetPrescriptionDetails(
      prescriptionDetailsPayload
    );

    console.log("prescriptionDetails", prescriptionDetails.data);

    setDoctorData(prescriptionDetails.data.userDetails);
    setHospitalAccountData(prescriptionDetails.data.accountDetails);
    setClinicDetails(prescriptionDetails.data.clinicDetails);
    setConsultationDetails(prescriptionDetails.data.consultationDetails);
    setPatientDetails(prescriptionDetails.data.patientDetails);
    setLabDetails(prescriptionDetails.data.pharmacyAndLabDetails);

    let payload = {
      refNumber: params,
      status: 1,
    };

    let result = await PharmacyService.GetShareDetails(payload);

    let labListPayload = {
      consultationId: result?.data[0].consultationId,
      status: 1,
    };

    let labList = await LabOrderService.GetLabTestOrderDtl(labListPayload);
    const hasLabOrderId = labList.data?.some((item) => item.labTestOrderId);

    console.log("hasLabOrderId inside the function", hasLabOrderId);

    if (hasLabOrderId) {
      let payload = {
        consultationId: consultationDetails?.consultationId,
        labTestOrderId: labList.data[0]?.labTestOrderId,
        patientId: consultationDetails?.patientId,
        userId: consultationDetails?.userId,
        status: 1,
      };

      console.log("payload to set update drug", payload);

      try {
        let result = await LabOrderService.LabListGet(payload);
        console.log("result.", result.data[0]);
        console.log("result.", result.data[0].labTestOrderDtl);
        setUpdatePayload(result.data[0]);
        setLabs(result.data[0].labTestOrderDtl);
        setHasLabOrderId(hasLabOrderId);
      } catch (error) {
        console.log("error", error);
      }

      let payload2 = {
        labTestOrderId: result.data[0].labTestOrderDtl,
        status: 1,
        patientId: result.data[0].patientId,
        consultationId: result.data[0].consultationId,
        userId: result.data[0].userId,
      };

      let result2 = await LabOrderService.LabReportUploadGet(payload2);
      setUploadedLabDocuments(result2.data);
    } else {
      console.log("dispense id is not present");
      setLabs(labList.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    let param = getCodeFromURL();
    setParams(param);
  }, []);

  useEffect(() => {
    if (params) {
      shareDetails();
    }
  }, [params]);

  const handleValueChange = (e, field, index, lab) => {
    let value = e.target.value;
    if (field === "amount") {
      lab.amount = value;
      const parsedValue = parseFloat(value) || 0;
      setPrices((prevPrices) => {
        const updatedPrices = { ...prevPrices, [index]: parsedValue };
        calculateTotal(updatedPrices);
        return updatedPrices;
      });
    }

    lab.createdBy = labDetails.labId;
    lab.modifiedBy = labDetails.labId;
    lab.createdDate = convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss");
    lab.modifiedDate = convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss");
    lab.consultationId = consultationDetails.consultationId;
    lab.patientId = consultationDetails.patientId;
    lab.userId = consultationDetails.userId;
    lab.status = 1;
    lab.labId = labDetails.labId;
    lab.appointmentId = consultationDetails.appointmentId;

    setLabs((prevData) => {
      const updatedData = [...prevData];
      updatedData[index] = lab;
      return updatedData;
    });
  };

  useEffect(() => {
    console.log("labs from useEffect", labs);
  }, [labs]);

  const calculateTotal = (updatedPrices) => {
    const newTotal = Object.values(updatedPrices).reduce(
      (acc, price) => acc + price,
      0
    );
    setTotal(parseFloat(newTotal).toFixed(2));
  };

  const labPayloadSubmit = async () => {
    setBtnDisable(true);
    let payload = {
      address1: patientDetails?.patientAddress1
        ? patientDetails?.patientAddress1
        : "",
      refNumber: params,
      acceptedYN: "Y",
      address2: patientDetails.patientAddress2
        ? patientDetails.patientAddress2
        : "",
      amount: total,
      appointmentId: consultationDetails.appointmentId,
      bloodGroup: patientDetails.patientBloodGroup,
      city: patientDetails.patientCity,
      consultationId: consultationDetails.consultationId,
      country: patientDetails.patientCountry,
      createdBy: labDetails.labId,
      createdDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
      labTestOrderDtl: labs,
      dob: patientDetails.dob,
      email: patientDetails.patientEmail,
      firstName: patientDetails.patientFirstName,
      gender: patientDetails.patientGender,
      lastName: patientDetails.patientLastName,
      mobile: patientDetails.patientMobile,
      modifiedBy: labDetails.labId,
      modifiedDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
      paidAmount: total,
      parentPatientId: "",
      patientId: consultationDetails.patientId,
      pharmacyName: labDetails.pharmacyName,
      pinCode: patientDetails.patientPinCode,
      labId: labDetails.labId,
      labName: labDetails.labName,
      salutation: patientDetails.patientSalutation,
      state: patientDetails.patientState,
      status: 1,
      userId: consultationDetails.userId,
      userName:
        doctorData.userSalutation +
        " " +
        doctorData.userFirstName +
        " " +
        doctorData.userLastName,
    };

    const hasError = payload.labTestOrderDtl.some((lab) => {
      if (lab.amount === 0) {
        message.error(`Enter amount for All lab tests.`);
        return true;
      }
      return false;
    });

    if (hasError) {
      setBtnDisable(false);
      return;
    }

    let labStatusPayload = {
      labPrescriptionStatus: "Tested",
      rejectedLabRemarks: "",
      consultationId: consultationDetails.consultationId,
    };

    LabOrderService.UpdateLabStatus(labStatusPayload).then((res) => {
      console.log("UpdatePharmacyStatus", res);
    });

    try {
      const result = await LabOrderService.LabListSave(payload);
      console.log("result", result);
      calculateTotalFromMedicines();
      message.success("Lab Order  Placed Successfully");
      shareDetails();
      setBtnDisable(false);
    } catch (error) {
      message.error(
        "An error occured, Make sure you entered all the lab amount and retry"
      );
      setBtnDisable(false);
      console.log("error", error);
    }
    setBtnDisable(false);
  };

  const calculateTotalFromMedicines = () => {
    const newTotal = labs.reduce((acc, medicine) => {
      const amount = parseFloat(medicine.amount) || 0;
      return acc + amount;
    }, 0);
    setTotal(parseFloat(newTotal).toFixed(2));
  };

  useEffect(() => {
    if (!loading) {
      calculateTotalFromMedicines();
    }
  }, [labs, loading]);

  const [uploadModalOpen, setUploadModalOpen] = useState(false); //first modal

  const showUploadModal = () => {
    setUploadModalOpen(true);
  };

  const disabledFutureDate = (current) => {
    return current && current > moment().endOf("day");
  };

  const [imageData, setImageData] = useState("");
  const [imageType, setImageType] = useState("");
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [reportPreview, setReportPreview] = useState("");

  const props1 = {
    customRequest: ({ file, onSuccess }) => {
      if (file && file.size > 1048576) {
        message.error(
          "Image/file size should be less than or equal to 1 mb !!"
        );
        return;
      }
      if (file.type !== "application/pdf") {
        message.error("upload PDF files only");
        return;
      }
      handleFileUpload(file);
      message.success(`${file.name} uploaded successfully.`);
      onSuccess("ok");
    },
  };

  const handleRemoveFile = () => {
    setImageData("");
  };

  const showImageModal = () => {
    setIsModalOpen2(true);
  };

  const handleFileUpload = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      let imageArray = [];
      imageArray = reader.result.split(",");
      const imageDataUrl = imageArray[1];
      const imageType = file.type;
      const fileExtension = imageType.split("/")[1];
      setImageData(imageDataUrl);
      setImageType(fileExtension);
      setReportPreview(reader.result);
    };
  };

  const handlePreviewOk = () => {
    setIsModalOpen2(false);
  };
  const handlePreviewClose = () => {
    setIsModalOpen2(false);
  };

  const [uploadedLabDocuments, setUploadedLabDocuments] = useState(null);

  const handleFileSave = async (payload) => {
    setBtnDisable(true);
    console.log("payload", payload);

    if (
      !payload.labTestOrderId ||
      !payload.date ||
      !payload.labTestName ||
      imageData === ""
    ) {
      message.error("Enter Values and upload Documents to proceed further");
      setBtnDisable(false);
      return;
    }

    let payload1 = {
      consultationId: consultationDetails.consultationId,
      createdBy: labDetails.labId,
      uploadedDateTime: convertUTCDate(
        payload.createdDate,
        "YYYY-MM-DD HH:mm:ss"
      ),
      createdDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
      labId: labDetails.labId,
      labTestName: payload.labTestName,
      labTestOrderId: labs[0].labTestOrderId,
      modifiedBy: labDetails.labId,
      modifiedDate: convertUTCDate(new Date(), "YYYY-MM-DD HH:mm:ss"),
      patientId: consultationDetails.patientId,
      resultDocument: imageData,
      resultDocumentType: imageType,
      resultJson: "{demo}",
      serialNumber: 1,
      status: 1,
      userId: consultationDetails.userId,
      appointmentId: consultationDetails.appointmentId,
    };

    // if (payload1.resultDocumentType !== "pdf") {
    //   message.error(PDF files only allowed);
    //   return;
    // }

    // try {
    //   const pdfData = atob(imageData); // Decode base64 data
    //   const loadingTask = getDocument({ data: pdfData });
    //   const pdf = await loadingTask.promise;

    //   let textContent = "";
    //   for (let i = 1; i <= pdf.numPages; i++) {
    //     const page = await pdf.getPage(i);
    //     const content = await page.getTextContent();
    //     content.items.forEach((item) => {
    //       textContent += item.str + " "; // Concatenate text from each item
    //     });
    //   }

    //   // Convert the text content to JSON format
    //   payload1.resultJson = JSON.stringify({ text: textContent.trim() });
    // } catch (error) {
    //   console.error("Error extracting PDF content:", error);
    //   message.error("Failed to extract PDF content.");
    //   return;
    // }

    console.log("payload1", payload1);

    try {
      let result = await LabOrderService.LabReportUpload(payload1);
      console.log("result", result);
      setUploadModalOpen(false);
      form.resetFields();
      setImageData("");
      setImageType("");
      getuploaded();
      setBtnDisable(false);
    } catch (error) {
      console.log("error", error);
      setBtnDisable(false);
    }
    setBtnDisable(false);
  };

  const getuploaded = async () => {
    let payload = {
      labTestOrderId: labs[0].labTestOrderId,
      status: 1,
      patientId: consultationDetails.patientId,
      consultationId: consultationDetails.consultationId,
      userId: consultationDetails.userId,
    };

    let result2 = await LabOrderService.LabReportUploadGet(payload);
    setUploadedLabDocuments(result2.data);
    console.log("result 2", result2);
  };

  const [form, setForm] = useForm();
  const [viewUploadedReports, setviewUploadedReports] = useState({});
  const [showUploadeddocuments, setShowUploadeddocuments] = useState(false);

  const viewFromTable = (record) => {
    setviewUploadedReports(record);
    setShowUploadeddocuments(true);
    console.log("record", record);
  };

  console.log("uploadedLabDocuments", uploadedLabDocuments);
  const [btndisable, setBtnDisable] = useState(false);

  return (
    <>
      {loading && (
        <div class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="spinner"></div>
        </div>
      )}
      <div className="m-4">
        <PharmacyHeader
          clinicDetails={clinicDetails}
          doctorData={doctorData}
          hospitalAccountData={hospitalAccountData}
          consultationDetails={consultationDetails}
        />
        <div className="my-4">
          <div className="md:flex justify-between items-center">
            <div className="md:flex gap-5  my-4 px-4 md:px-0">
              <div className="text-sm font-bold flex gap-1">
                Name:
                <span className="text-sm text-gray-500 font-semibold">
                  {patientDetails?.patientSalutation
                    ? patientDetails?.patientSalutation + " "
                    : ""}
                  {patientDetails?.patientFirstName
                    ? patientDetails?.patientFirstName + " "
                    : ""}
                  {patientDetails?.patientLastName
                    ? patientDetails?.patientLastName
                    : ""}
                </span>
              </div>
              <div className="text-sm font-bold md:flex gap-1">
                Age/Gender:
                <span className="text-sm text-gray-500 font-semibold">
                  {patientDetails?.dob ? calculateAge(patientDetails?.dob) : ""}
                  /
                  {patientDetails?.patientGender
                    ? patientDetails?.patientGender === "M"
                      ? "Male"
                      : "Female"
                    : ""}
                </span>
              </div>
              <div className="text-sm font-bold md:flex gap-1">
                Phone Number:
                <span className="text-sm text-gray-500 font-semibold">
                  {patientDetails?.patientMobile
                    ? patientDetails?.patientMobile
                    : ""}
                </span>
              </div>

              <div className="text-sm font-bold flex gap-1">
                Visit Type:
                <span className=" text-sm text-gray-500 font-semibold">
                  {consultationDetails?.consultationType}
                </span>
              </div>
            </div>
            <PrescriptionPharma
              doctorData={doctorData}
              hospitalAccountData={hospitalAccountData}
              clinicDetails={clinicDetails}
              consultationDetails={consultationDetails}
              patientDetails={patientDetails}
              labDetails={labDetails}
              calculateAge={calculateAge}
            />
          </div>

          <div className="w-full mx-auto bg-white shadow-md rounded-lg p-6">
            <table className="min-w-full border-collapse">
              <thead>
                <tr className="bg-gray-200">
                  <th className="border px-4 py-2 text-left">Test Name</th>

                  <th className="border px-4 py-2 text-left">Price</th>
                </tr>
              </thead>
              <tbody>
                {labs.map((lab, index) => (
                  <tr key={index}>
                    <td className="border px-2 py-2">{lab.labTestName}</td>

                    <td className="border">
                      <input
                        type="number"
                        value={lab.amount ? lab.amount : null}
                        disabled={hasLabOrderId}
                        onChange={(e) =>
                          handleValueChange(e, "amount", index, lab)
                        }
                        className="w-full h-full p-1 outline-none px-3"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td
                    colSpan=""
                    className="border px-4 py-2 text-right font-semibold"
                  >
                    Total
                  </td>
                  <td className="border px-4 py-2">{total}</td>
                </tr>
              </tfoot>
            </table>

            <div className="px-4 py-2">
              {!hasLabOrderId && (
                <div className="px-4 py-2">
                  <label className="inline-flex items-center">
                    <input
                      checked={dispensed}
                      onChange={onDispenseBoxChange}
                      type="checkbox"
                      className="form-checkbox h-5 w-5 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                    />
                    <span className="ml-2">Accepted</span>
                  </label>
                </div>
              )}
            </div>
            <div className="px-4 py-2 flex justify-end w-full">
              {!hasLabOrderId ? (
                <button
                  disabled={btndisable}
                  onClick={labPayloadSubmit}
                  className="bg-blue-500 text-white px-4 py-2 rounded-md mr-4 border"
                >
                  Submit
                </button>
              ) : (
                <div className="my-4">
                  <button
                    onClick={showUploadModal}
                    className="bg-blue-500 text-white px-4 py-2 rounded-md mr-4 border"
                  >
                    Upload
                  </button>
                </div>
              )}
            </div>
            {hasLabOrderId && (
              <>
                {uploadedLabDocuments && uploadedLabDocuments.length > 0 && (
                  <div className="w-full overflow-x-auto">
                    <table className="w-[80%] mx-auto border-collapse">
                      <thead>
                        <tr className="bg-gray-200">
                          <th className="border px-4 py-2 text-center">
                            Test Name
                          </th>
                          <th className="border px-4 py-2 text-center">
                            Order ID
                          </th>
                          <th className="border px-4 py-2 text-center">View</th>
                        </tr>
                      </thead>
                      <tbody>
                        {uploadedLabDocuments &&
                          uploadedLabDocuments?.map((lab, index) => (
                            <tr key={index}>
                              <td className="border px-2 text-center py-2">
                                {lab.labTestName}
                              </td>
                              <td className="border px-2 text-center py-2">
                                {lab.labTestOrderId}
                              </td>
                              <td className="border px-2 text-center py-2">
                                <button
                                  className="p-1 text-white text-sm rounded-sm bg-blue-500"
                                  onClick={() => viewFromTable(lab)}
                                >
                                  View Report
                                </button>
                                {/* Optional Image */}
                                {/* <img
                                className="cursor-pointer"
                                src={Preview}
                                alt="image"
                              /> */}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </>
            )}
          </div>

          {uploadModalOpen && (
            <Modal
              title={<div className=" font-bold ">Upload Reports</div>}
              open={uploadModalOpen}
              onOk={showUploadModal}
              footer={[]}
              onCancel={() => {
                setUploadModalOpen(false);
                form.resetFields();
                setImageData("");
                setImageType("");
              }}
            >
              <Modal
                title="Preview"
                open={isModalOpen2}
                onOk={handlePreviewOk}
                onCancel={handlePreviewClose}
                footer={null}
                width={800}
                style={{
                  top: 20,
                }}
              >
                {imageType === "pdf" ? (
                  <iframe
                    src={`data:application/pdf;base64,${imageData}`}
                    title="PDF Preview"
                    style={{ width: "100%", height: "500px" }}
                  />
                ) : (
                  <img
                    src={reportPreview}
                    alt="Preview"
                    style={{ width: "100%" }}
                  />
                )}
              </Modal>
              <Form
                className="CommonForm grid grid-cols-2 gap-5"
                layout="vertical"
                autoComplete="off"
                form={form}
                onFinish={handleFileSave}
                initialValues={{
                  labTestOrderId: `${labs[0]?.labTestOrderId}`,
                }}
              >
                <Form.Item
                  className="mb-0"
                  label={
                    <span>
                      Order ID <span className="text-red-500">*</span>
                    </span>
                  }
                  name="labTestOrderId"
                >
                  <Input readOnly />
                </Form.Item>
                <Form.Item
                  className="mb-0"
                  label={
                    <span>
                      Date <span className="text-red-500">*</span>
                    </span>
                  }
                  name="date"
                >
                  <DatePicker
                    className="w-full h-8"
                    format="DD-MM-YYYY"
                    disabledDate={disabledFutureDate}
                    // name="createdDate"
                  />
                </Form.Item>
                <Form.Item
                  className="mb-0"
                  label={
                    <span>
                      Test Name <span className="text-red-500">*</span>
                    </span>
                  }
                  name="labTestName"
                >
                  <Input placeholder="Enter Test" />
                </Form.Item>
                <Form.Item
                  name="document"
                  label={
                    <span className="font-medium">
                      Document<span style={{ color: "#FF000F" }}>*</span>
                    </span>
                  }
                >
                  {imageData ? (
                    <div className="flex gap-1 items-center">
                      <span>
                        {imageType === "application/pdf" ? "PDF" : "document"}
                      </span>
                      <img
                        className="ml-3 cursor-pointer"
                        onClick={handleRemoveFile}
                        src={deleteHoverIcon}
                        alt="image"
                      />
                      <img
                        className="ml-3 cursor-pointer"
                        src={Preview}
                        alt="image"
                        onClick={showImageModal}
                      />
                    </div>
                  ) : (
                    <Upload {...props1} showUploadList={false}>
                      <Button
                        icon={<CloudUploadOutlined />}
                        className="text-white"
                      >
                        CHOOSE FILE
                      </Button>
                    </Upload>
                  )}
                </Form.Item>
                <div className=" flex justify-end gap-3 mt-10">
                  <button
                    className="bg-white text-brand-primaryColor border-brand-primaryColor border rounded-lg w-[100px] h-[40px] text-base"
                    onClick={() => {
                      setUploadModalOpen(false);
                      setImageData("");
                      form.resetFields();
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    disabled={btndisable}
                    htmlType="submit"
                    className="bg-brand-primaryColor w-[100px] h-[40px] text-base rounded-lg text-white disabled:cursor-not-allowed disabled:opacity-50"
                  >
                    Save
                  </button>
                </div>
              </Form>
            </Modal>
          )}

          {showUploadeddocuments && (
            <Modal
              open={showUploadeddocuments}
              onCancel={() => {
                setShowUploadeddocuments(false);
                setviewUploadedReports({});
              }}
              footer={[]}
              width={800}
              style={{
                top: 20,
              }}
            >
              <iframe
                src={`${process.env.REACT_APP_IMAGE_URL}${viewUploadedReports?.resultDocumentName}`}
                title="PDF Preview"
                style={{ width: "100%", height: "500px" }}
              />
            </Modal>
          )}
        </div>
        <PharmacyFooter
          clinicDetails={clinicDetails}
          doctorData={doctorData}
          hospitalAccountData={hospitalAccountData}
          consultationDetails={consultationDetails}
        />
      </div>
    </>
  );
}

export default LabPharma;
